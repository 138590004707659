import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelComponent implements OnInit {
  @Input() value: number;
  @Input() animate = false;

  constructor() {}

  ngOnInit() {}

  public style() {    
    return { clipPath: `inset(${this.value * 100}% 0px 0px 0px)` };
    // return { animationPlayState: this.animate ? 'running' : 'paused' };
  }
}
