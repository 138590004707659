import {Component, OnInit} from '@angular/core';
import {SendLogService, SendParams} from '../../../services/send-log.service';


@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})

export class SupportComponent implements OnInit {

    private static readonly urlStr: string = 'http://www.ultramixer.com/contact/logfiles.php';
    /*
    * Access to XMLHttpRequest at 'http://www.ultramixer.com/contact/logfiles.php' from
    * origin 'http://localhost:8100' has been blocked by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource.
    */

    // from component html
    email = '';
    supportCode = '';
    problemDesc = '';

    // from java, set in ngOnInit()
    private logfileContent = '?';
    private language = 'languagePlaceholder';
    private ordernumber = ''; // old, leave empty

    constructor(private sendLogService: SendLogService) {
    }

    ngOnInit() {
        const s = window['ultramixer.swing.preferences.additional.support'];
        if (s) {
            this.language = s.getLanguage();
            this.logfileContent = s.getLogfilesContent();
            // this.ordernumber = s.getOrderNumber();//old, leave empty
        } else {
            this.language = 'no java proxy';
        }
    }

    sendLog($event: MouseEvent) {
        this.logfileContent = 'testlog content.MHY';

        // collect params
        const sendParams: SendParams = {
            email: this.email,
            supportCode: this.supportCode ? this.supportCode : '',
            locale: this.language,
            problemDesc: this.problemDesc,
            logfileContent: this.logfileContent
        };

        // message format btoa, base64 etc. is handled in SendLogService
        /* let request: jqXHR = */
        this.sendLogService.send(SupportComponent.urlStr, sendParams).subscribe((succ: boolean) => {
            console.log('succ: ', succ);
        });
    }
}
