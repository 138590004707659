<div class="charts-root" [style.--color-a]="colorA" [style.--color-b]="colorB" [style.--color-text]="colorText"
  [style.--color-highlight]="colorHighlight" [style.--color-main-bg]="colorMainBg">
  <div class="charts-content" [ngClass]="{'charts-content-blur': (isLoading$ | async) || (isFilter$ | async)}">

    <div class="charts-header-with-filter" *ngIf="inputMode === 'true';else headerWithoutFilter">
      <i class="pi pi-filter filter-icon" (click)="handleOpenFilter($event)"></i>
      <div class="header-info">
        <span class="header-label">Genre:</span> &quot;{{ chartsGenre }}&quot;, <span class="header-label">Land:</span>
        &quot;{{ chartsCountry }}&quot; - <span class="header-label">Top {{ chartsLimit }}</span>
      </div>
    </div>

    <ng-template #headerWithoutFilter>
      <div class="charts-header-without-filter">
        <div class="header-info">
          <span class="header-label">Genre:</span> &quot;{{ chartsGenre }}&quot;, <span
            class="header-label">Land:</span>
          &quot;{{ chartsCountry }}&quot; - <span class="header-label">Top {{ chartsLimit }}</span>
        </div>
      </div>
    </ng-template>

    <div class="charts-content">
      <div class="charts-list" *ngIf="(charts$ | async) as charts">
        <p-table [columns]="tableCols" [value]="charts" styleClass="p-datatable-gridlines" [scrollable]="true"
          scrollHeight="flex" [resizableColumns]="false" [tableStyle]="{'min-width': '50rem'}" class="table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-chart let-rowIndex="rowIndex">
            <tr>
              <td>{{ rowIndex + 1 }}</td>
              <td>{{ chart?.title }}</td>
              <td>{{ chart?.artist }}</td>
              <td>{{ chart?.album }}</td>
              <td>{{ chart?.genre }}</td>
              <td>{{ chart?.locale }}</td>
              <td *ngIf="inputMode === 'true'">{{ chart?.bpm }}</td>
              <td *ngIf="inputMode === 'true'">
                <ng-container *ngIf="chart.rating !== undefined; else noStars">
                  <p-rating [(ngModel)]="chart.rating" [readonly]="true" [cancel]="false"></p-rating>
                </ng-container>
                <ng-template #noStars>
                  <span> </span>
                </ng-template>
              </td>
            </tr>
          </ng-template>

        </p-table>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading$ | async" class="charts-loading">
    <!-- ! Look at assets/img/waiting-disc.svg to edit this svg and copy tha changes into this file -->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75">
      <!-- Border -->
      <circle cx="37.5" cy="37.5" r="36.5" fill="#999" />
    
      <!-- Multiple circles -->
      <circle cx="37.5" cy="37.5" r="36" fill="#222" />
      <circle cx="37.5" cy="37.5" r="35" fill="#333" />
      <circle cx="37.5" cy="37.5" r="34" fill="#222" />
      <circle cx="37.5" cy="37.5" r="33" fill="#333" />
      <circle cx="37.5" cy="37.5" r="32" fill="url(#cg1)" />
      <circle cx="37.5" cy="37.5" r="31" fill="#333" />
      <circle cx="37.5" cy="37.5" r="30" fill="#222" />
    
      <circle cx="37.5" cy="37.5" r="29" fill="#333" />
      <circle cx="37.5" cy="37.5" r="28" fill="#222" />
      <circle cx="37.5" cy="37.5" r="27" fill="#333" />
      <circle cx="37.5" cy="37.5" r="26" fill="url(#cg2)" />
      <circle cx="37.5" cy="37.5" r="25" fill="#333" />
      <circle cx="37.5" cy="37.5" r="24" fill="#222" />
      <circle cx="37.5" cy="37.5" r="23" fill="#333" />
      <circle cx="37.5" cy="37.5" r="22" fill="#222" />
      <circle cx="37.5" cy="37.5" r="21" fill="#333" />
      <circle cx="37.5" cy="37.5" r="20" fill="url(#cg3)" />
    
      <circle cx="37.5" cy="37.5" r="19" fill="#333" />
      <circle cx="37.5" cy="37.5" r="18" fill="#222" />
      <circle cx="37.5" cy="37.5" r="17" fill="#333" />
      <circle cx="37.5" cy="37.5" r="16" fill="#222" />
      <circle cx="37.5" cy="37.5" r="15" fill="#333" />
      <circle cx="37.5" cy="37.5" r="14" fill="url(#cg4)" />
      <circle cx="37.5" cy="37.5" r="13" fill="#333" />
      <circle cx="37.5" cy="37.5" r="12" fill="#222" />
      <circle cx="37.5" cy="37.5" r="11" fill="#333" />
      <circle cx="37.5" cy="37.5" r="10" fill="#222" />
      <circle cx="37.5" cy="37.5" r="09" fill="#333" />
      <circle cx="37.5" cy="37.5" r="08" fill="#222" />
    
      <!-- Inner circle -->
      <circle cx="37.5" cy="37.5" r="06.5" fill="#f8c915" />
    
      <!-- Line to border -->
      <line x1="37.5" y1="37" x2="73" y2="37.5" stroke="#f8c915" stroke-width="1.5" />
      <circle cx="37.5" cy="37.5" r="00.5" fill="#111" />
    
      <defs>
        <linearGradient id="cg1" x1="100%" y1="45%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#c09c0c; stop-opacity:1" />
          <stop offset="02%" style="stop-color:#c09c0c; stop-opacity:1" />
          <stop offset="02.1%" style="stop-color:#c09c0c; stop-opacity:1" />
          <stop offset="03%" style="stop-color:#222; stop-opacity:1" />
          <stop offset="100%" style="stop-color:#222; stop-opacity:1" />
        </linearGradient>
    
        <linearGradient id="cg2" x1="100%" y1="45%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#c0510c; stop-opacity:1" />
          <stop offset="02%" style="stop-color:#c0510c; stop-opacity:1" />
          <stop offset="02.1%" style="stop-color:#c0510c; stop-opacity:1" />
          <stop offset="03%" style="stop-color:#222; stop-opacity:1" />
          <stop offset="100%" style="stop-color:#222; stop-opacity:1" />
        </linearGradient>
    
        <linearGradient id="cg3" x1="100%" y1="45%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#150cc0; stop-opacity:1" />
          <stop offset="02%" style="stop-color:#150cc0; stop-opacity:1" />
          <stop offset="02.1%" style="stop-color:#150cc0; stop-opacity:1" />
          <stop offset="03%" style="stop-color:#222; stop-opacity:1" />
          <stop offset="100%" style="stop-color:#222; stop-opacity:1" />
        </linearGradient>
    
        <linearGradient id="cg4" x1="100%" y1="45%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#c09c0c; stop-opacity:1" />
          <stop offset="02%" style="stop-color:#c09c0c; stop-opacity:1" />
          <stop offset="02.1%" style="stop-color:#c09c0c; stop-opacity:1" />
          <stop offset="03%" style="stop-color:#222; stop-opacity:1" />
          <stop offset="100%" style="stop-color:#222; stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
  </div>

  <div *ngIf="isFilter$ | async" (click)="handleCloseFilter($event)" class="charts-filter">
    <div class="charts-filter-menu" (click)="stopToggle($event)">
      <div class="charts-filter-menu-left">
        <div class="spacing-down input-box-text">
          <label for="username">{{ filterLabels.genre }}</label>
          <input pInputText id="username" [(ngModel)]="chartsGenre" />
        </div>

        <div class="spacing-top input-box-text">
          <label for="username">{{ filterLabels.country }}</label>
          <input pInputText id="username" [(ngModel)]="chartsCountry" />
        </div>
      </div>

      <div class="charts-filter-menu-right">
        <div class="spacing-down charts-limit-input">
          <span class="charts-limit-input-label">{{ filterLabels.limit }}</span>
          <p-inputNumber [(ngModel)]="chartsLimit" [showButtons]="true" spinnerMode="horizontal" inputId="vertical"
            decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
        </div>

        <div class="generate-charts spacing-top" (click)="handleFilterApply($event)">
          {{ applyFilter }}
        </div>
      </div>
    </div>
  </div>
</div>