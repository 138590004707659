import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionTrigger } from '../../../main/action-trigger/action-trigger';
import {
  ActionTriggerEditorActions,
  AddActionAction,
  AddActionTriggerAction,
  DeleteActionAction,
  DeleteActionTriggerAction,
  SetActionTriggerActiveAction,
  SetActionTriggersAction,
} from '../actions/action-trigger-editor.actions';
import { ActionWithPayload } from '../actions/action.interface';

export interface ActionTriggerState {
  actionTriggers: Array<ActionTrigger>;
  selectedActionTriggerIndex: number;
}

const initialState: ActionTriggerState = {
  actionTriggers: [],
  selectedActionTriggerIndex: -1,
};

export function actionTriggerEditorReducer(state: ActionTriggerState = initialState, action: ActionWithPayload): ActionTriggerState {
  switch (action.type) {
    case ActionTriggerEditorActions.ADD_ACTION_TRIGGER: {
      const a = action as AddActionTriggerAction;
      const newActionTrigger = new ActionTrigger(a.payload.position);

      return {
        ...state,
        actionTriggers: [...state.actionTriggers, newActionTrigger],
      };
    }
    case ActionTriggerEditorActions.SET_ACTION_TRIGGERS: {
      const a = action as SetActionTriggersAction;
      const actionTriggers: ActionTrigger[] = JSON.parse(a.payload.actionTriggersJSON);
      return {
        ...state,
        actionTriggers,
      };
    }
    case ActionTriggerEditorActions.ADD_ACTION: {
      const a = action as AddActionAction;
      const actionTrigger = { ...state.actionTriggers[a.payload.actionTriggerIndex] };
      actionTrigger.actions.push(a.payload.action);

      const actionTriggers = [...state.actionTriggers];
      actionTriggers[a.payload.actionTriggerIndex] = actionTrigger;

      return {
        ...state,
        actionTriggers,
      };
    }
    case ActionTriggerEditorActions.DELETE_ACTION: {
      const a = action as DeleteActionAction;

      const actionTrigger = { ...state.actionTriggers[a.payload.actionTriggerIndex] };
      actionTrigger.actions.splice(a.payload.actionIndex, 1);

      const actionTriggers = [...state.actionTriggers];
      actionTriggers[a.payload.actionTriggerIndex] = actionTrigger;

      return {
        ...state,
        actionTriggers,
      };
    }
    case ActionTriggerEditorActions.DELETE_ACTION_TRIGGER: {
      const a = action as DeleteActionTriggerAction;

      const actionTriggers = [...state.actionTriggers];
      actionTriggers.splice(a.payload.actionTriggerIndex, 1);

      return {
        ...state,
        actionTriggers,
      };
    }
    case ActionTriggerEditorActions.SET_ACTION_TRIGGER_ACTIVE_ACTION: {
      const a = action as SetActionTriggerActiveAction;
      state.actionTriggers[a.payload.actionTriggerIndex].active = a.payload.active;
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}

// All getter of state
export const actionTriggerEditorState = createFeatureSelector<ActionTriggerState>('actionTriggerEditor');
export const getActionTriggers = createSelector(actionTriggerEditorState, (state: ActionTriggerState) => state.actionTriggers);
export const getSelectedActionTrigger = createSelector(
  actionTriggerEditorState,
  (state: ActionTriggerState) => state.actionTriggers[state.selectedActionTriggerIndex]
);
