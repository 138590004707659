import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActionTriggerEditorComponent} from './action-trigger-editor/action-trigger-editor.component';
import {RouterModule, Routes} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {SharedUiModule} from '../../shared-ui/shared-ui.module';
import {ActionChooserComponent} from './action-chooser/action-chooser.component';


const routes: Routes = [
    /*
    {
        path: '',
        redirectTo: 'editor',
        pathMatch: 'full'
    },
    */
    {
        path: 'editor/add/:position',
        component: ActionTriggerEditorComponent,
    },
    {
        path: 'editor',
        component: ActionTriggerEditorComponent,
    }
];

@NgModule({
    declarations: [ActionTriggerEditorComponent, ActionChooserComponent],
    imports: [
        CommonModule,
        IonicModule,
        SharedUiModule,
        RouterModule.forChild(routes),
    ],
    exports: [ActionChooserComponent]
})
export class ActionTriggerModule {
}
