import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

const momentConstructor = moment;

@Pipe({
  pure: true,
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: number, ...args: any[]): string {
    if (!value) return '00:00:00';
    return momentConstructor(value).format(args[0]);
  }
}
