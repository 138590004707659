import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeatMakerComponent} from './beat-maker/beat-maker.component';
import { BeatMakerBarComponent } from './beat-maker/beat-maker-bar/beat-maker-bar.component';
import {ComponentsModule} from "../../components/components.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
    declarations: [BeatMakerComponent, BeatMakerBarComponent],
    imports: [
        CommonModule,
        ComponentsModule
    ],
    exports: [BeatMakerComponent]
})
export class BeatmakerModule {
}
