<div class="tracks" *ngIf="!(isLoadingPattern$ | async)">
    <div class="steps">
        <div class="step" *ngFor="let bar of (noBars$ | async);let i = index"
            [ngStyle]="{'margin-right': (i+1)%4==0?'8px':'2px'}"
            [ngClass]="{'currentlyPlaying':(currentPlayingBarIndex$ | async)===i}">
            <div class="number" (click)="handleSeek(i)">{{i+1}}</div>
            <div class="current-bar-indicator"></div>
        </div>
    </div>
    <div *ngFor="let track of (pattern$ | async)?.tracks;let i=index" class="track {{track.id}}">
        <div class="name">{{track.name}}</div>
        <um-button [toggle]="false" (click)="muteTrack(!track.mute, track, i)" [selected]="track.mute">M</um-button>
        <um-button [toggle]="false" (click)="soloTrack(!track.solo, track,i)" [selected]="track.solo">S</um-button>
        <div class="bars">
            <beat-maker-bar *ngFor="let bar of track.bars;let i = index" [track]="track" [barIndex]="i"
                [currentlyPlaying]="(currentPlayingBarIndex$ | async)===i"></beat-maker-bar>
        </div>
    </div>
</div>
<div class="tracks" *ngIf="(isLoadingPattern$ | async)">
    Loading Beat...
</div>
<div class="transport">
    <div class="logo"><span class="beat">BEAT</span><span class="maker">MAKER</span></div>
    <div class="name" (click)="changePattern()">{{(pattern$ | async)?.name}}</div>
    <um-button (click)="play()" [selected]="(isBeatMakerPlaying$ | async)">PLAY</um-button>
    <um-button (click)="stop()"  [selected]="!(isBeatMakerPlaying$ | async)">STOP</um-button>
    <ox-spinner class="speed-spinner" [min]="60" [max]="200" [steps]="1" [value]="(pattern$ | async)?.bpm" (valueChanged)="speedChanged($event)"></ox-spinner>
    <um-button (click)="toggleEcho()" [selected]="(isEchoEnabled$ | async)">ECHO</um-button>
</div>

<beat-maker-bar #ghostBar1 class="animating-block"></beat-maker-bar>
<beat-maker-bar #ghostBar2 class="animating-block"></beat-maker-bar>
<beat-maker-bar #ghostBar3 class="animating-block"></beat-maker-bar>
<beat-maker-bar #ghostBar4 class="animating-block"></beat-maker-bar>