import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isBeatMakerVisible, isMixerVisible, isMusicArchiveVisible } from '../core/redux/reducers/general.reducer';
import { getWaveformCache } from '../core/redux/reducers/player.reducer';
import { SoundPlayerComponent } from './sound-player/sound-player.component';
import { WaveformCache } from './waveform/WaveformCache';
import { VideoMixComponent } from './widgets/video-mix/video-mix.component';

@Component({
  selector: 'main',
  templateUrl: './main.page.html',
  styleUrls: ['./main.page.scss'],
})
export class MainPage implements OnInit, AfterViewInit {
  @ViewChild('soundPlayer1', { static: false }) soundPlayer1: SoundPlayerComponent;
  @ViewChild('soundPlayer2', { static: false }) soundPlayer2: SoundPlayerComponent;
  @ViewChild('videoMix', { static: false }) videoMix: VideoMixComponent;

  // TOOD: check error
  waveformCache1$: Observable<WaveformCache> = this.store.select(getWaveformCache(0));
  waveformCache2$: Observable<WaveformCache> = this.store.select(getWaveformCache(1));
  mixerVisible$: Observable<boolean> = this.store.select(isMixerVisible);
  beatMakerVisible$: Observable<boolean> = this.store.select(isBeatMakerVisible);
  musicArchiveVisible$: Observable<boolean> = this.store.select(isMusicArchiveVisible);

  mobile = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.checkMobile(window.innerWidth);
  }

  ngAfterViewInit(): void {
    //  this.store.dispatch(loadGroupAction({ groupID: null }));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkMobile(event.target.innerWidth);
  }

  private checkMobile(width) {
    this.mobile = width < 600;
  }
}
