<canvas
    #internalCanvasER
    [width]="this.width"
    [height]="this.height"
    class="segmentCanvas"
    [style.transform]="this.segmentPositionCanvasPxTranlate"
  >
  </canvas>

<!--  
    [ngStyle] ="transform: this.segmentPositionCanvasPxTranlate}
     [ngStyle] ="transform: this.segmentPositionCanvasPxTranlate}"
    [ngStyle] ="{transform: translate(this.segmentPositionCanvasPx)}"
    [ngStyle] ="{left: this.segmentPositionCanvasPx}" -->
