import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest, interval, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { WaveformCache } from '../../../main/waveform/WaveformCache';
import { Track } from '../../../music-archive/track';
import { AudioService } from '../../../services/audio.service';
import { MidiService } from '../../../services/midi.service';
import { VisualsService } from '../../../services/visuals.service';
import { setLevelsAction } from '../actions/mixer.actions';
import {
  cueAction,
  loadIntoPlayerAction,
  loadIntoPlayerFailedAction,
  loadIntoPlayerSuccessfullAction,
  loopAction,
  loopInAction,
  loopOutAction,
  playAction,
  saveCuePointAction,
  setPitchAction,
  setTimeAction,
  setVirtualVelocityAction,
  setVirtualVelocityAvgTimeAction,
} from '../actions/player.actions';
import { getTrack } from '../reducers/player.reducer';
import { playAvgTimeAction, setVolumeAction } from './../actions/player.actions';

declare var AudioContext;
declare var webkitAudioContext;

@Injectable()
export class PlayerEffects {
  public static timeRate = 55;

  private timerSubs: Subscription[] = [];

  constructor(
    private store: Store,
    private actions$: Actions,
    private visualsService: VisualsService,
    private audioService: AudioService,
    private midiService: MidiService,
    public alertController: AlertController
  ) {
    // alert('UM7 effects');
  }

  loadIntoPlayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadIntoPlayerAction),
      switchMap((action) => {
        return this.audioService.loadTrack$(action.playerID, action.track).pipe(
          map((waveformCache: WaveformCache) => {
            const length = this.audioService.getLength(action.playerID);
            const track = { ...action.track, length };
            return loadIntoPlayerSuccessfullAction({
              playerID: action.playerID,
              track,
              waveformData: waveformCache,
            });
          }),
          catchError((error: any) => {
            console.error('error while loading track', error);
            return of(loadIntoPlayerFailedAction({ ...action, error }));
          })
        );
      })
    )
  );

  loadIntoPlayerFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadIntoPlayerFailedAction),
        map((action) => {
          this.presentAlert(`Could not load track ${action.track.src}- (no internet connection?)`);
        })
      ),
    { dispatch: false }
  );

  play$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(playAction),
        map((action) => {
          this.visualsService.playVideo();

          this.audioService.play(action.playerID);

          const timerSub: Subscription = this.timerSubs[action.playerID];
          if (timerSub) {
            timerSub.unsubscribe();
          }
          this.timerSubs[action.playerID] = interval(PlayerEffects.timeRate).subscribe((value) => {
            const v = value * PlayerEffects.timeRate;
            // if (v > fromPlayer.FAKE_LENGTH) {
            //   this.store.dispatch(new CueAction({playerID: action.payload.playerID}));
            // }
            // else {
            const l = Math.random();
            // const l = this.audioService.getLevels(); // TODO:
            this.store.dispatch(setLevelsAction({ playerID: action.playerID, levelLeft: l, levelRight: l }));
            this.store.dispatch(setTimeAction({ playerID: action.playerID, time: v }));
            //  }
          });
        })
      ),
    { dispatch: false }
  );

  playAvgTime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(playAvgTimeAction),
        tap((action) => {
          // this.visualsService.playVideo();
          this.audioService.playAvgTime(action.playerID, action.received);

          // LED //
          if (this.audioService.isPlaying(action.playerID)) {
            this.midiService.play(1);
          } else {
            this.midiService.play(0);
          }

          /*
                    let timerSub: Subscription = this.timerSubs[action.payload.playerID];
                    if (timerSub) {
                        timerSub.unsubscribe();
                    }
                    this.timerSubs[action.payload.playerID] = interval(PlayerEffects.timeRate).subscribe(value => {
                        const v = value * PlayerEffects.timeRate;
                        //if (v > fromPlayer.FAKE_LENGTH) {
                        //   this.store.dispatch(new CueAction({playerID: action.payload.playerID}));
                        //}
                        //else {
                        const l = Math.random();
                        this.store.dispatch(MixerActions.setLevelsAction(action.payload.playerID, l, l));
                        this.store.dispatch(new SetTimeAction({playerID: action.payload.playerID, time: v}));
                        //  }
                    });
                    */
        })
      ),
    { dispatch: false }
  );

  stop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cueAction),
        map((action) => {
          // this.visualsService.stopVideo();
          this.audioService.stop(action.playerID);

          if (this.timerSubs[action.playerID]) {
            this.timerSubs[action.playerID].unsubscribe();
          }
          this.timerSubs[action.playerID] = null;
        })
      ),
    { dispatch: false }
  );

  setVirtualVelocity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setVirtualVelocityAction),
        map((action) => {
          this.audioService.setVirtualVelocity(action.playerID, action.positionMs, action.timems);
        })
      ),
    { dispatch: false }
  );

  setVirtualVelocityAvgTime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setVirtualVelocityAvgTimeAction),
        map((action) => {
          this.audioService.setVirtualVelocityAvgTime(action.playerID, action.positionMs, action.timems, action.received);
        })
      ),
    { dispatch: false }
  );

  setPitch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPitchAction),
        map((action) => {
          this.audioService.setPitch(action.playerID, action.value);
        })
      ),
    { dispatch: false }
  );

  setVolume$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setVolumeAction),
        map((action) => {
          this.audioService.setVolume(action.playerID, action.volume);
        })
      ),
    { dispatch: false }
  );

  saveCuePoint = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveCuePointAction),
        map((action) => action),
        // tslint:disable-next-line: deprecation
        switchMap((action) => combineLatest(of(action), this.store.select(getTrack(action.playerID)))),
        map(([action, track]: [any, Track]) => {
          console.log('track', track);
          console.log('action', action);
          const pos = this.audioService.getPosition(action.playerID);
          console.log('pos', pos);

          // this.dataSerive.saveCuePointFromCurrentTime(action.payload.cuepointIndex);
        })
      ),
    { dispatch: false }
  );

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      // subHeader: 'Subtitle',
      mode: 'ios',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  loop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loopAction),
        map((action) => {
          this.audioService.loop(action.playerID, action.loop);
        })
      ),
    { dispatch: false }
  );

  loopIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loopInAction),
        map((action) => {
          this.audioService.setLoopIn(action.playerID, action.time);
        })
      ),
    { dispatch: false }
  );

  loopOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loopOutAction),
        map((action) => {
          this.audioService.setLoopOut(action.playerID, action.time);
        })
      ),
    { dispatch: false }
  );
}
