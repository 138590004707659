import { NgModule } from '@angular/core';
import { UMButtonComponent } from './umbutton/umbutton.component';
import { CommonModule } from '@angular/common';
import { KnobComponent } from './knob/knob.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [UMButtonComponent, KnobComponent, SpinnerComponent],
  exports: [UMButtonComponent, KnobComponent, SpinnerComponent]
})
export class ComponentsModule {}
