import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import PouchDB from 'pouchdb';
import { TreeNode } from 'primeng/api';
import { Observable, concat, from, of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { GroupChangedAction } from '../core/redux/actions/archive.actions';
import { Track } from '../music-archive/track';
import { Group } from './group';

import AllDocsResponse = PouchDB.Core.AllDocsResponse;
import { FileInfo, Filesystem, ReaddirResult } from '@capacitor/filesystem';
import { ChartsResponse, TrackDataForResponse } from '../core/interfaces/um-functions.interface';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private db: any;

  // configure Bugsnag asap
  // private bugsnagClient = bugsnag('6c3e1ee915a118444b4aae35e2f02da5');

  constructor(private http: HttpClient, private store: Store) {
    // PouchDB.debug.enable('*');

    this.db = new PouchDB('https://bfc34c7c-0773-47ce-bbe8-0293907eaf5f-bluemix.cloudant.com/ultramixer', {
      auth: {
        username: 'ghtheentriaterysilybrion',
        password: '2fd6765ee391e4d34d7c24a54e89acb0f31ef710',
      },
    });

    let changes = this.db
      .changes({
        since: 'now',
        live: true,
        include_docs: true,
      })
      .on('change', (change) => {
        console.log('change: ', change);
        if (change.doc) {
          if (change.doc._id.startsWith('group:')) {
            this.store.dispatch(new GroupChangedAction({ group: change.doc as Group }));
          }
        }
      })
      .on('complete', (info) => {
        // changes() was canceled
        console.log('complete:', info);
      })
      .on('error', (err) => {
        console.log('DB Error:', err);
        // this.bugsnagClient.notify(err);
      });
  }

  getGroups$(): Observable<TreeNode[]> {
    return from<AllDocsResponse<Group>>(
      this.db.allDocs({
        include_docs: true,
        startkey: 'group:',
        endkey: 'group:\uffff',
      })
    ).pipe(
      map((response: AllDocsResponse<Group>) =>
        response.rows.map((row: any) => {
          return Group.treeNodeFromGroup(row.doc);
        })
      )
    );
  }

  // TODO: remove??
  getTracks2(): Observable<Track[]> {
    return new Observable<Track[]>((observer) => {
      const tracks: Track[] = [];
      for (let i = 1; i <= 40; i++) {
        tracks.push({ artist: `Artist ${i}`, title: `Title ${i}`, bpm: 120, length: 215000, rating: 5 } as Track);
      }
      observer.next(tracks);
      observer.complete();
    }).pipe(delay(1000));
  }

  getTracks(): Observable<Track[]> {
    return from(
      this.db.allDocs({
        include_docs: true,
        startkey: 'track:',
      })
    ).pipe(map((response: any) => response.rows.map((row) => row.doc as Track)));
  }

  addDirectory(dir: File): Observable<any> {
    console.log('addDirectory: dir: ', dir);

    // directory: FilesystemDirectory.Documents

    /*
        return new Observable<boolean>(observer => {
            Filesystem.readdir({
                path: dir.path
            }).then((value: ReaddirResult) => {
                console.log("value");
                console.log(value);

                observer.next(true);
                observer.complete();

            }).catch((reason:any) => {
                console.log("reason");
                console.log(reason);
            })
        });*/

    return this.addGroup(dir.name).pipe(
      switchMap((result: Group) => {
        console.log('result');
        console.log(result);
        return from(
          Filesystem.readdir({
            path: dir.name,
          })
        ).pipe(
          map((ret: ReaddirResult) => {
            return this.addFiles(ret.files);
          })
        );
      }),
      catchError((err) => {
        console.log('err');
        console.log(err);
        return of('error');
      })
    );
  }

  // todo: write unit test!
  addGroup(name: string): Observable<Group> {
    const newGroup = {
      _id: `group:${name}:${new Date().getTime()}`,
      name,
    } as Group;

    return from(this.db.put(newGroup)).pipe(
      map((e) => {
        console.log('e');
        console.log(e);
        return newGroup;
      }),
      catchError((err) => {
        console.log('err');
        console.log(err);
        return of(err);
      })
    );
  }

  // todo: write unit test!
  private addFiles(files: FileInfo[]): Observable<any> {
    const addJobs: Observable<any>[] = [];

    files.forEach((file: FileInfo, index: number) => {
      addJobs.push(
        from(
          this.db.put({
            _id: `track:${file.name}}`,
            artist: `Artist ${index}`,
            title: `Title ${index}`,
            src: '', // todo
            length: 215000, // todo:
          } as Track)
        ).pipe(
          map((e) => {
            console.log('e');
            console.log(e);
            return true;
          }),
          catchError((err) => {
            console.log('err');
            console.log(err);
            return of(err);
          })
        )
      );
    });

    return concat(addJobs);
  }

  removeGroup(group: TreeNode): Observable<boolean> {
    console.log('data service: remove group ' + group);
    return from(this.db.remove(group.data._id, group.data._rev)).pipe(map((ret) => ret as boolean));
    // return of(true).pipe(delay(1000));
  }

  renameGroup(group: TreeNode, newName: string): Observable<boolean> {
    const newGroup = { ...group.data, name: newName };
    return from(this.db.put(newGroup)).pipe(map((ret: any) => ret.ok));
  }

  // todo
  login(username: string, password: string): Observable<boolean> {
    console.log('login');
    return of(true).pipe(delay(1000));
  }

  updateTrack(track: Track) { }

  // For charts
  getChartsList$(url: string): Observable<TrackDataForResponse[]> {
    return this.http.get<ChartsResponse>(url).pipe(
      map((response) => {
        let list: TrackDataForResponse[] = [];
        if (response.list) {
          list = response.list;
        }
        
        console.log('###', url, JSON.parse(JSON.stringify(list)));

        return list;
      })
    )
  }
}
