import { HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AudioContextModule } from 'angular-audio-context';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { extModules } from './build-specifics/index';
import { EmbeddingModule } from './embedding/embedding.module';
import { MainPageModule } from './main/main.module';
// import { NgxElectronModule } from 'ngx-electron';
import { StarRatingModule } from 'angular-star-rating';
import { NgxSliderModule } from 'ngx-slider-v2';
import { WaveformModule } from './main/waveform/waveform.module';
import { UitestsModule } from './uitests/uitests.module';

// configure Bugsnag asap
// TODO
// const bugsnagClient = bugsnag('6c3e1ee915a118444b4aae35e2f02da5');

// create a factory which will return the bugsnag error handler

// TODO:
/*
export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient);
}
*/

@NgModule({
  declarations: [AppComponent],
  exports: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AudioContextModule.forRoot('balanced'),
    AppRoutingModule,
    HttpClientModule,
    MainPageModule,
    EmbeddingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    UitestsModule,
    WaveformModule,
    StarRatingModule.forRoot(),
    extModules,
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 , connectInZone: true}) : [],
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideHttpClient(withFetch()),
    /* Pass the BugsnagErrorHandler class along to the providers for your module */
    // { provide: ErrorHandler, useFactory: errorHandlerFactory } // TODO
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
