import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MixerComponent } from './mixer.component';
import { VolumeComponent } from './volume/volume.component';
import { ComponentsModule } from '../../components/components.module';
import { LevelComponent } from './volume/level/level.component';
import { LevelsvgComponent } from './volume/levelsvg/levelsvg.component';
import { LevelCanvasComponent } from './volume/levelcanvas/levelcanvas.component';
import { CrossfaderComponent } from './crossfader/crossfader.component';

@NgModule({
  imports: [CommonModule, ComponentsModule],
  declarations: [MixerComponent, VolumeComponent, LevelComponent, LevelsvgComponent, LevelCanvasComponent, CrossfaderComponent],
  exports: [MixerComponent, VolumeComponent, LevelComponent, LevelsvgComponent, LevelCanvasComponent],
  providers: []
})
export class MixerModule {}
