import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupsBrowserMobileComponent } from './groups-browser-mobile.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from '@angular/router';
import { TrackListMobileComponent } from '../track-list-mobile/track-list-mobile.component';
import { TrackListMobileModule } from '../track-list-mobile/track-list-mobile.module';

const routes: Routes = [
  { path: 'x', component: GroupsBrowserMobileComponent },
  { path: ':id', component: TrackListMobileComponent }
];

@NgModule({
  declarations: [GroupsBrowserMobileComponent],
  imports: [RouterModule.forChild(routes), CommonModule, IonicModule, RouterModule, TrackListMobileModule],
  exports: [GroupsBrowserMobileComponent]
})
export class GroupsBrowserMobileModule {}
