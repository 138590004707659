import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

declare var PrecisionInputs: any;
// var PrecisionInputs = require("precision-inputs");
// import * as from 'precision-inputs';
// import FLStandardKnob from 'precision-inputs';

@Component({
  selector: 'knob',
  templateUrl: './knob.component.html',
  styleUrls: ['./knob.component.scss'],
})
export class KnobComponent implements OnInit, AfterViewInit {
  @ViewChild('knobInput', { static: false }) knobInput: ElementRef;
  private knob: any;

  @Input() label: string;
  @Output() value = new EventEmitter<number>();
  @Output() kill = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.knob = new PrecisionInputs.FLStandardKnob(this.knobInput.nativeElement, {
      min: 0, // -10,
      max: 10000, // 10
      indicatorDot: true,
      color: '#ffd261',
      indicatorRingType: 'split',
      initial: 5000,
    });

    this.knob.addEventListener('change', (evt) => {
      // console.log(evt.target.value);
      this.value.emit(evt.target.value);
    });
  }

  handleKnobChange(event) {
    this.value.emit(event.target.value);
  }

  handleKillSelected(event) {
    this.kill.emit(event);
  }
}
