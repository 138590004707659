<ion-header translucent="false">
  <ion-toolbar>
    <ion-title>Music</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list insets>
    <ion-item *ngFor="let group of groups$ | async" (click)="handleLoadTracks(group)" detail="true">
      <ion-label>{{ group.label }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>