import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import {
  enterFullScreenModeAction,
  loginAction,
  loginSucceededAction,
  quitFullScreenModeAction,
  toggleFullScreenModeAction,
} from './../actions/general.actions';

@Injectable()
export class GeneralEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    //  private fullscreenService: ScreenfullService,
    private dataService: DataService,
    private router: Router
  ) {}

  enterFullscreen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(enterFullScreenModeAction),
        map((action) => {
          // this.fullscreenService.request();
        })
      ),
    { dispatch: false }
  );

  quitFullscreen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(quitFullScreenModeAction),
        map((action) => {
          // this.fullscreenService.exit();
        })
      ),
    { dispatch: false }
  );

  toggleFullscreen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleFullScreenModeAction),
        map((action) => {
          /*
          if (this.fullscreenService.isFullScreenModeActive) {
            this.fullscreenService.exit();
          } else {
            this.fullscreenService.request();
          }
          */
        })
      ),
    { dispatch: false }
  );

  login = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction),
      switchMap((action) =>
        this.dataService.login(action.username, action.password).pipe(
          map((ret) => {
            console.log('ret');
            console.log(ret);
            return loginSucceededAction();
          })
        )
      )
    )
  );

  loginSuccessful = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSucceededAction),
        map(() => this.router.navigateByUrl('main'))
      ),
    { dispatch: false }
  );
}
