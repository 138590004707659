import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MidiMessage } from '../../../models/MidiMessage';
import { ActionWithPayload } from '../actions/action.interface';

export interface MidiState {
  lastMessage: MidiMessage;
}

const initialState: MidiState = {
  lastMessage: null,
};

export function midiReducer(state: MidiState = initialState, action: ActionWithPayload): MidiState {
  switch (action.type) {
    /*
        case MidiActions.MIDI_INPUT: {
            return {
                ...state, lastMessage: (action as MidiInputAction).payload.midiMessage
            };
        }
        */
    default: {
      return state;
    }
  }
}

// All getter of midi state
export const midiState = createFeatureSelector<MidiState>('midi');
export const getMidiMessages = createSelector(midiState, (state: MidiState) => state.lastMessage);
