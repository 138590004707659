import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sound-player-embed',
  templateUrl: './sound-player-embed.component.html',
  styleUrls: ['./sound-player-embed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoundPlayerEmbedComponent {
  @Input() playerID?: string;

  constructor(private route: ActivatedRoute) {
    this.playerID = this.route.snapshot.paramMap.get('playerID');
  }
}
