import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UMButtonComponent } from '../../components/umbutton/umbutton.component';
import { PitchFaderComponent } from './pitch-fader/pitch-fader.component';

import {
  cueAction,
  decreaseLoopBeatAction,
  increaseLoopBeatAction,
  loadIntoPlayerAction,
  loopBeatAction,
  loopInAction,
  loopOutAction,
  playAction,
  setCuePointAction,
  setPitchAction,
  togglePitchModeAction,
  togglePlayedToSyncToAction,
} from '../../core/redux/actions/player.actions';
import {
  PlayerState,
  getPitchMode,
  getPlayerIDToSyncTo,
  getWaveformCache,
  isLoading,
  isPlaying,
} from '../../core/redux/reducers/player.reducer';
import { Track } from '../../music-archive/track';
import { AudioService } from '../../services/audio.service';
import { WaveformCache } from '../waveform/WaveformCache';
import { TurntableComponent } from './turntable/turntable.component';

@Component({
  selector: 'sound-player',
  templateUrl: './sound-player.component.html',
  styleUrls: ['./sound-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // changeDetection: ChangeDetectionStrategy.Default,
})
export class SoundPlayerComponent implements OnInit {
  isPlaying$: Observable<boolean>;
  isLoading$: Observable<boolean>;

  pitchMode$: Observable<'pitch' | 'tempo' | 'key'>;
  playerIDToSyncTo$: Observable<number>;

  @ViewChild('cueButton', { static: false }) cueButton: UMButtonComponent;
  @ViewChild('playButton', { static: false }) playButton: UMButtonComponent;
  @ViewChild('turntable', { static: false }) turntable: TurntableComponent;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('pitchSlider', { static: false }) pitchSlider: PitchFaderComponent;

  @Input() playerID: number;

  waveformCache$: Observable<WaveformCache>;

  constructor(private store: Store<PlayerState>, private audioService: AudioService) {}

  ngOnInit() {
    this.isPlaying$ = this.store.select(isPlaying(this.playerID));
    this.isLoading$ = this.store.select(isLoading(this.playerID));

    this.waveformCache$ = this.store.select(getWaveformCache(this.playerID));
    this.pitchMode$ = this.store.select(getPitchMode(this.playerID));
    this.playerIDToSyncTo$ = this.store.select(getPlayerIDToSyncTo(this.playerID));
  }

  handlePlay() {
    this.store.dispatch(playAction({ playerID: this.playerID }));
  }

  handleCue() {
    this.store.dispatch(cueAction({ playerID: this.playerID }));
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    const fileUrl = this.audioService.getFileUrlFromFile(file);

    this.store.dispatch(
      loadIntoPlayerAction({
        playerID: this.playerID,
        track: new Track(fileUrl, 'Unknown artist', 'Unknown title', 0, 120, 3), // TODO: id3!
      })
    );
  }

  test() {
    alert('so');
  }

  handleLoopIn() {
    const pos: number = this.audioService.getPosition(this.playerID);
    console.log('pos', pos);
    this.store.dispatch(loopInAction({ playerID: this.playerID, time: pos }));
  }

  handleLoopOut() {
    const pos = this.audioService.getPosition(this.playerID);
    this.store.dispatch(loopOutAction({ playerID: this.playerID, time: pos }));
  }

  handleSetCuePoint(cuepointIndex: number) {
    this.store.dispatch(setCuePointAction({ playerID: this.playerID, cuepointIndex }));
  }

  handleClickFileInput() {
    console.log('this.fileInput', this.fileInput);
    this.fileInput.nativeElement.click();
  }

  handleLoopBeat(beat: number) {
    this.store.dispatch(loopBeatAction({ playerID: this.playerID, beat }));
  }

  handleIncreaseLoopBeat() {
    this.store.dispatch(increaseLoopBeatAction({ playerID: this.playerID }));
  }
  handleDecreaseLoopBeat() {
    this.store.dispatch(decreaseLoopBeatAction({ playerID: this.playerID }));
  }

  handlePitchChanged(value: number) {
    this.store.dispatch(setPitchAction({ playerID: this.playerID, value }));
  }

  handleResetPitch() {
    this.pitchSlider.reset();
  }

  handleIncreasePitch() {
    this.pitchSlider.setValue(this.pitchSlider.getValue() + 0.1);
  }

  handleDecreasePitch() {
    this.pitchSlider.setValue(this.pitchSlider.getValue() - 0.1);
  }

  handleTogglePitchMode() {
    this.store.dispatch(togglePitchModeAction({ playerID: this.playerID }));
  }

  handleSync() {
    throw new Error('Method not implemented.');
  }

  handleTogglePlayer() {
    this.store.dispatch(togglePlayedToSyncToAction({ playerID: this.playerID }));
  }
}
