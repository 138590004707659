import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import 'brace';
import 'brace/mode/xml';
import 'brace/theme/github';
import 'brace/theme/chrome';

@Component({
    selector: 'app-midi-editor',
    templateUrl: './midi-editor.component.html',
    styleUrls: ['./midi-editor.component.scss']
})
export class MidiEditorComponent implements OnInit {

    midiContent: string = "?";
    config: any = {
        wrap: true
    };

    constructor(private http: HttpClient) {
    }

    ngOnInit() {
        this.http.get("./assets/midi/DENON-MCX8000-P4.xml", {responseType: 'text'}).subscribe(((value: any) => {
            this.midiContent = value;
        }));
    }

}
