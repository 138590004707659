import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { WaveformCache } from '../../../main/waveform/WaveformCache';
import { PitchMode } from '../../../models/pitch-mode';
import { Track } from '../../../music-archive/track';
import {
  cueAction,
  increaseLoopBeatAction,
  loadIntoPlayerAction,
  loadIntoPlayerSuccessfullAction,
  playAction,
  setPitchAction,
  setPitchModeAction,
  setTimeAction,
  setTimeUM6Action,
  setTrackTitleUM6Action,
  togglePitchModeAction,
  togglePlayedToSyncToAction,
} from '../actions/player.actions';

export interface PlayerState {
  isPlaying: boolean;
  isLoading: boolean;
  time: number;
  remainingTime: number;
  progress: number;
  track: Track;
  waveformData: WaveformCache;
  loopBeat: number;
  playerID: number;
  // playerID of the player where the current player should sync to when the sync button is pressed
  playerIDToSyncTo: number;
  // the pitch setting of the pitch slider (in %)
  pitch: number;
  pitchMode: PitchMode;
}

export const playerReducerInitialState: PlayerState = {
  isPlaying: false,
  isLoading: false,
  time: 0,
  remainingTime: 0,
  progress: 0,
  track: null,
  waveformData: null,
  loopBeat: null,
  playerID: -1,
  playerIDToSyncTo: null,
  pitch: 0,
  pitchMode: 'pitch',
};

export const playerReducer = createReducer(
  playerReducerInitialState,

  on(loadIntoPlayerAction, (state, action): PlayerState => {
    console.log('>>> loadIntoPlayerAction1', action);
    if (!checkPlayerID(action, state)) return state;

    return {
      ...state,
      track: null,
      time: 0,
      isLoading: true,
      remainingTime: 0,
      progress: 0,
    };
  }),
  on(loadIntoPlayerSuccessfullAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;

    return {
      ...state,
      track: action.track,
      time: 0,
      isLoading: false,
      remainingTime: action.track.length,
      progress: 0,
      waveformData: action.waveformData,
    };
  }),

  on(playAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      isPlaying: true,
    };
  }),
  on(cueAction, (state, action) => getCueState(state, action)),
  on(increaseLoopBeatAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      // loopBeat: getLoopBeat(playerID)+1),
    };
  }),

  on(setTimeAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;

    let trackLength = getTrackLength(state, action.playerID);

    if (trackLength <= 0) {
      trackLength = 60 * 3 * 1000;
    }

    if (action.time > trackLength) {
      return getCueState(state, action);
    } else {
      return {
        ...state,
        time: action.time,
        remainingTime: trackLength - action.time,
        progress: action.time / trackLength,
      };
    }
  }),
  on(setTimeUM6Action, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      time: action.time,
      remainingTime: action.remainingTime,
    };
  }),
  on(setTrackTitleUM6Action, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    console.log('>>> setTrackTitleUM6Action', action.artist);
    const track = new Track(null, action.artist, action.title, 0, 0, 0);
    return {
      ...state,
      track: track,
    };
  }),
  on(setPitchAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      pitch: action.value,
    };
  }),
  on(togglePitchModeAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      pitchMode: state.pitchMode == 'tempo' ? 'pitch' : 'tempo',
    };
  }),
  on(togglePlayedToSyncToAction, (state, action): PlayerState => {
    return {
      ...state,
      playerIDToSyncTo: state.playerIDToSyncTo++ % 2,
    };
  }),
  on(setPitchModeAction, (state, action): PlayerState => {
    return {
      ...state,
      pitchMode: action.pitchMode,
    };
  })
);

function getCueState(state: PlayerState, action: any) {
  if (!checkPlayerID(action, state)) return state;
  return {
    ...state,
    isPlaying: false,
    time: action.playerID,
    remainingTime: getTrackLength(state, action.playerID),
    progress: 0,
  };
}

function getTrackLength(state: PlayerState, playerID: number) {
  const track = state.track[playerID];
  if (!track) {
    return 0;
  } else {
    return track.length;
  }
}

function getLoopBeat(state: PlayerState, playerID: number): number {
  return state.loopBeat[playerID];
}

function checkPlayerID(action: any, state: PlayerState): boolean {
  return action.playerID == state.playerID;
}

// All getter of general state

const playerStates: Array<any> = [createFeatureSelector<PlayerState>('player1'), createFeatureSelector<PlayerState>('player2')];
// console.log('>>> playerStates',playerStates);

export const isPlaying = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.isPlaying);

export const isLoading = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.isLoading);

export const getTime = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.time);

export const getRemainingTime = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.remainingTime);

export const getProgress = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.progress);

export const getTrack = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.track);

export const getWaveformCache = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.waveformData);

export const getPitch = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.pitch);
export const getPitchMode = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.pitchMode);
export const getPlayerIDToSyncTo = (playerID: number) =>
  createSelector(getPlayerState(playerID), (state: PlayerState) => (state.playerID == 0 ? 1 : 2));

export function getPlayerState(playerID): any {
  const s = playerStates[playerID];
  // console.log('>>> playerState', playerID, playerStates,s);
  return s;
}
