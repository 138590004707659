import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WaveformComponent} from './waveform/waveform.component';
import {BSWaveformComponent} from './bs-waveform/bs-waveform.component';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DualWaveformComponent } from './dual-waveform/dual-waveform.component';
import { WaveformModule } from './waveform.module';

@NgModule({
    imports: [
       WaveformModule
    ],
    exports:[
        WaveformModule
    ]
})
export class WaveformNoRouteModule {}
