import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { ComponentsModule } from '../components/components.module';
import { WidgetsModule } from '../main/widgets/widgets.module';
import { SharedUiModule } from '../shared-ui/shared-ui.module';
import { GroupsBrowserMobileModule } from './groups-browser-mobile/groups-browser-mobile.module';
import { GroupsBrowserComponent } from './groups-browser/groups-browser.component';
import { MusicArchiveComponent } from './music-archive.component';
import { TracksListComponent } from './tracks-list/tracks-list.component';
import { StarRatingModule } from 'angular-star-rating';
import { RatingModule } from 'primeng/rating';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChartsListModule } from './charts-list/charts-list.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    TreeModule,
    ContextMenuModule,
    GroupsBrowserMobileModule,
    TableModule,
    IonicModule,
    WidgetsModule,
    StarRatingModule,
    SharedUiModule,
    RatingModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    ChartsListModule,
  ],
  declarations: [
    MusicArchiveComponent,
    GroupsBrowserComponent,
    TracksListComponent,
  ],
  exports: [
    MusicArchiveComponent,
  ],
})
export class MusicArchiveModule {}
