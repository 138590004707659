import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface SendParams {
    email: string;
    ordernumber?: string;
    supportCode?: string;
    locale: string;
    problemDesc: string;
    logfileContent: string;
}

@Injectable({
    providedIn: 'root'
})
export class SendLogService {


    constructor(private http: HttpClient) {
        console.log('SendLogService');
    }

    public send(urlStr: string, sendParams: SendParams): Observable<boolean> {
        const msg: string = this.prepMessage(sendParams);

        return this.http.post<any>(urlStr, msg).pipe(
            map((result: any) => true)       // todo
        );
    }

    private prepMessage(sendParams: SendParams): string {
        // requiring repacement: mail, ordernumber, supportcode, locale, problem, logfiles

        // encode log string base64
        const base64Log = btoa(sendParams.logfileContent);

        // prepare message
        const xmlTpl = `<request>
            <mail>${sendParams.email}</mail>
            <ordernumber>${sendParams.ordernumber}</ordernumber>
            <supportcode>${sendParams.supportCode}</supportcode>
            <locale>${sendParams.locale}</locale>
            <problem>${sendParams.problemDesc}</problem>
            <logfiles>${base64Log}</logfiles>
            </request>`;
        // encode entire message base64
        const msg = btoa(xmlTpl);

        return msg;
    }
}
