import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { loadTracksAction } from '../core/redux/actions/archive.actions';

@Component({
  selector: 'music-archive',
  templateUrl: './music-archive.component.html',
  styleUrls: ['./music-archive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MusicArchiveComponent implements OnInit, AfterViewInit {
  constructor(private store: Store) {}

  ngOnInit() {}

  ngAfterViewInit(): void {}

  loadTracks(group: TreeNode) {
    this.store.dispatch(loadTracksAction({ groupID: group.key }));
  }

  handleGroupSelect(group: TreeNode) {
    this.loadTracks(group);
  }
}
