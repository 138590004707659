import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WaveformComponent} from './waveform/waveform.component';
import {BSWaveformComponent} from './bs-waveform/bs-waveform.component';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DualWaveformComponent } from './dual-waveform/dual-waveform.component';
import { WaveformModule } from './waveform.module';
import { WaveformImplementationTestComponent } from './waveform-implementation-test/waveform-implementation-test.component';
import { DualWaveformComponentv1Component } from './dual-waveform-v1/dual-waveform-v1.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'test',
    pathMatch: 'full'
  },
  {
    path: 'dual',
    redirectTo: 'dual2',
    pathMatch: 'full'
  },
  {
    path: 'dual2',
    component: DualWaveformComponent
  },
  {
    path: 'dual1',
    component: DualWaveformComponentv1Component
  },
  {
    path: 'bs',
    component: BSWaveformComponent
  },
  {
    path: 'test',
    component: WaveformImplementationTestComponent
  }
];

@NgModule({
    imports: [
        WaveformModule,
        RouterModule.forChild(routes)
    ]/*,
    exports:[
      WaveformModule
  ]*/
})
export class WaveformInclRoutesModule {}
