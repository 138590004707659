import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackListMobileComponent } from './track-list-mobile.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [TrackListMobileComponent],
  imports: [CommonModule, IonicModule],
  exports: [TrackListMobileComponent]
})
export class TrackListMobileModule {}
