import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { BeatMakerPattern } from '../../../main/beatmaker/beat-maker-pattern';
import { BeatMakerTrack } from '../../../main/beatmaker/beat-maker-track';
import {
  disableEchoAction,
  enableEchoAction,
  loadPatternAction,
  loadPatternFailedAction,
  loadPatternSucceededAction,
  playBeatAction,
  setCurrentPlayingBarAction,
  setSpeedAction,
  soloTrackAction,
  stopBeatAction,
  toggleEchoAction,
} from '../actions/beatmaker.actions';
import { initializingBeatMakerSucceededAction, muteTrackAction } from './../actions/beatmaker.actions';

export interface BeatMakerState {
  loadingPattern: boolean;
  playing: boolean;
  pattern: BeatMakerPattern;
  currentPlayingBarIndex: number;
  tracks: BeatMakerTrack[];
  speed: number;
  echoEnabled: boolean;
}

const initialState: BeatMakerState = {
  loadingPattern: false,
  pattern: null,
  playing: false,
  currentPlayingBarIndex: -1,
  tracks: [],
  speed: 120,
  echoEnabled: false,
};

export const beatMakerReducer = createReducer(
  initialState,
  on(
    playBeatAction,
    (state, action): BeatMakerState => ({
      ...state,
      playing: true,
    })
  ),
  on(
    stopBeatAction,
    (state: BeatMakerState, action): BeatMakerState => ({
      ...state,
      playing: false,
      currentPlayingBarIndex: -1,
    })
  ),
  on(
    loadPatternAction,
    (state: BeatMakerState, action): BeatMakerState => ({
      ...state,
      loadingPattern: true,
    })
  ),
  on(
    loadPatternSucceededAction,
    (state, action): BeatMakerState => ({
      ...state,
      loadingPattern: false,
      pattern: action.pattern,
      speed: action.pattern.bpm,
    })
  ),
  on(
    loadPatternFailedAction,
    (state, action): BeatMakerState => ({
      ...state,
      loadingPattern: false,
    })
  ),
  on(
    initializingBeatMakerSucceededAction,
    (state, action): BeatMakerState => ({
      ...state,
      loadingPattern: false,
      pattern: action.pattern,
      speed: action.pattern.bpm,
    })
  ),
  on(muteTrackAction, (state, action): BeatMakerState => {
    let pattern = { ...state.pattern };
    let tracks = [...pattern.tracks];
    let track = { ...action.track };
    track.mute = action.mute;

    tracks[action.trackIndex] = track;
    pattern.tracks = tracks;

    return { ...state, playing: false, pattern: pattern };
  }),
  on(soloTrackAction, (state, action): BeatMakerState => {
    let pattern = { ...state.pattern };
    let tracks = [...pattern.tracks];
    let track = { ...action.track };

    tracks.forEach((track) => {
      track.mute = true;
    });

    track.solo = action.solo;

    tracks[action.trackIndex] = track;
    pattern.tracks = tracks;

    return { ...state, playing: false, pattern: pattern };
  }),
  on(setCurrentPlayingBarAction, (state, action): BeatMakerState => {
    return {
      ...state,
      currentPlayingBarIndex: action.barIndex,
    };
  }),
  on(setSpeedAction, (state, action): BeatMakerState => {
    return {
      ...state,
      speed: action.bpm,
    };
  }),
  on(enableEchoAction, (state, action): BeatMakerState => {
    return {
      ...state,
      echoEnabled: true,
    };
  }),
  on(disableEchoAction, (state, action): BeatMakerState => {
    return {
      ...state,
      echoEnabled: false,
    };
  }),
  on(toggleEchoAction, (state, action): BeatMakerState => {
    return {
      ...state,
      echoEnabled: !state.echoEnabled,
    };
  })

  /*
  on(toggleTrackBarAction, (state, action) => ({
    ...state,
    pattern: toggleBar(state.pattern, action.track, action.barIndex),
  }))
  */
);

// All getter of general state
export const beatMakerState = createFeatureSelector<BeatMakerState>('beatMaker');
export const isLoadingPattern = createSelector(beatMakerState, (state: BeatMakerState) => state.loadingPattern);
export const isPlaying = createSelector(beatMakerState, (state: BeatMakerState) => state.playing);
export const getPattern = createSelector(beatMakerState, (state: BeatMakerState) => state.pattern);
export const currentPlayingBarIndex = createSelector(beatMakerState, (state: BeatMakerState) => state.currentPlayingBarIndex);
export const isEchoEnabled = createSelector(beatMakerState, (state: BeatMakerState) => state.echoEnabled);
