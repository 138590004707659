import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AudioSamplerComponent} from "./audio-sampler/audio-sampler.component";
import {WidgetsComponent} from "./widgets/widgets.component";
import {VideoMixComponent} from "./video-mix/video-mix.component";
import {IonicModule} from "@ionic/angular";
import {LiveVisualComponent} from "../../live-visual/live-visual.component";
import {ComponentsModule} from "../../components/components.module";
import {MidiConnectionComponent} from "./midi/midi-connection.component";

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        IonicModule
    ],
    declarations: [
        WidgetsComponent,
        VideoMixComponent,
        LiveVisualComponent,
        AudioSamplerComponent,
        MidiConnectionComponent],
    providers: [],
    exports: [
        WidgetsComponent,
        VideoMixComponent,
        LiveVisualComponent,
        AudioSamplerComponent,
        MidiConnectionComponent]
})
export class WidgetsModule {
}
