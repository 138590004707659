import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonButton, IonContent, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  AddActionTriggerAction,
  DeleteActionAction,
  DeleteActionTriggerAction,
  SetActionTriggerActiveAction,
  SetActionTriggersAction,
} from '../../../core/redux/actions/action-trigger-editor.actions';
import { getActionTriggers } from '../../../core/redux/reducers/action-trigger-editor-reducer';
import { ActionChooserComponent } from '../action-chooser/action-chooser.component';
import { ActionTrigger } from '../action-trigger';
import { ActionTriggerService } from '../action-trigger.service';

@Component({
  selector: 'app-action-trigger-editor',
  templateUrl: './action-trigger-editor.component.html',
  styleUrls: ['./action-trigger-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ActionTriggerEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  actionTriggers$: Observable<ActionTrigger[]> = this.store.select(getActionTriggers);
  actionTriggers: ActionTrigger[];
  private position: number;

  @ViewChild('content', { static: false }) content: IonContent;
  @ViewChild('addTriggerButton', { static: false }) addTriggerButton: IonButton;
  @ViewChild('addTriggerButton', { static: false, read: ElementRef }) addTriggerButton2: ElementRef;
  private sub: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalController: ModalController,
    private actionTriggerService: ActionTriggerService,
    private store: Store
  ) {
    this.position = parseInt(this.activatedRoute.snapshot.paramMap.get('position'), 0);

    this.sub = this.actionTriggers$.subscribe((value: ActionTrigger[]) => {
      this.actionTriggers = value;
      this.scrollToBottom();
    });
  }

  ngOnInit() {
    // @ts-ignore
    window.ultramixer = {
      actiontriggers: {
        addNewActionTrigger: this.addNewActionTrigger.bind(this),
        getActionTriggers: this.getActionTriggers.bind(this),
        setActionTriggers: this.setActionTriggers.bind(this),
      },
    };

    // todo: weg, nur zum Test
    /*
        this.actionTriggerService.getActionTriggers().subscribe((items: ActionTrigger[]) => {

            if (this.position) {
                const newActionTrigger = new ActionTrigger(this.position);
                items.push(newActionTrigger);
            }

            const str = JSON.stringify(items);

            this.store.dispatch(new SetActionTriggersAction({actionTriggersJSON: str}));
        });
        */
    /*
        if (this.position) {
            this.store.dispatch(new AddActionTriggerAction({position: this.position}));
        }
        */
  }

  handleSetTriggerActive(actionTriggerIndex: number, active: boolean) {
    console.log('active: ', active);
    this.store.dispatch(
      new SetActionTriggerActiveAction({
        actionTriggerIndex,
        active,
      })
    );
  }

  handleAddTrigger() {
    // @ts-ignore
    var timeStampStr: string = window.ultramixer_actiontriggers.getTimestamp();
    console.log('got timestamp: ' + timeStampStr);
    var timeStamp: number = 0;
    timeStamp = Number(timeStampStr); //parseInt(String(timeStampStr));
    console.log('timestamp as number: ' + timeStamp);
    this.store.dispatch(new AddActionTriggerAction({ position: timeStamp }));
  }

  /**
   *
   * @param position timestamp in ms
   */
  addNewActionTrigger(positionTimestamp: number) {
    // alert('addNewActionTrigger:' + positionTimestamp);
    this.store.dispatch(new AddActionTriggerAction({ position: positionTimestamp }));
  }

  handleAddAction(actionTriggerIndex: number) {
    this.showModal(actionTriggerIndex);
  }

  private async showModal(actionTriggerIndex: number) {
    const modal = await this.modalController.create({
      component: ActionChooserComponent,
      componentProps: { actionTriggerIndex },
    });
    return await modal.present();
  }

  handleDeleteAction(actionTriggerIndex: number, actionIndex: number) {
    this.store.dispatch(new DeleteActionAction({ actionTriggerIndex, actionIndex }));
  }

  handleRemoveActionTrigger(actionTriggerIndex: number) {
    this.store.dispatch(new DeleteActionTriggerAction({ actionTriggerIndex }));
  }

  private scrollToBottom() {
    if (this.content) {
      const offsetTop = this.addTriggerButton2.nativeElement.offsetTop;
      this.content.scrollToPoint(0, offsetTop, 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getActionTriggers(): string {
    return JSON.stringify(this.actionTriggers);
  }

  setActionTriggers(json: string) {
    this.store.dispatch(new SetActionTriggersAction({ actionTriggersJSON: json }));
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    if (window.ultramixer_actiontriggers) {
      console.log('Javba bridge found!: ');
    } else {
      console.log('Java bridge not found: ');
      return;
    }
    // @ts-ignore
    //  console.log('window.ultramixer_actiontriggers: ', window.ultramixer_actiontriggers);

    // @ts-ignore
    // window.ultramixer_actiontriggers.setWebAppIsLoaded();

    // @ts-ignore
    window.ultramixer_actiontrigers && window.ultramixer_actiontriggers.setWebAppIsLoaded();
  }
}
