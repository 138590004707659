<!--<level class="level1" style="max-width: 30px;max-height: 200px;"></level>//-->
<!--<level-svg class="level1"></level-svg>//-->
<!--<level-canvas class="level1" style="width: 128px;height: 128px;"></level-canvas>//-->

<!--<turntable-web-gl></turntable-web-gl>//-->
<!--<turntable2 [playing]="true"></turntable2>//-->


<h1>This is web in JavaSwing!</h1>
<div class="time">{{time$ | async}}</div>
<button (click)="handleTest()">Test</button>