import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { crossfaderAction } from './../../../core/redux/actions/mixer.actions';

@Component({
  selector: 'um-crossfader',
  templateUrl: './crossfader.component.html',
  styleUrls: ['./crossfader.component.scss'],
})
export class CrossfaderComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {}

  handleVolumeChanged(value: number) {
    this.store.dispatch(crossfaderAction({ value }));
  }
}
