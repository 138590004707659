import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SupportComponent} from './support/support.component';
import {RouterModule, Routes} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";

const routes: Routes = [
    {
        path: '',
        component: SupportComponent
    },
    {
        path: 'support',
        component: SupportComponent
    }
];

@NgModule({
    declarations: [SupportComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes)
    ]/*,
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]*/
})
export class AdditionalModule {
}
