import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { GroupsBrowserMobileComponent } from '../groups-browser-mobile/groups-browser-mobile.component';
import { IonNav } from '@ionic/angular';

@Component({
  selector: 'music-archive-mobile',
  templateUrl: './music-archive-mobile.component.html',
  styleUrls: ['./music-archive-mobile.component.scss']
})
export class MusicArchiveMobileComponent implements OnInit, AfterViewInit {
  rootPage = GroupsBrowserMobileComponent;

  @ViewChild('nav', { static: false }) nav: IonNav;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    console.log('nav', this.nav);
    
  }
}
