import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Track } from '../../../music-archive/track';
import { Store } from '@ngrx/store';
import { getPitch, getRemainingTime, getTime, getTrack, isLoading } from '../../../core/redux/reducers/player.reducer';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayComponent implements OnInit {
  @Input() playerID: number;

  time$: Observable<number>;
  remainingTime$: Observable<number>;
  pitch$: Observable<number>;
  track$: Observable<Track>;
  track: Track;
  isLoading$: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.pitch$ = this.store.select(getPitch(this.playerID));
    this.time$ = this.store.select(getTime(this.playerID));
    this.remainingTime$ = this.store.select(getRemainingTime(this.playerID));
    this.track$ = this.store.select(getTrack(this.playerID)).pipe(map((track: Track) => (this.track = track)));
    this.isLoading$ = this.store.select(isLoading(this.playerID));
  }
}
