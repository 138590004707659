/**
 * CURRENTLY UNUSED
 * wraps a byteArray that holds waveform-info for one segments
 * this class instance usually is only cached and used by WaveformSegment
 */
export class WaveformSegmentBuffer{
    byteArray:Int8Array = new Int8Array();

    public getBuffer():Int8Array
    {
        return this.byteArray;
    }
}