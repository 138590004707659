<div style="display:flex; flex-direction: column;">
  <h1>Waveform implementation test</h1>
  <div>
    <h2>BarSearch Waveform</h2>
    <bs-waveform [cacheContent]="cacheData"></bs-waveform>
  </div>

  <div style="margin: 0 0 20px 0px;">
    <h2>DualWaveform v1</h2>
    <dual-waveform-v1></dual-waveform-v1>
  </div>

  <div>
    <h2>DualWaveform v2</h2>
    <dual-waveform-v2></dual-waveform-v2>
  </div>
</div>
