import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { toggleTrackBarAction } from '../../../../core/redux/actions/beatmaker.actions';
import { BeatMakerTrack } from '../../beat-maker-track';

@Component({
  selector: 'beat-maker-bar',
  templateUrl: './beat-maker-bar.component.html',
  styleUrls: ['./beat-maker-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BeatMakerBarComponent implements OnInit {
  @Input() track: BeatMakerTrack;
  @Input() barIndex: number;
  @Input() currentlyPlaying = false;

  constructor(public el: ElementRef, private store: Store) {}

  ngOnInit() {}

  handleToggle() {
    this.store.dispatch(toggleTrackBarAction({ track: this.track, barIndex: this.barIndex }));
  }

  // todo: optimieren! z.b. mit pipe oder behavior subkect und onpush
  isActive() {
    if (!this.track) return false;
    return this.track.bars[this.barIndex] === 1;
  }
}
