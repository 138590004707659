import { EffectsModule } from '@ngrx/effects';
import { StoreConfig, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ActionTriggerEditorEffects } from '../core/redux/effects/action-trigger-editor.effects';
import { ArchiveEffects } from '../core/redux/effects/archive.effects';
import { BeatmakerEffects } from '../core/redux/effects/beatmaker.effects';
import { GeneralEffects } from '../core/redux/effects/general.effects';
import { MidiEffects } from '../core/redux/effects/midi.effects';
import { MixerEffects } from '../core/redux/effects/mixer.effects';
import { PlayerEffects } from '../core/redux/effects/player.effects';
import { actionTriggerEditorReducer } from '../core/redux/reducers/action-trigger-editor-reducer';
import { archiveReducer } from '../core/redux/reducers/archive.reducer';
import { beatMakerReducer } from '../core/redux/reducers/beatmaker.reducer';
import { generalReducer } from '../core/redux/reducers/general.reducer';
import { midiReducer } from '../core/redux/reducers/midi.reducer';
import { mixerReducer } from '../core/redux/reducers/mixer.reducer';
import { PlayerState, playerReducer, playerReducerInitialState } from '../core/redux/reducers/player.reducer';

export const extModules = [
  StoreDevtoolsModule.instrument({ maxAge: 250, serialize: true , connectInZone: true}),
  EffectsModule.forRoot([
    GeneralEffects,
    PlayerEffects,
    ArchiveEffects,
    BeatmakerEffects,
    MixerEffects,
    ActionTriggerEditorEffects,
    MidiEffects,
  ]),
  /**
   * StoreModule.forRoot is imported once in the root module, accepting a reducer
   * function or object map of reducer functions. If passed an object of
   * reducers, combineReducers will be run creating your application
   * meta-reducer. This returns all providers for an @ngrx/store
   * based application.
   */
  StoreModule.forRoot(
    {
      general: generalReducer,
      mixer: mixerReducer,
      archive: archiveReducer,
      beatMaker: beatMakerReducer,
      actionTriggerEditor: actionTriggerEditorReducer,
      midi: midiReducer,
    },
    {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }
  ),

  StoreModule.forFeature('player1', playerReducer, {
    initialState: { ...playerReducerInitialState, playerID: 0 },
  } as StoreConfig<PlayerState>),

  StoreModule.forFeature('player2', playerReducer, {
    initialState: { ...playerReducerInitialState, playerID: 1 },
  } as StoreConfig<PlayerState>),
];
