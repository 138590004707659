import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tracks } from '../../core/redux/reducers/archive.reducer';
import { Track } from '../track';
import { loadIntoPlayerAction } from './../../core/redux/actions/player.actions';

@Component({
  selector: 'app-tracks-list',
  templateUrl: './tracks-list.component.html',
  styleUrls: ['./tracks-list.component.scss'],
})
export class TracksListComponent implements OnInit {
  tracks$: Observable<Track[]> = this.store.select(tracks);
  selectedTrack: Track;

  cols = [
    { field: 'actions', header: 'Load', width: '110px' },
    { field: 'cover', header: '', width: '60px' },
    { field: 'no', header: 'No', width: '46px' },
    { field: 'artist', header: 'Artist', width: '' },
    { field: 'title', header: 'Title', width: '' },
    { field: 'bpm', header: 'BPM', width: '100px' },
    { field: 'length', header: 'Length', width: '100px' },
    { field: 'rating', header: 'Rating', width: '100px' },
    { field: 'genre', header: 'Genre', width: '100px' },
  ];

  constructor(private store: Store) {}

  ngOnInit() {}

  loadTrackIntoPlayer(track: Track, playerID: number) {
    //this.store.dispatch(loadIntoPlayerAction({ playerID, track }));
  }
}
