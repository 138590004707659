import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getMidiMessages } from '../../../core/redux/reducers/midi.reducer';
import { MidiMessage } from '../../../models/MidiMessage';

@Component({
  selector: 'midi-Connection',
  templateUrl: './midi-connection.component.html',
  styleUrls: ['./midi-connection.component.scss'],
})
export class MidiConnectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('devices', { static: false }) devices: ElementRef;
  @ViewChild('messages', { static: false }) messages: ElementRef;

  private unsubscribe$ = new Subject<void>();

  constructor(private store: Store) {}

  ngAfterViewInit(): void {
    // this.store.connect().then(() => {
    //     this.devices.nativeElement.innerHTML += this.midiService.getDevices().map(device => `<option>${device.name}</option>`).join('');
    //
    //     // this.devices.innerHTML += this.midiService.getDevices().map(device => `<option>${device.name}</option>`).join('');
    //     // this.midiService.addListener(this.showMidiMessage);
    //
    //     console.log("this.messages", this.messages)
    // });

    this.store
      .select(getMidiMessages)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((message: MidiMessage) => {
        if (message) this.messages.nativeElement.innerHTML += message.toString();
        // console.log('MidiMessage: ', (messages[0] as MidiMessage).toString());
      });
  }

  ngOnInit(): void {}

  cleanup() {
    this.messages.nativeElement.innerHTML = '';
    this.devices.nativeElement.innerHTML = '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();

    this.unsubscribe$.complete();
  }

  handleClick1() {
    // this.cleanup();
    //
    // this.midiService.connect().then(() => {
    //     this.devices.nativeElement.innerHTML += this.midiService.getDevices().map(device => `<option>${device.name}</option>`).join('');
    //     this.midiService.addListener(this.showMidiMessage);
    //
    // });
  }

  handleClick2() {
    // var message = new MidiMessage();
    // message.set(7, 144,0, 127);
    // this.midiService.sendMidiMessage(message,this.midiService.midiOut[0]);
  }
}
