import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  enterFullScreenModeAction,
  loginAction,
  loginSucceededAction,
  quitFullScreenModeAction,
  setWidgetAction,
  showBeatMakerAction,
  showMixerAction,
} from '../actions/general.actions';
import { showMusicArchiveAction } from './../actions/general.actions';

export interface GeneralState {
  fullscreenModeOpen: boolean;
  widget1: string;
  loggingIn: boolean;
  beatMakerVisible: boolean;
  musicArchiveVisible: boolean;
  mixerVisible: boolean;
}

const initialState: GeneralState = {
  fullscreenModeOpen: false,
  widget1: 'video-mix', //'audio-sampler',
  loggingIn: false,
  beatMakerVisible: false,
  musicArchiveVisible: true,
  mixerVisible: false,
};

export const generalReducer = createReducer(
  initialState,
  on(
    quitFullScreenModeAction,
    (state, action): GeneralState => ({
      ...state,
      fullscreenModeOpen: false,
    })
  ),
  on(
    enterFullScreenModeAction,
    (state, action): GeneralState => ({
      ...state,
      fullscreenModeOpen: true,
    })
  ),
  on(
    setWidgetAction,
    (state, action): GeneralState => ({
      ...state,
      widget1: action.widgetID,
    })
  ),
  on(
    loginAction,
    (state, action): GeneralState => ({
      ...state,
      loggingIn: true,
    })
  ),
  on(
    loginSucceededAction,
    (state, action): GeneralState => ({
      ...state,
      loggingIn: false,
    })
  ),
  on(
    showMixerAction,
    (state, action): GeneralState => ({
      ...state,
      musicArchiveVisible: false,
      beatMakerVisible: false,
      mixerVisible: true,
    })
  ),
  on(
    showMixerAction,
    (state, action): GeneralState => ({
      ...state,
      musicArchiveVisible: false,
      beatMakerVisible: false,
      mixerVisible: true,
    })
  ),
  on(
    showMusicArchiveAction,
    (state, action): GeneralState => ({
      ...state,
      musicArchiveVisible: true,
      beatMakerVisible: false,
      mixerVisible: false,
    })
  ),
  on(
    showBeatMakerAction,
    (state, action): GeneralState => ({
      ...state,
      musicArchiveVisible: false,
      beatMakerVisible: true,
      mixerVisible: false,
    })
  )
);

// All getter of general state
export const generalState = createFeatureSelector<GeneralState>('general');
export const isFullscreenModeOpen = createSelector(generalState, (state: GeneralState) => state.fullscreenModeOpen);
export const getWidget1 = createSelector(generalState, (state: GeneralState) => state.widget1);
export const isLoggingIn = createSelector(generalState, (state: GeneralState) => state.loggingIn);
export const isBeatMakerVisible = createSelector(generalState, (state: GeneralState) => state.beatMakerVisible);
export const isMusicArchiveVisible = createSelector(generalState, (state: GeneralState) => state.musicArchiveVisible);
export const isMixerVisible = createSelector(generalState, (state: GeneralState) => state.mixerVisible);
