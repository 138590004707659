import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimePipe} from "./time.pipe";
import {MomentModule} from "ngx-moment";

@NgModule({
    declarations: [TimePipe],
    imports: [
        CommonModule,
        MomentModule
    ],
    exports: [TimePipe]
})
export class SharedUiModule {
}
