import {ActionWithPayload} from './action.interface';
import {ActionTrigger} from '../../../main/action-trigger/action-trigger';
import {ActionTriggerAction} from '../../../main/action-trigger/action-trigger-action';

export enum ActionTriggerEditorActions {

    SET_ACTION_TRIGGERS = '[ActionTriggerEditor] Set Action Triggers',
    ADD_ACTION_TRIGGER = '[ActionTriggerEditor] Add Action Trigger',
    // ADD_ACTION_TRIGGER_SUCCESFUL = '[ActionTriggerEditor] Add Action Trigger successful',
    ADD_ACTION = '[ActionTriggerEditor] Add Action',
    DELETE_ACTION = '[ActionTriggerEditor] Delete Action',
    DELETE_ACTION_TRIGGER = '[ActionTriggerEditor] Delete Action Trigger',
    SET_ACTION_TRIGGER_ACTIVE_ACTION = '[ActionTriggerEditor] Set selected Action Trigger',
    NOTIFY_ACTION_TRIGGERS_CHANGED_ACTION = '[ActionTriggerEditor] Notify action triggers changed'
}

export class SetActionTriggersAction implements ActionWithPayload {
    readonly type: string = ActionTriggerEditorActions.SET_ACTION_TRIGGERS;

    constructor(public payload: { actionTriggersJSON: string }) {
    }
}

export class AddActionTriggerAction implements ActionWithPayload {
    readonly type: string = ActionTriggerEditorActions.ADD_ACTION_TRIGGER;

    constructor(public payload: { position: number }) {
    }
}

export class AddActionTriggerSuccessfulAction implements ActionWithPayload {
    readonly type: string = ActionTriggerEditorActions.ADD_ACTION_TRIGGER;

    constructor(public payload: { actionTriggers: ActionTrigger[] }) {
    }
}


export class AddActionAction implements ActionWithPayload {
    readonly type = ActionTriggerEditorActions.ADD_ACTION;

    constructor(public payload: { actionTriggerIndex: number, action: ActionTriggerAction }) {
    }
}

export class DeleteActionAction implements ActionWithPayload {
    readonly type = ActionTriggerEditorActions.DELETE_ACTION;

    constructor(public payload: { actionTriggerIndex: number, actionIndex: number }) {
    }
}

export class DeleteActionTriggerAction implements ActionWithPayload {
    readonly type = ActionTriggerEditorActions.DELETE_ACTION_TRIGGER;

    constructor(public payload: { actionTriggerIndex: number }) {
    }
}


export class SetActionTriggerActiveAction implements ActionWithPayload {
    readonly type = ActionTriggerEditorActions.SET_ACTION_TRIGGER_ACTIVE_ACTION;

    constructor(public payload: { actionTriggerIndex: number, active: boolean }) {
    }
}

export class NotifyActionTriggersChangedAction implements ActionWithPayload {
    readonly type = ActionTriggerEditorActions.NOTIFY_ACTION_TRIGGERS_CHANGED_ACTION;

    constructor(public payload: { actionTriggersJSON: string }) {
    }
}






