import { createAction, props } from '@ngrx/store';
import { WaveformCache } from '../../../main/waveform/WaveformCache';
import { Track } from '../../../music-archive/track';
import { PitchMode } from '../../../models/pitch-mode';

const ACTION_TYPE = '[Player]';

export const loadIntoPlayerAction = createAction(`${ACTION_TYPE} Load into Player`, props<{ playerID: number; track: Track }>());

export const loadIntoPlayerSuccessfullAction = createAction(
  `${ACTION_TYPE} Load into Player successful`,
  props<{ playerID: number; track: Track; waveformData: WaveformCache }>()
);

export const loadIntoPlayerFailedAction = createAction(
  `${ACTION_TYPE} Load into Player failed`,
  props<{ playerID: number; track: Track; error: any }>()
);

export const setTimeAction = createAction(`${ACTION_TYPE} Set Time`, props<{ playerID: number; time: number }>());
export const setTimeUM6Action = createAction(
  `${ACTION_TYPE} Set Time UM6`,
  props<{ playerID: number; time: number; remainingTime: number }>()
);
export const setTrackTitleUM6Action = createAction(
  `${ACTION_TYPE} Set Track title UM6`,
  props<{ playerID: number; artist: string; title: string }>()
);

export const playAvgTimeAction = createAction(`${ACTION_TYPE} PlayAvgTime`, props<{ playerID: number; received: number }>());
export const playAction = createAction(`${ACTION_TYPE} Play`, props<{ playerID: number }>());
export const cueAction = createAction(`${ACTION_TYPE} Cue`, props<{ playerID: number }>());
export const loopBeatAction = createAction(`${ACTION_TYPE} Loop Beat`, props<{ playerID: number; beat: number }>());
export const increaseLoopBeatAction = createAction(`${ACTION_TYPE} Increase Loop Beat`, props<{ playerID: number }>());
export const decreaseLoopBeatAction = createAction(`${ACTION_TYPE} Decrease Loop Beat`, props<{ playerID: number }>());
export const loopInAction = createAction(`${ACTION_TYPE} Loop In`, props<{ playerID: number; time: number }>());
export const loopOutAction = createAction(`${ACTION_TYPE} Loop Out`, props<{ playerID: number; time: number }>());
export const loopAction = createAction(`${ACTION_TYPE} Loop`, props<{ playerID: number; loop: boolean }>());
export const setCuePointAction = createAction(`${ACTION_TYPE} Set Cue Point`, props<{ playerID: number; cuepointIndex: number }>());
export const saveCuePointAction = createAction(`${ACTION_TYPE} Save Cue Point`, props<{ playerID: number; cuepointIndex: number }>());
export const setPitchAction = createAction(`${ACTION_TYPE} Set Pitch`, props<{ playerID: number; value: number }>());
export const setVirtualVelocityAction = createAction(
  `${ACTION_TYPE} Set Virtual Velocity`,
  props<{ playerID: number; positionMs: number; timems: number }>()
);
export const setVirtualVelocityAvgTimeAction = createAction(
  `${ACTION_TYPE} Set Virtual Velocity AvgTime`,
  props<{ playerID: number; positionMs: number; timems: number; received: number }>()
);
export const setVolumeAction = createAction(`${ACTION_TYPE} Set volume`, props<{ playerID: number; volume: number }>());
export const togglePitchModeAction = createAction(`${ACTION_TYPE} Toggle pitch mode`, props<{ playerID: number }>());
export const setPitchModeAction = createAction(`${ACTION_TYPE} Set pitch mode`, props<{ playerID: number; pitchMode: PitchMode }>());
export const togglePlayedToSyncToAction = createAction(`${ACTION_TYPE} Toggle player to sync to sync`, props<{ playerID: number }>());
