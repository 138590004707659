import { BeatMakerTrack } from './beat-maker-track';

export class BeatMakerPattern {
  name: string;
  bpm: number;
  noBars: number;
  tracks: BeatMakerTrack[];

  static defaultPattern(): BeatMakerPattern {
    return {
      name: 'Dance 1',
      bpm: 120,
      noBars: 8,
      tracks: [
        {
          id: 'basedrum',
          note: 'C3',
          name: 'Base',
          bars: [1, 0, 1, 0, 1, 0, 1, 0],
        },
        { id: 'snare', note: 'D3', name: 'Snare', bars: [0, 0, 1, 0, 0, 0, 1, 0] },
        { id: 'hihat', note: 'E3', name: 'Hihat', bars: [1, 1, 1, 1, 1, 1, 1, 1] },
        {
          id: 'openhihat',
          note: 'F3',
          name: 'Open HH',
          bars: [0, 1, 0, 1, 0, 1, 0, 1],
        },
      ],
    };
  }
}
