import { BeatMakerPattern } from '../main/beatmaker/beat-maker-pattern';
import { BeatMakerTrack } from '../main/beatmaker/beat-maker-track';

export function toggleBar(pattern: BeatMakerPattern, track: BeatMakerTrack, barIndex: number): BeatMakerPattern {
  console.log('>>> pattern', pattern);
  console.log('>>> toggleBar', track, barIndex);
  const trackIndex = pattern.tracks.indexOf(track);

  const newPattern = { ...pattern };
  newPattern.tracks = [...pattern.tracks];

  const _track = { ...newPattern.tracks[trackIndex] };
  const bars = [..._track.bars];

  bars[barIndex] = bars[barIndex] === 1 ? 0 : 1;

  _track.bars = bars;

  newPattern.tracks[trackIndex] = _track;

  console.log('>>> newPattern', newPattern);

  return newPattern;
}
