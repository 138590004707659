import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BeatMakerPattern } from '../../../main/beatmaker/beat-maker-pattern';
import { toggleBar } from '../../../services/audio.helpers';
import { AudioService } from '../../../services/audio.service';
import {
  enableEchoAction,
  initializingBeatMakerAction,
  initializingBeatMakerSucceededAction,
  playBeatAction,
  seekAction,
  setSpeedAction,
  stopBeatAction,
  toggleEchoAction,
  toggleTrackBarAction,
} from '../actions/beatmaker.actions';
import { getPattern, isEchoEnabled, BeatMakerState } from '../reducers/beatmaker.reducer';
import { loadPatternAction, loadPatternSucceededAction } from './../actions/beatmaker.actions';

@Injectable()
export class BeatmakerEffects {
  constructor(private actions$: Actions, private store: Store<BeatMakerState>, private audioService: AudioService) {}

  initializingBeatMaker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializingBeatMakerAction),
      switchMap((action) => {
        return this.audioService.initializingBeatmaker().pipe(
          map((ret: boolean) => {
            console.log('ret: ', ret);
            const pattern = this.audioService.loadBeatMakerBank(BeatMakerPattern.defaultPattern());
            return initializingBeatMakerSucceededAction({ pattern });
          })
        );
      })
    )
  );

  loadBeatPattern$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPatternAction),
      tap((action) => this.audioService.loadBeatMakerBank(action.pattern)),
      map((action) => loadPatternSucceededAction({ pattern: action.pattern }))
    )
  );

  playBeat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(playBeatAction),
        tap((action) => this.audioService.playDrumPattern())
      ),
    {
      dispatch: false,
    }
  );

  stopBeat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stopBeatAction),
        tap(() => this.audioService.stopDrumPattern())
      ),
    { dispatch: false }
  );

  toggleTrackBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleTrackBarAction),
        withLatestFrom(this.store.select(getPattern)),
        tap(([action, pattern]) => {
          console.log('>>> pattern', pattern);
        }),
        map(([action, pattern]) => toggleBar(pattern, action.track, action.barIndex)),
        map((pattern: BeatMakerPattern) => loadPatternAction({ pattern: pattern }))
      ),
    { dispatch: true }
  );

  setSpeed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSpeedAction),
        tap((action) => {
          this.audioService.setSpeed(action.bpm);
        })
      ),
    { dispatch: false }
  );

  seek$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(seekAction),
        tap((action) => {
          this.audioService.seek(action.barIndex);
        })
      ),
    { dispatch: false }
  );

  enableEcho$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(enableEchoAction),
        tap((action) => {
          this.audioService.enableEcho(true);
        })
      ),
    { dispatch: false }
  );

  toggleEcho$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleEchoAction),
        withLatestFrom(this.store.select(isEchoEnabled)),
        tap(([action, isEchoEnabled]) => {
          this.audioService.enableEcho(isEchoEnabled);
        })
      ),
    { dispatch: false }
  );
}
