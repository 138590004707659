import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeviceChooserComponent} from './device-chooser/device-chooser.component';
import {RouterModule, Routes} from '@angular/router';
import {IonicModule} from '@ionic/angular';

const routes: Routes = [
    {
        path: 'devices',
        component: DeviceChooserComponent
        // loadChildren: './mixer/mixer.module#MixerModule'
    }
];


@NgModule({
    declarations: [DeviceChooserComponent],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild(routes)
    ]
})
export class HidModule {
}
