import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PerformanceTestComponent} from './performance-test/performance-test.component';
import {MixerModule} from "../main/mixer/mixer.module";
import { TurntableWebGLComponent } from './performance-test/turntable-web-gl/turntable-web-gl.component';
import {SoundPlayerModule} from "../main/sound-player/sound-player.module";

@NgModule({
    declarations: [PerformanceTestComponent, TurntableWebGLComponent],
    imports: [
        CommonModule,
        MixerModule,
        SoundPlayerModule
    ],
    exports: [PerformanceTestComponent]
})
export class UitestsModule {
}
