<ion-header>
    <ion-toolbar>
        <ion-title>HID Devices</ion-title>
        <ion-buttons slot="end">
            <ion-button>Test</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-button (click)="fetch()">fetch data</ion-button>

<ion-content>

    <ion-list>
        <ion-item *ngFor="let device of listDevices">
            <ion-label>{{device.getPath()}}</ion-label>
            <ion-button fill="outline" slot="end" (click)="handleConnect(device);"> Connect</ion-button>
        </ion-item>
    </ion-list>

</ion-content>
