import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  killEqHighAction,
  killEqLowAction,
  killEqMidAction,
  setEqHighAction,
  setEqLowAction,
  setEqMidAction,
  setVolumeAction,
} from '../../core/redux/actions/mixer.actions';
import { getVolume, levels1, levels2 } from '../../core/redux/reducers/mixer.reducer';
import { isPlaying } from '../../core/redux/reducers/player.reducer';

@Component({
  selector: 'mixer',
  templateUrl: './mixer.component.html',
  styleUrls: ['./mixer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MixerComponent implements OnInit {
  levels1$: Observable<number[]> = this.store.select(levels1);
  levels2$: Observable<number[]> = this.store.select(levels2);
  volume1$: Observable<number> = this.store.select(getVolume(0));
  volume2$: Observable<number> = this.store.select(getVolume(1));

  isPlaying1$: Observable<boolean> = this.store.select(isPlaying(0));
  isPlaying2$: Observable<boolean> = this.store.select(isPlaying(1));

  constructor(private store: Store) {}

  ngOnInit() {}

  handleVolume(playerID: number, volume: number) {
    this.store.dispatch(setVolumeAction({ playerID, volume }));
  }

  handleEQHigh(playerID: number, value: number) {
    this.store.dispatch(setEqHighAction({ playerID, value }));
  }

  handleEQMid(playerID: number, value: number) {
    this.store.dispatch(setEqMidAction({ playerID, value }));
  }

  handleEQLow(playerID: number, value: number) {
    this.store.dispatch(setEqLowAction({ playerID, value }));
  }

  handleKillEqHigh(playerID: number, kill: boolean) {
    this.store.dispatch(killEqHighAction({ playerID, kill }));
  }

  handleKillEqMid(playerID: number, kill: boolean) {
    this.store.dispatch(killEqMidAction({ playerID, kill }));
  }

  handleKillEqLow(playerID: number, kill: boolean) {
    this.store.dispatch(killEqLowAction({ playerID, kill }));
  }
}
