import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { WaveformCache } from '../WaveformCache';

@Component({
  selector: 'waveform-segment',
  templateUrl: './waveform-segment.component.html',
  styleUrls: ['./waveform-segment.component.scss']
})
export class WaveformSegmentComponent implements OnInit, AfterViewInit {
  @Input('segmentPositionCanvasPx')
  set segmentPositionCanvasPx(newPosXCanvasPx) {
    this._segmentPositionCanvasPx = newPosXCanvasPx;
    /*console.log("set segmentPositionCanvasPx: %s", newPosXCanvasPx);
    console.log(
      "segmentPositionCanvasPxTranlate: ",
      this.segmentPositionCanvasPxTranlate
    );*/
  }

  get segmentPositionCanvasPx() {
    return this._segmentPositionCanvasPx;
  }

  get segmentPositionCanvasPxTranlate() {
    const rounded = Math.round(this._segmentPositionCanvasPx);
    const translateString = `translateX(${rounded}px)`;
    return translateString;
  }

  private get segmentCanvas() {
    return this.internalCanvasER.nativeElement;
  }

  // repaint on cacheChange/msPxRatio/xPositionMs
  // todo: repaint on width/Height change except initially
  public constructor() {
    // this.msPxRatioProvider = msPxRatioProvider;
    WaveformSegmentComponent.segmentCount++;
    this.segmentIndex = WaveformSegmentComponent.segmentCount;
    console.log('called constructor of segment %s', this.segmentIndex);

    // this is now created by the component
    /*this.internalCanvas = document.createElement("canvas");
    this.internalCanvas.width = width;
    this.internalCanvas.height = height;
    this.internalCtx = this.internalCanvas.getContext("2d");*/
  }

  private get msPxRatio(): number {
    return this.msPxRatioProvider.msPxRatio;
  }

  @Input('xPositionMs')
  public set xPositionPx(xPos: number) {
    this._xPositionMs = xPos;
    if (this.initPassed) {
      this.repaint(this.waveformCache);
    }
  }

  public get xPositionPx(): number {
    return this._xPositionMs;
  }
  static segmentCount = -1;

  @Input('width') width: number;
  @Input('height') height: number;

  // x position in ms in regards to the song -> requires different calculations to get to pixel-position in cache/in waveform
  // in cache this is simply xPositionMs / msPxRatio
  private _xPositionMs = 0;
  // @Input('msPxRatioProvider')
  // @ViewChild(DualWaveformComponent) msPxRatioProvider:DualWaveformComponent;
  // msPxRatioProvider.msPxRatio

  // hardcoded value specific to testWaveformCache/data1.json waveformCache
  // of the song C:\\Program Files\\UltraMixer6\\The_Admirals_featuring_Seraphina_Bass!Man2010_UltraMixerEdit.mp4
  // later this is used as a zoom factor and as input for PlayerFmod4.calcWaveform()
  msPxRatioProvider: { msPxRatio: number } = { msPxRatio: 14.61 };

  // cached value set by dual-waveform-component
  private _segmentPositionCanvasPx: number;

  @ViewChild('internalCanvasER', { static: false }) internalCanvasER: ElementRef;

  @Input('waveformCache')
  waveformCache: WaveformCache;

  // for debugging only
  segmentIndex = 0;

  // assinged in ngOnInit()
  // internalCtx: CanvasRenderingContext2D;

  // inialised on first getContext2D() call and cached
  private ctx2d: CanvasRenderingContext2D = null;

  private initPassed = false;
  ngOnInit() {
    console.log('called ngOnInit() of segment %s', this.segmentIndex);
  }

  ngAfterViewInit() {
    this.initPassed = true;
    this.repaint(this.waveformCache);
  }

  public getContext2D(): CanvasRenderingContext2D {
    if (this.ctx2d == null) {
      console.log('init ctx');
      this.ctx2d = this.segmentCanvas.getContext('2d');
    }
    return this.ctx2d;
  }

  public getXPositionMs() {
    return this._xPositionMs;
  }

  drawSegmentBackground() {
    /*this.getContext2D().fillStyle = "#ff0000";
    this.getContext2D().fillRect(0, 0, 512, 100);
    this.getContext2D().fill();*/

    this.getContext2D().clearRect(0, 0, this.segmentCanvas.width, this.segmentCanvas.height);
  }

  private repaint(data: WaveformCache) {
    console.log('entered repaint segment');
    // console.log("this waveformsegment: ", this);


    // data = WaveformSegmentComponent.staticCache; //old debug code to fix some bug?

    // console.log("data:WaveformCache: ", data);
    if (!data) {
      console.error('data for segment %s is ', this.segmentIndex, data);
    }
    const byteArray: Int8Array = data.getByteArrayFromCacheObj();

    console.log(length);
    // copied value from WaveformSegmentBuffer.BYTES_PER_PIXEL in waveformcontrol6-fx
    const BYTES_PER_PIXEL = data.BYTES_PER_PIXEL;
    const lineWidth = 1;
    const pixelCount = data.pixelCount;

    this.drawSegmentBackground();

    // todo_impl: // Zwei duenne Linien fuer die echten Null-Positionen zeichnen, unabhaengig von der Silence.
    // drawTrackLimits()

    // console.log('msPx-Ratio:%s', this.msPxRatio);

    // start px in global
    const xStartPx = Math.round(this.getXPositionMs() / this.msPxRatio);
    const xEndPx = Math.round(xStartPx + this.segmentCanvas.width);

    console.log('painting segment %s: xStartPx: %s until xEndPx %s in cache', this.segmentIndex, xStartPx, xEndPx);

    if (!(xEndPx < 0)) {
      console.log('parts of segment %s are in positive cache position-> something to draw', this.segmentIndex);
      // clear waveform first (drawSegmentBackground()), then only paint over where there is data
      // x - pixel position to paint
      // i - position in byteArray to get data from
      for (let x = xStartPx, i = xStartPx * BYTES_PER_PIXEL; i + 7 < byteArray.length && x < xEndPx; x++, i += BYTES_PER_PIXEL) {
        if (x < 0) {
          continue;
        }

        const minValue = byteArray[i] * (1 / Math.sqrt(2)) - 0.5;
        const maxValue = byteArray[i + 1] * (1 / Math.sqrt(2)) + 0.5;

        const middle = this.segmentCanvas.height / 2;
        const scale = this.segmentCanvas.height / 255.0;
        // Durch die Rundung werden die sehr kleinen Werte um 1 Pixel besser sichtbar.
        // Bei den negativen Werten erzeugt nur -0.5 symetrische Bilder, auch round() ist falsch.
        const maxY1 = middle - (maxValue * scale + 0.5); // top of line, 0.5  is like ceil
        // let minY2 = middle - (minValue * scale - 0.5); // bot of line, -0.5 is like floor
        // var lineH = maxY1 - minY2 + 1;//why +1?

        // todo
        /*let lowPassAvg = Math.max(0, Math.min(255, byteArray[i + 4] * 2));
          let midPassAvg = Math.max(0, Math.min(255, byteArray[i + 5] * 2));
          let highPassAvg = Math.max(0, Math.min(255, byteArray[i + 6] * 2));
          let r = Math.max(0.0, Math.min(255, lowPassAvg));
          let g = Math.max(0.0, Math.min(255, midPassAvg));
          let b = Math.max(0.0, Math.min(255, highPassAvg));
          let minMaxColor = this.rgb256ToHexCode(r, g, b, 127);*/

        // maxY1 = middle - Math.abs(maxValue*scale);
        const lineH = Math.abs(minValue * scale) + Math.abs(maxValue * scale) + 1; // why +1

        // this.getContext2D().beginPath();
        this.getContext2D().rect(x - xStartPx, maxY1, lineWidth, lineH);
        this.getContext2D().fillStyle = 'red'; // "#" + "red";
        this.getContext2D().fill();
      }
    }

    if (environment.debugDualWaveform) {
      this.getContext2D().strokeStyle = '#00ffff55';
      this.getContext2D().lineWidth = 1;
      this.getContext2D().strokeRect(0, 0, this.segmentCanvas.width - 1, this.segmentCanvas.height);
      // this.getContext2D().stroke();

      this.getContext2D().fillStyle = 'red';
      this.getContext2D().font = 'italic 10pt Calibri';
      const text = 'segment ' + this.segmentIndex;
      this.getContext2D().fillText(text, 5, this.segmentCanvas.height - 10);
    }
  }
}
