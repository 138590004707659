import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AudioContext } from 'angular-audio-context';
import 'capacitor-um-core';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as Tone from 'tone';
import { setCurrentPlayingBarAction } from '../core/redux/actions/beatmaker.actions';
// import * as umCore from 'umcore-napi/public/UMCoreNAPI.js';
// import {UmCoreWeb} from 'umcore-plugin';
// import { UmCoreWeb } from '../../../../../../umcore-plugin';
import { getPattern } from '../core/redux/reducers/beatmaker.reducer';
import { BeatMakerPattern } from '../main/beatmaker/beat-maker-pattern';
import { BeatMakerTrack } from '../main/beatmaker/beat-maker-track';
import { WaveformCache } from '../main/waveform/WaveformCache';
import { Track } from '../music-archive/track';

const { UMCore } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class AudioService implements OnDestroy {
  // TimeMessurements
  private sumTime = 0;
  private valueCounter = 0;

  private currentEqHighValue = 0;
  private currentEqMidValue = 0;
  private currentEqLowValue = 0;

  private nativeAudioEngine = false;

  filePath: string;

  buffer: AudioBuffer[];
  bufferSource: any[];
  private sampler: Tone.Sampler;
  private audioSampler: Tone.Sampler;
  private sequence: Tone.Sequence<any>;
  private reverb: Tone.Reverb;
  private currentPattern: BeatMakerPattern;
  // private umCore: UmCoreWeb;

  private patternSubscription: Subscription;

  private gainNode: any;
  private players: Array<{ player: Tone.Player; volume: Tone.Volume; eq: Tone.EQ3 }> = new Array(2).fill(null);
  private mainSoundCard = 0;
  private monitorSoundCard = 0;
  // transport: Tone.Transport;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private audioContext: AudioContext,
    private store: Store,
    private zone: NgZone
  ) {
    console.log('>>> AudioService: ');
    this.buffer = new Array(2).fill(null);
    this.bufferSource = new Array(2).fill(null);
    this.gainNode = this.audioContext.createGain();

    console.log('platform electron', this.platform.is('electron'));

    this.nativeAudioEngine = this.platform.is('electron');
    console.log('>>> this.nativeAudioEngine', this.nativeAudioEngine);

    Plugins.UMCore.setBufferSizesMS(10000, 2);
    Plugins.UMCore.setScratchQuality(1);

    Plugins.UMCore.initPlayer(0, 1, 44100);
    Plugins.UMCore.initPlayer(1, 1, 44100);

    // Tone.Transport.bpm = 120;
    // this.transport = Tone.Transport;

    this.patternSubscription = this.store.select(getPattern).subscribe((pattern) => (this.currentPattern = pattern));

    if (!this.nativeAudioEngine) {
      this.initWebAudio();
    }
  }
  initWebAudio() {}

  setFilePath(filePath: string): void {
    this.filePath = filePath;
  }

  ngOnDestroy(): void {
    this.patternSubscription.unsubscribe();
  }

  // todo: change Play function
  play(playerID: number) {
    if (this.nativeAudioEngine) {
      this.play_native(playerID);
    } else {
      this.play_web(playerID);
    }
  }
  play_native(playerID: number) {
    const ret = UMCore.play(playerID);
    //   const ret = this.umCore.play(playerID);
    console.log(`play: playerID:${playerID} result: ${ret}`);
  }

  playAvgTime_webaudio(playerID: number, received: number) {
    /*this.bufferSource[playerID] = this.audioContext.createBufferSource();
        this.bufferSource[playerID].onended = function () {
            // this.onSampleEnded(slotNo);
        }.bind(this);
        this.bufferSource[playerID].buffer = this.buffer[playerID];
        this.bufferSource[playerID].connect(this.audioContext.destination);
        this.bufferSource[playerID].start(0);*/
    // this.transport.start();
  }
  playAvgTime(playerID: number, received: number) {
    /*
    this.sumTime += window.performance.now() - received;
    this.valueCounter += 1;
    console.log(
      'AvgTime: ',
      this.sumTime / this.valueCounter,
      ' counter: ',
      this.valueCounter,
      ' this time: ',
      window.performance.now() - received
    );

    if (this.isPlaying(playerID)) {
      this.umCore.pause(playerID);
    } else {
      this.umCore.play(playerID);
    }

    this.players[playerID].player.start();
    */
  }

  loadTestFile(): void {
    // return this.umCore.loadTestFile();
  }

  isPlaying(playerID: number): boolean {
    // return UMCore.isPlaying(playerID);
    return true;
  }

  unload(playerID: number): number {
    // return this.umCore.unload(playerID);
    return 1;
  }

  getLength(playerID: number): number {
    return UMCore.getLength(playerID);
  }

  getPosition(playerID: number): number {
    // return this.umCore.getPosition(playerID);
    return 0;
  }

  setPosition(playerID: number, timeinmillis: number): number {
    // return this.umCore.setPosition(playerID, timeinmillis);
    return 1;
  }

  setIsReverse(playerID: number, reverse: boolean): number {
    // return this.umCore.setIsReverse(playerID, reverse);
    return 1;
  }

  getVolume(playerID: number): number {
    // return this.umCore.getVolume(playerID);
    return 1;
  }

  setVolume(playerID: number, volume: number) {
    console.log(`[audio-service] setVolume: playerID: ${playerID} - volume: ${volume}`);
    if (this.nativeAudioEngine) {
      this.setVolume_native(playerID, volume);
    } else {
      this.setVolume_web(playerID, volume);
    }
  }

  /**
   *
   * @param playerID
   * @param pitch -100 - 100
   */
  setPitch(playerID: number, pitch: number) {
    // console.log(`[audio-service] setPitch: playerID: ${playerID} - volume: ${pitch}`);
    if (this.nativeAudioEngine) {
      this.setPitch_native(playerID, pitch);
    } else {
      this.setPitch_web(playerID, pitch);
    }
  }

  // TODO:
  setPitch_native(playerID: number, volume: number) {
    //  UMCore.setVolume(playerID, volume);
  }

  /**
   *
   * @param playerID ID of player
   * @param pitch -2 to 2
   */
  setPitch_web(playerID: number, pitch: number) {
    let v = (pitch + 1) * 1; // pitch*100;

    const player = this.players[playerID];
    if (player) {
      player.player.playbackRate = v;
    }
  }

  /**
   *
   * @param playerID ID of player
   * @param volume 0-1
   */
  setVolume_web(playerID: number, volume: number) {
    // this.bufferSource[playerID].volume = volume;
    // TODO: use Arrays

    // this.gainNode.gain.value = volume / 10000;
    const v = volume / 3 / 100 - 100 / 3;
    const player = this.players[playerID];
    if (player) {
      player.player.volume.value = v;
    }
  }

  setVolume_native(playerID: number, volume: number) {
    UMCore.setVolume(playerID, volume);
  }

  getVolumeMonitor(playerID: number): number {
    return UMCore.getVolumeMonitor(playerID);
  }

  setVolumeMonitor(playerID: number, volume: number): number {
    return UMCore.setVolumeMonitor(playerID, volume);
  }

  setMute(playerID: number, enable: boolean): number {
    return UMCore.setMute(playerID, enable);
  }

  setMuteMonitor(playerID: number, enable: boolean): number {
    return UMCore.setMuteMonitor(playerID, enable);
  }

  setGain(playerID: number, gain: number): number {
    return UMCore.setGain(playerID, gain);
  }

  setPan(playerID: number, pan: number): number {
    return UMCore.setPan(playerID, pan);
  }

  setLoop(playerID: number, loop: boolean, setposition: boolean): number {
    return UMCore.setLoop(playerID, loop, setposition);
  }

  setLoopPoints(playerID: number, start: number, end: number): number {
    return UMCore.setLoopPoints(playerID, start, end);
  }

  pause(playerID: number): number {
    return UMCore.pause(playerID);
  }

  // todo change stop
  stop(playerID: number) {
    //// this.umCore.pause(0);
    if (this.nativeAudioEngine) {
      this.stop_native(playerID);
    } else {
      this.stop_web(playerID);
    }
  }
  stop_native(playerID: number) {
    return UMCore.stop(playerID);
  }

  SKF(keyFile: string, purchaseID: number): boolean {
    // return this.umCore.SKF(keyFile, purchaseID);
    return true;
  }

  cleanUp(): void {
    return UMCore.cleanUp();
  }

  enableVirtualVinyl(playerID: number, enable: boolean): number {
    return UMCore.enableVirtualVinyl(playerID, enable);
  }

  setVirtualVelocity(playerID: number, positionMs: number, timems: number): number {
    return UMCore.setVirtualVelocity(playerID, positionMs, timems);
  }

  setVirtualVelocityAvgTime(playerID: number, positionMs: number, timems: number, received: number): number {
    this.sumTime += window.performance.now() - received;
    this.valueCounter += 1;

    return UMCore.setVirtualVelocity(playerID, positionMs, timems);
  }
  setVirtualVTMode(playerID: number, mode: number): number {
    return UMCore.setVirtualVTMode(playerID, mode);
  }

  setVirtualVTInertia(playerID: number, inertia: number): number {
    return UMCore.setVirtualVTInertia(playerID, inertia);
  }

  getVirtualVTMode(playerID: number): number {
    return UMCore.getVirtualVTMode(playerID);
  }

  setScratchQuality(scratchQuality: number): number {
    return UMCore.setScratchQuality(scratchQuality);
  }

  play_web(playerID: number) {
    this.players[playerID].player.start();
  }

  play_web_webAudio(playerID: number) {
    this.bufferSource[playerID] = this.audioContext.createBufferSource();
    this.bufferSource[playerID].connect(this.gainNode);

    this.bufferSource[playerID].onended = () => {
      // this.onSampleEnded(slotNo);
    };
    this.bufferSource[playerID].buffer = this.buffer[playerID];
    this.gainNode.connect(this.audioContext.destination);
    this.bufferSource[playerID].start(0);
  }

  stop_web(playerID: number) {
    this.players[playerID].player?.stop();
  }

  stop_webaudio(playerID: number) {
    this.bufferSource[playerID].disconnect(); // web-MIDI
  }

  loadTrack$(playerID: number, track: Track): Observable<WaveformCache> {
    if (this.nativeAudioEngine) {
      return this.loadTrack_native$(playerID, track);
    } else {
      return this.loadTrack_web$(playerID, track);
    }
  }

  loadTrack_native$(playerID: number, track: Track): Observable<WaveformCache> {
    this.filePath = track.src; // TODO: save only track

    const loadingAudioData$ = of(null).pipe(
      tap(() => {
        const ret = UMCore.loadFile(playerID, this.filePath, this.mainSoundCard, this.monitorSoundCard, false);
        console.log(`[loadTrack_native]: playerID:${playerID} - result: ${ret}`);
      })
    );

    return forkJoin([loadingAudioData$, this.loadWaveformData$(track)]).pipe(map((results) => results[1]));
  }

  loadTrack_web$(playerID: number, track: Track): Observable<WaveformCache> {
    return forkJoin([this.loadingAudioData$_web(playerID, track), this.loadWaveformData$(track)]).pipe(map((results) => results[1]));
  }

  private loadingAudioData$_(playerID: number, track: Track): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.http.get(track.src, { responseType: 'arraybuffer' }).subscribe((data) => {
        this.audioContext.decodeAudioData(
          data,
          function (buffer: AudioBuffer) {
            this.buffer[playerID] = buffer;
            observer.next(true); // könnte hier raus
            observer.complete();
          }.bind(this)
        );
      });
    });
  }

  private loadingAudioData$_web(playerID: number, track: Track): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.players[playerID] = { player: null, volume: null, eq: null };
      const player = new Tone.Player(track.src, () => {
        const eq = new Tone.EQ3(0, 0, 0).toMaster();
        player.chain(eq);

        // player.sync().toMaster();
        // player.start(1);

        this.players[playerID].player = player;
        this.players[playerID].eq = eq;

        observer.next(true);
        observer.complete();
      });
    });
  }

  private isPlayerAvailable(playerID): boolean {
    return this.players[playerID] && this.players[playerID].player !== null;
  }

  setEQHigh(playerID: number, value: number) {
    console.log(`>>> setEQHigh: player:${playerID} - v:${value}`);
    this.currentEqHighValue = value;
    if (this.nativeAudioEngine) {
      this.setEQHigh_native(playerID, value);
    } else {
      this.setEQHigh_web(playerID, value);
    }
  }

  setEQHigh_native(playerID: number, value: number) {
    value = Math.round(value);
    const ret = UMCore.setEqHigh(playerID, value);
    console.log(`>>> setEQHigh_native: player:${playerID} - v:${value} - ret: ${ret}`);
  }

  setEQHigh_web(playerID: number, value: number) {
    if (this.isPlayerAvailable(playerID)) {
      this.players[playerID].eq.high.value = value;
    } else {
      console.log('no player available!', playerID);
    }
  }

  setEQMid(playerID: number, value: number) {
    this.currentEqMidValue = value;
    if (this.nativeAudioEngine) {
      this.setEQMid_native(playerID, value);
    } else {
      this.setEQMid_web(playerID, value);
    }
  }

  setEQMid_web(playerID: number, value: number) {
    if (this.isPlayerAvailable(playerID)) {
      this.players[playerID].eq.mid.value = value;
    }
  }

  setEQMid_native(playerID: number, value: number) {
    value = Math.round(value);
    UMCore.setEqMid(playerID, value);
  }

  setEQLow(playerID: number, value: number) {
    this.currentEqLowValue = value;
    if (this.nativeAudioEngine) {
      this.setEQLow_native(playerID, value);
    } else {
      this.setEQLow_web(playerID, value);
    }
  }

  setEQLow_web(playerID: number, value: number) {
    if (this.isPlayerAvailable(playerID)) {
      this.players[playerID].eq.low.value = value;
    }
  }

  setEQLow_native(playerID: number, value: number) {
    value = Math.round(value);
    UMCore.setEqLow(playerID, value);
  }

  killEqHigh(playerID: number, kill: boolean) {
    console.log(`>>> killEqHigh - player:${playerID} - kill:${kill}`);
    if (kill) {
      this.setEQHigh(playerID, 0);
    } else {
      this.setEQHigh(playerID, this.currentEqHighValue);
    }
  }

  killEqMid(playerID: number, kill: boolean) {
    if (kill) {
      this.setEQMid(playerID, 0);
    } else {
      this.setEQMid(playerID, this.currentEqMidValue);
    }
  }

  killEqLow(playerID: number, kill: boolean) {
    if (kill) {
      this.setEQLow(playerID, 0);
    } else {
      this.setEQLow(playerID, this.currentEqLowValue);
    }
  }

  // TODO: implement
  private loadWaveformData$(track: Track): Observable<WaveformCache> {
    let cacheSrc = track.src.replace('.mp3', '.cache.json');
    cacheSrc = cacheSrc.replace('.MP3', '.cache.json');

    return this.http.get<any>(cacheSrc).pipe(
      map((data) => new WaveformCache(data)),
      catchError((error) => {
        console.info('Failed loading waveform from cache...using fake waveform...', error);
        return this.http.get<WaveformCache>('./assets/testWaveformCache/data1.json').pipe(map((data) => new WaveformCache(data)));
      })
    );
  }

  // TODO: refactor (cannot load an absolute file path via browser without backend server)
  loadTrack(playerID: number, track: Track): Observable<boolean> {
    return of(false);

    // return new Observable<boolean>((observer) => {
    //   this.http.get(track.src, { responseType: 'arraybuffer' }).subscribe(
    //     (data) => {
    //       this.audioContext.decodeAudioData(
    //         data,
    //         function(buffer: AudioBuffer) {
    //           this.buffer[playerID] = buffer;
    //           observer.next(true); // könnte hier raus
    //           observer.complete();
    //         }.bind(this)
    //       );
    //     },
    //     (error: Error) => {
    //       console.error('Could not load track', error);
    //       observer.next(false);
    //       observer.complete();
    //     }
    //   );
    // });
  }

  private createSampler(): Observable<Tone.Sampler> {
    return new Observable<Tone.Sampler>((observer) => {
      const sampler = new Tone.Sampler(
        {
          C3: 'assets/beatmaker/tr808/1-TR808-Basedrum.mp3',
          D3: 'assets/beatmaker/tr808/2-TR808-Snare.mp3',
          E3: 'assets/beatmaker/tr808/3-TR808-Hihat.mp3',
          F3: 'assets/beatmaker/tr808/4-TR808-Open-Hihat.mp3',
        },
        () => {
          console.log('createSampler-el:');
          console.log('sampler: ', sampler);
          sampler.toDestination();
          observer.next(sampler);
          observer.complete();
        }
      );
      console.log('sampler: ', sampler);
    });
  }
  private createAudioSampler(): Observable<Tone.Sampler> {
    return new Observable<Tone.Sampler>((observer) => {
      const sampler = new Tone.Sampler(
        {
          C3: 'assets/beatmaker/tr808/1-TR808-Basedrum.mp3',
          D3: 'assets/beatmaker/tr808/2-TR808-Snare.mp3',
          E3: 'assets/beatmaker/tr808/3-TR808-Hihat.mp3',
          F3: 'assets/beatmaker/tr808/4-TR808-Open-Hihat.mp3',
        },
        () => {
          console.log('createAudioSampler-el:');
          sampler.toDestination();
          observer.next(sampler);
          observer.complete();
        }
      );

      console.log('sampler: ', sampler);
    });
  }

  private createSequencer(pattern: BeatMakerPattern): Tone.Sequence<any> {
    const events = new Array();
    for (let i = 0; i < pattern.noBars; i++) {
      events.push(i);
    }

    const sequencer = new Tone.Sequence(
      function (time, note) {
        //console.log('time: ', time);
        //  console.log('note: ', note);

        this.currentPattern.tracks.forEach((track) => {
          if (track.bars[note] === 1 && !track.mute) {
            this.sampler.triggerAttack(track.note, time);
          }
        });

        Tone.Draw.schedule(() => {
          this.zone.run(() => {
            this.store.dispatch(setCurrentPlayingBarAction({ barIndex: note }));
          });
        }, time);
      }.bind(this),
      events,
      '8n'
    );
    Tone.Transport.bpm.value = pattern.bpm;

    return sequencer;
  }

  // todo: use audio sprite as bank
  loadBeatMakerBank(pattern: BeatMakerPattern): BeatMakerPattern {
    this.currentPattern = pattern;
    if (!this.sequence) {
      this.sequence = this.createSequencer(pattern);
      console.log('>>> loadBeatMakerBank:this.sequence', this.sequence);
      this.sequence.start(0);
    }

    //   this.reverb = new Tone.Reverb().toMaster();
    // let promise = this.reverb.generate()
    // .then(() => this.createSampler())
    return pattern;
  }

  initializingBeatmaker(): Observable<boolean> {
    return this.createSampler().pipe(
      map((sampler) => {
        this.sampler = sampler;
        return sampler !== null;
      })
    );
  }

  initializingAudioSampleBank$(): Observable<boolean> {
    return this.createAudioSampler().pipe(
      map((sampler) => {
        this.audioSampler = sampler;
        console.log(">>> audioSampler", sampler);
        return sampler !== null;
      })
    );
  }

  playSampleSlot(sampleSlot: number) {
    if (this.nativeAudioEngine) {
      this.playSampleSlot_native(sampleSlot);
    } else {
      this.playSampleSlot_web(sampleSlot);
    }
  }

  playSampleSlot_web(sampleSlot: number) {
    const notes = { 0: 'C3', 1: 'D3', 2: 'E3', 3: 'F3' };
    console.log(">>> play sampler slot")
    this.audioSampler.triggerAttackRelease(notes[sampleSlot], '1n');
  }

  playSampleSlot_native(slotNumber: number) {
    let testClass = window['ultramixer.swing.audiosampler'];
    testClass.playSample(slotNumber);
  }

  playDrumPattern() {
    this.sequence.start(0);
    Tone.Transport.start();
  }

  stopDrumPattern() {
    Tone.Transport.stop();
  }

  soloTrack(selected: boolean, track: BeatMakerTrack) {
    let atLeastOneTrackMuted = false;
    this.currentPattern.tracks.forEach((_track) => {
      if (_track.mute) {
        atLeastOneTrackMuted = true;
        return;
      }
    });
    console.log('atLeastOneTrackMuted: ', atLeastOneTrackMuted);
    if (selected) {
      track.mute = false;
      if (!atLeastOneTrackMuted) {
        this.currentPattern.tracks.forEach((_track) => {
          if (_track.id !== track.id) {
            _track.mute = true;
          }
        });
      }
    } else {
      this.currentPattern.tracks.forEach((_track) => {
        _track.mute = false;
      });
    }
  }

  muteTrack(mute: boolean, track: BeatMakerTrack) {
    track.mute = mute;
  }

  setSpeed(bpm: number) {
    if (bpm) {
      Tone.Transport.bpm.value = bpm;
    }
  }

  seek(barIndex: number) {
    // this.sequence.seek
  }

  enableEcho(enable: boolean) {
    if (!this.sampler) {
      return;
    }

    if (enable) {
      this.sampler.connect(this.reverb);
    } else {
      this.sampler.disconnect(this.reverb);
    }
  }

  timeMessurement() {}

  loop(playerID: number, loop: boolean) {
    if (this.isPlayerAvailable(playerID)) {
      this.players[playerID].player.loop = loop;
    }
  }

  setLoopIn(playerID: number, time: number) {
    if (this.isPlayerAvailable(playerID)) {
      this.players[playerID].player.loopStart = time;
    }
  }

  setLoopOut(playerID: number, time: number) {
    if (this.isPlayerAvailable(playerID)) {
      this.players[playerID].player.loopEnd = time;
    }
  }

  getFileUrlFromFile(file: any) {
    if (this.nativeAudioEngine) {
      return file?.path;
    } else {
      return URL.createObjectURL(file);
    }
  }

  setCrossfader(value: number) {
    {
      this.setVolume(0, value - 10000);
      this.setVolume(1, value);
    }
  }
}
