import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MixerEmbedComponent } from './mixer-embed/mixer-embed.component';
import { MixerModule } from '../main/mixer/mixer.module';
import { AudioSamplerEmbedComponent } from './audio-sampler-embed/audio-sampler-embed.component';
import { TurntableEmbedComponent } from './turntable-embed/turntable-embed.component';
import { SoundPlayerModule } from '../main/sound-player/sound-player.module';
import { WidgetsModule } from '../main/widgets/widgets.module';
import { SoundPlayerEmbedComponent } from './sound-player-embed/sound-player-embed.component';

@NgModule({
  declarations: [MixerEmbedComponent, AudioSamplerEmbedComponent, TurntableEmbedComponent, SoundPlayerEmbedComponent],
  imports: [CommonModule, MixerModule, WidgetsModule, SoundPlayerModule],
  exports: [MixerEmbedComponent, AudioSamplerEmbedComponent, TurntableEmbedComponent],
})
export class EmbeddingModule {}
