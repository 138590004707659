<ion-header>
    <ion-toolbar>
        <ion-title>Action Chooser</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="handleClose();">Close</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-list>
        <ng-container *ngFor="let actionGroup of actions$ | async">
            <ion-list-header style="margin-top: 10px;">{{actionGroup.name}}</ion-list-header>
            <ion-item *ngFor="let action of actionGroup.actions" (click)="handleAddAction(action);">
                <ion-label>{{action.name}}</ion-label>

                <!-- (click)="handleAddAction(action);" //-->
                <ion-button class="delete-button" fill="outline" slot="end"
                           > Add
                </ion-button>
            </ion-item>
        </ng-container>
    </ion-list>
</ion-content>