import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSegment } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  showBeatMakerAction,
  showMixerAction,
  showMusicArchiveAction,
  toggleFullScreenModeAction,
} from '../../core/redux/actions/general.actions';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @ViewChild('buttons', { static: false }) buttons: IonSegment;

  constructor(private store: Store) {}

  ngOnInit() {}

  toggleFullscreenMode() {
    this.store.dispatch(toggleFullScreenModeAction());
  }

  handleShowMusicArchive() {
    this.store.dispatch(showMusicArchiveAction());
  }

  handleShowMixer() {
    this.store.dispatch(showMixerAction());
  }

  handleShowBeatMaker() {
    this.store.dispatch(showBeatMakerAction());
  }

  segmentChanged(event: CustomEvent) {
    console.log(event);

    switch (event.detail.value) {
      case 'mixer':
        this.handleShowMixer();
        break;
      case 'music':
        this.handleShowMusicArchive();
        break;
      case 'beatmaker':
        this.handleShowBeatMaker();
        break;
    }
  }
}
