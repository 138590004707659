export class MidiMessage {

    private _id;
    private _channel;
    private _command;
    private _data1;
    private _data2;
    private _timeStamp;

    constructor(channel,command,data1,data2){
        this._channel = channel;
        this._command = command;
        this._data1 = data1;
        this._data2 = data2;
        this._id = (channel + command) << 16 | data1 << 8 | data2;
        this._timeStamp = window.performance.now();
    }

    get channel() {
        return this._channel;
    }

    set channel(value) {
        this._channel = value;
        this._id = (this._channel + this._command) << 16 | this._data1 << 8 | this._data2;
    }

    get command() {
        return this._command;
    }

    set command(value) {
        this._command = value;
        this._id = (this._channel + this._command) << 16 | this._data1 << 8 | this._data2;
    }

    get data1() {
        return this._data1;
    }

    set data1(value) {
        this._data1 = value;
        this._id = (this._channel + this._command) << 16 | this._data1 << 8 | this._data2;
    }

    get data2() {
        return this._data2;
    }

    set data2(value) {
        this._data2 = value;
        this._id = (this._channel + this._command) << 16 | this._data1 << 8 | this._data2;
    }

    get timeStamp() {
        return this._timeStamp;
    }

    set timeStamp(value) {
        this._timeStamp = value;
    }

    get id() {
        return this._id;
    }

    public toString(){
        return "Channel: " + this._channel + " Command: " + this._command + " Data1: " + this._data1 + " Data2: " + this._data2;
    }



}