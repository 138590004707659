<ion-header>
  <ion-toolbar>
    <ion-buttons>
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Tracks</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div *ngIf="isLoadingTracks$ | async" class="loader"><ion-spinner></ion-spinner></div>
  <ion-list *ngIf="!(isLoadingTracks$ | async)" insets>
    <ion-item *ngFor="let track of tracks$ | async" (click)="loadTrackIntoPlayer(track,0)">
      <ion-label>{{ track.artist }} - {{ track.title }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
