import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MusicArchiveMobileComponent } from './music-archive-mobile.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [MusicArchiveMobileComponent],
  imports: [CommonModule, IonicModule],
  exports: [MusicArchiveMobileComponent]
})
export class MusicArchiveMobileModule {}
