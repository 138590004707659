import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MidiEditorComponent} from './midi-editor/midi-editor.component';

import {ACE_CONFIG, AceConfigInterface, AceModule} from 'ngx-ace-wrapper';
import {RouterModule, Routes} from "@angular/router";

const DEFAULT_ACE_CONFIG: AceConfigInterface = {};

const routes: Routes = [
    {
        path: '',
        component: MidiEditorComponent
    },
    {
        path: 'editor',
        component: MidiEditorComponent
    }
];

@NgModule({
    declarations: [MidiEditorComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        AceModule,
    ],
    providers: [
        {
            provide: ACE_CONFIG,
            useValue: DEFAULT_ACE_CONFIG
        }
    ]
})
export class MidiModule {
}
