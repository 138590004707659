import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  initializingBeatMakerAction,
  loadPatternAction,
  muteTrackAction,
  playBeatAction,
  seekAction,
  setSpeedAction,
  soloTrackAction,
  stopBeatAction,
  toggleEchoAction,
} from '../../../core/redux/actions/beatmaker.actions';
import {
  currentPlayingBarIndex,
  getPattern,
  isEchoEnabled,
  isLoadingPattern,
  isPlaying,
} from '../../../core/redux/reducers/beatmaker.reducer';
import { AudioService } from '../../../services/audio.service';
import { BeatMakerPattern } from '../beat-maker-pattern';
import { BeatMakerTrack } from '../beat-maker-track';
import { BeatMakerBarComponent } from './beat-maker-bar/beat-maker-bar.component';

@Component({
  selector: 'beat-maker',
  templateUrl: './beat-maker.component.html',
  styleUrls: ['./beat-maker.component.scss'],
})
export class BeatMakerComponent implements OnInit {
  @ViewChild('ghostBar1', { static: false }) ghostBar1: BeatMakerBarComponent;
  @ViewChild('ghostBar2', { static: false }) ghostBar2: BeatMakerBarComponent;
  @ViewChild('ghostBar3', { static: false }) ghostBar3: BeatMakerBarComponent;
  @ViewChild('ghostBar4', { static: false }) ghostBar4: BeatMakerBarComponent;

  @ViewChildren(BeatMakerBarComponent) bars!: QueryList<BeatMakerBarComponent>;

  noBars$: Observable<number[]>;
  pattern$: Observable<BeatMakerPattern> = this.store.select(getPattern);
  isLoadingPattern$: Observable<boolean> = this.store.select(isLoadingPattern);
  currentPlayingBarIndex$: Observable<number> = this.store.select(currentPlayingBarIndex);
  isBeatMakerPlaying$: Observable<boolean> = this.store.select(isPlaying);
  isEchoEnabled$: Observable<boolean> = this.store.select(isEchoEnabled);

  private pattern: BeatMakerPattern;

  constructor(private store: Store, private el: ElementRef, private audioService: AudioService) {
    this.noBars$ = this.pattern$.pipe(
      map((pattern) => {
        if (pattern) {
          return new Array(pattern.noBars).fill(0);
        } else {
          return new Array(0).fill(0);
        }
      })
    );
  }

  ngOnInit() {
    this.pattern$.subscribe((pattern) => (this.pattern = pattern));
    this.store.dispatch(initializingBeatMakerAction());
    // this.loadPattern(BeatMakerPattern.defaultPattern());
  }

  loadPattern(pattern: BeatMakerPattern) {
    this.store.dispatch(loadPatternAction({ pattern }));
  }

  play() {
    this.store.dispatch(playBeatAction());
  }

  stop() {
    this.store.dispatch(stopBeatAction());
  }

  soloTrack(solo: boolean, track: BeatMakerTrack, trackIndex: number) {
    this.store.dispatch(soloTrackAction({ track, trackIndex, solo }));
  }

  muteTrack(mute: boolean, track: BeatMakerTrack, trackIndex: number) {
    this.store.dispatch(muteTrackAction({ track, trackIndex, mute }));
  }

  speedChanged(value: number) {
    if (value) {
      this.store.dispatch(setSpeedAction({ bpm: value }));
    }
  }

  handleSeek(barIndex: number) {
    this.store.dispatch(seekAction({ barIndex }));
  }

  toggleEcho() {
    this.store.dispatch(toggleEchoAction());
  }

  changePattern() {
    this.moveBar(this.ghostBar1, 25);
    this.moveBar(this.ghostBar2, 27);
    this.moveBar(this.ghostBar3, 29);
    this.moveBar(this.ghostBar4, 31);
  }

  private moveBar(bar: BeatMakerBarComponent, index: number) {
    const firstBarPos = this.bars.toArray()[index].el.nativeElement.getBoundingClientRect();
    const ghostBarPos = bar.el.nativeElement.getBoundingClientRect();

    const xDiff = firstBarPos.x - ghostBarPos.x;
    const yDiff = firstBarPos.y - ghostBarPos.y;

    let keyframes = [
      { transform: 'translate(0px,0px)', opacity: 0 },
      { transform: `translate(${xDiff}px,${yDiff}px)`, opacity: 1 },
    ];
    const a = bar.el.nativeElement.animate(keyframes, 1000);
    a.play();
  }
}
