import {ActionWithPayload} from './action.interface';
import {MidiMessage} from "../../../models/MidiMessage";

export enum MidiActions {
    MIDI_INPUT = '[Midi] Input Signal'
}

export class MidiInputAction implements ActionWithPayload {
    readonly type: string = MidiActions.MIDI_INPUT;
    payload: { midiMessage: MidiMessage };

    constructor(payload: { midiMessage: MidiMessage }) {
        this.payload = payload;
    }
}
