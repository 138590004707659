import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MidiSoundControlService } from '../../../services/midi-sound-control.service';
import { MidiService } from '../../../services/midi.service';

@Injectable()
export class MidiEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private midiService: MidiService,
    private router: Router,
    private midiSoundCtrlService: MidiSoundControlService
  ) {}

  /*
    @Effect({dispatch: false})
    handleNewMidiMessage$: Observable<any> = this.actions$.pipe(
        ofType(MidiActions.MIDI_INPUT),
        concatMap((action: MidiInputAction) => this.midiSoundCtrlService.handleNewMessage$(action.payload.midiMessage))
    )
    */

  /*
    @Effect({dispatch: false})
    handleNewMidiMessage$: Observable<any> = this.actions$.pipe(
        ofType(MidiActions.MIDI_INPUT),
        concatMap((action: MidiInputAction) => this.midiSoundCtrlService.handleNewMessage$(action.payload.midiMessage))
    )
    */
}
