import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { Track } from '../../../music-archive/track';
import { DataService } from '../../../services/data.service';
import { Group } from '../../../services/group';
import {
  AddDirectoryAction,
  AddDirectoryFailedAction,
  AddDirectorySuccessfulAction,
  AddGroupAction,
  AddGroupSuccessfulAction,
  ArchiveActions,
  LoadTracksFailedAction,
  RemoveGroupAction,
  RemoveGroupSuccessfulAction,
  RenameGroupAction,
  RenameGroupSuccessfulAction,
  loadGroupsFailedAction,
  loadGroupsSucceededAction,
  loadTracksSucceededAction,
} from '../actions/archive.actions';
import { loadGroupsAction, loadTracksAction } from './../actions/archive.actions';
import { UmFunctionsEnum } from '../../enums/um-functions.enum';
import { TrackDataForResponse } from '../../interfaces/um-functions.interface';

@Injectable()
export class ArchiveEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private dataService: DataService,
    public toastController: ToastController,
    public alertController: AlertController
  ) {}

  loadGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroupsAction),
      switchMap((action) =>
        this.dataService.getGroups$().pipe(
          map((groups: TreeNode[]) => loadGroupsSucceededAction({ groups })),
          catchError((error) => of(loadGroupsFailedAction({ error })))
        )
      )
    )
  );

  loadTracks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTracksAction),
      switchMap((action) =>
        this.dataService.getTracks().pipe(
          map((tracks: Track[]) => loadTracksSucceededAction({ tracks })),
          catchError((error) => {
            console.log('error: ', error);
            this.presentAlert('Could not load tracks (no internet connection?');
            return of(new LoadTracksFailedAction({ groupID: action.groupID, error }));
          })
        )
      )
    )
  );

  addDirectory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.ADD_DIRECTORY),
      switchMap((action: AddDirectoryAction) => this.dataService.addDirectory(action.payload.dir)),
      map((ret: boolean) => new AddDirectorySuccessfulAction()),
      catchError((error) => {
        console.log('error');
        console.log(error);
        this.presentAlert('Could not add directory (no internet connection?');
        return of(new AddDirectoryFailedAction());
      })
    )
  );

  removeGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.REMOVE_GROUP),
      switchMap((action: RemoveGroupAction) =>
        this.dataService.removeGroup(action.payload.group).pipe(
          map((ret: boolean) => new RemoveGroupSuccessfulAction({ group: action.payload.group })),
          catchError((error) => {
            console.log('error while adding group: ', error);
            return of(new RemoveGroupSuccessfulAction({ group: action.payload.group })); // todo
          })
        )
      )
    )
  );

  renameGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.RENAME_GROUP),
      switchMap((action: RenameGroupAction) =>
        this.dataService.renameGroup(action.payload.group, action.payload.newName).pipe(
          map((ret: boolean) => {
            return new RenameGroupSuccessfulAction({ group: action.payload.group });
          }),
          catchError((error) => {
            console.log('error while adding group: ', error);
            return of(new RenameGroupSuccessfulAction({ group: action.payload.group })); // todo
          })
        )
      )
    )
  );

  addGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.ADD_GROUP),
      switchMap((action: AddGroupAction) =>
        this.dataService.addGroup(action.payload.groupName).pipe(
          map((addedGroup: Group) => {
            return new AddGroupSuccessfulAction({
              group: Group.treeNodeFromGroup(addedGroup),
            });
          }),
          catchError((error) => {
            console.log('error while adding group: ', error);
            return of(new AddGroupSuccessfulAction({ group: null })); // todo
          })
        )
      )
    )
  );

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      // showCloseButton: true,
      color: 'warning',
      position: 'middle',
      // closeButtonText: 'Done'
    });
    toast.present();
  }

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      // subHeader: 'Subtitle',
      mode: 'ios',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
