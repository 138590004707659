<div class="root">
    <ion-label>Um potentielle Probleme analysieren zu können, benötigen wir die Logfiles von UltraMixer. Bitte geben Sie
        nun Ihre E-Mail-Adresse und den Ihnen vom Support-Team mitgeteilten Code an. Durch Bestätigen des
        "Senden"-Buttons
        werden die Logfiles zur Aalyse an das UltraMixer-Support-Team gesendet. Vielen Dank.</ion-label>

    <ion-item>
        <ion-label position="stacked">E-Mail</ion-label>
        <ion-input clearInput name="email" id="email" [value]="email" placeholder="Deine E-Mail-Adresse"></ion-input>
    </ion-item>

    <ion-item>
        <ion-label position="stacked">Support-Code</ion-label>
        <ion-input clearInput [value]="supportCode" id="supportCode3"
                   placeholder="Den Support-Code bekommst du von uns per E-Mail oder Telefon"></ion-input>
    </ion-item>

    <ion-item>
        <ion-label position="stacked">Problembeschreibung</ion-label>
        <ion-textarea class="logfilesContentTextArea" id="problemDesc" [value]="problemDesc" wrap="hard" rows="10"
                      cols="100">
        </ion-textarea>
    </ion-item>


    <div>
        <button id="sendLogButton" (click)="sendLog($event)">Sende Logfiles...</button>
    </div>

    <!--<h1>test zeug</h1>-->
    <!--<input name="myinpouts" [(ngModel)]="username">-->
    <!--<p>Hello {{username}}!</p>-->
</div>