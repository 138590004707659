<div class="display">
  <div class="row1">
    <div class="block">
      <div class="label">TRACK</div>
      <div class="value" id="track">01</div>
    </div>
    <div class="time-block">
      <div class="block time">
        <div class="label">+TIME</div>
        <div class="value">{{ time$ | async | time: 'mm:ss:SS'}}</div>
      </div>
      <div class="block remainingTime">
        <div class="label">-TIME</div>
        <div class="value">-{{ remainingTime$ | async | time: 'mm:ss:SS' }}</div>
      </div>
    </div>
    <div class="time-block">
      <div class="block pitch">
        <div class="label">PITCH</div>
        <div class="value">{{(pitch$ | async) | percent:'1.1-1' }}</div>
      </div>
      <div class="block">
        <div class="label">BPM</div>
        <div class="value" id="bpm">
          <!-- {{ (track$ | async)?.bpm }}-->
          0.00
        </div>
      </div>
    </div>
  </div>
  <div class="track-info">
    <ion-spinner *ngIf="isLoading$ | async" name="crescent"></ion-spinner>
    <ng-container *ngIf="!(isLoading$ | async)">
      <app-track-ticker [track]="track$|async"></app-track-ticker>
    </ng-container>
  </div>
</div>