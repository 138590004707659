<div class="root">
  <div class="logo"><img src="./assets/img/um-logo.png" height="30" /></div>

  <ion-segment #buttons value="music" class="buttons" color="dark" (ionChange)="segmentChanged($event)">
    <ion-segment-button value="mixer">
      <ion-label>Mixer</ion-label>
    </ion-segment-button>
    <ion-segment-button value="music">
      <ion-label>Music</ion-label>
    </ion-segment-button>
    <ion-segment-button value="beatmaker">
      <ion-label>BeatMaker</ion-label>
    </ion-segment-button>
  </ion-segment>

  <!--
  <button (click)="handleShowMixer()" class="mini-button">Mixer</button>
  <button (click)="handleShowMusicArchive()" class="mini-button">Music</button>
  <button (click)="handleShowBeatMaker()" class="mini-button">BeatMaker</button>
  <button (click)="toggleFullscreenMode()" class="mini-button">Fullscreen</button>
  -->
</div>