<div style="height: 100%;">
  <p-table [columns]="cols" [value]="tracks$ | async" class="table" [resizableColumns]="true"
    [styleClass]="'p-datatable-striped'" [reorderableColumns]="true" [scrollable]="true" [scrollHeight]="'100%'"
    selectionMode="multiple" [(selection)]="selectedTrack" [virtualScroll]="false" [virtualScrollItemSize]="20">
    <!-- scrollHeight="260px" -->
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width" />
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of cols" pResizableColumn>
          {{ col.header }}
        </th>
      </tr>
    </ng-template>

    <!--
    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>No</th>
            <th [pSortableColumn]="artist">Artist
                <p-sortIcon [field]="artist" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
            </th>
            <th [pSortableColumn]="title"> Title</th>
            <th>BPM</th>
            <th>Length</th>
            <th>Rating</th>
        </tr>
    </ng-template>
    //-->
    <!--
    <ng-template pTemplate="body" let-track let-rowIndex="rowIndex">
        <tr [pSelectableRow]="track" [pSelectableRowIndex]="rowIndex">
            <td><img src="./assets/img/djT-check-that-body-cover.jpg" class="cover"></td>
            <td>{{rowIndex+1}}</td>
            <td>{{track.artist}}</td>
            <td>{{track.title}}</td>
            <td>{{track.bpm}}</td>
            <td>{{track.length}}</td>
            <td>{{track.rating}}</td>
        </tr>
    </ng-template>
    //-->



    <ng-template pTemplate="body" let-track let-columns="columns" let-rowIndex="rowIndex">
      <tr>
        <td *ngFor="let col of columns" class="p-resizable-column">
          <ng-container [ngSwitch]="col.field">
            <div *ngSwitchCase="'no'">{{ rowIndex + 1 }}</div>
            <div *ngSwitchCase="'cover'"><img src="./assets/img/djT-check-that-body-cover.webp" class="cover" /></div>
            <div *ngSwitchCase="'length'">{{ track[col.field] * 1000 | time: 'mm:ss:SS' }}</div>

            <div *ngSwitchCase="'rating'"> <star-rating [starType]="'svg'" [rating]="track.rating"></star-rating></div>



            <div *ngSwitchCase="'actions'">
              <ion-button size="small" fill="outline" (click)="loadTrackIntoPlayer(track, 0)">A</ion-button>
              <ion-button size="small" fill="outline" (click)="loadTrackIntoPlayer(track, 1)">B</ion-button>
            </div>
            <div *ngSwitchDefault>{{ track[col.field] }}</div>
          </ng-container>
        </td>
      </tr>
      <!--
        <tr [pSelectableRow]="track" [pSelectableRowIndex]="rowIndex">
            <td><img src="./assets/img/djT-check-that-body-cover.webp" class="cover"></td>
            <td>{{rowIndex+1}}</td>
            <td>{{track.artist}}</td>
            <td>{{track.title}}</td>
            <td>{{track.bpm}}</td>
            <td>{{track.length}}</td>
            <td>{{track.rating}}</td>
        </tr>
        //-->
    </ng-template>
  </p-table>
</div>