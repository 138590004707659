import { WaveformCache } from "./WaveformCache";
import { BehaviorSubject } from 'rxjs';

export class WaveformSegmentModel {
  //repaint on cacheChange/msPxRatio/xPositionMs

  public segmentWidth: number;
  public segmentHeight: number;

  public xPositionMs: number; //segmentPositionSongMs
  public segmentPositionCanvasPx: number;

  public _waveformCache$: BehaviorSubject<WaveformCache> = new BehaviorSubject<WaveformCache>(null);
  private msPxRatioProvider: { msPxRatio: number }; //zoom

  //for debugging
  public idNumber:number;

  //segmentYPositionCanvas

  constructor(
    width: number,
    height: number,
    msPxRatioProvider: { msPxRatio: number }
  ) {
    this.segmentWidth = width;
    this.segmentHeight = height;
    this.msPxRatioProvider = msPxRatioProvider;
  }

  get msPxRatio(): number {
    return this.msPxRatioProvider.msPxRatio;
  }

  public getXPositionMs() {
    return this.xPositionMs;
  }

  public setXPositionMs(xPos: number) {
    this.xPositionMs = xPos;
  }

  /*public set waveformCache(cache: WaveformCache) {
    this._waveformCache = cache;
  }*/
}
