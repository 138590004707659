import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { gsap } from 'gsap';

@Component({
  selector: 'turntable2',
  templateUrl: './turntable2.component.html',
  styleUrls: ['./turntable2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Turntable2Component implements OnInit, AfterViewInit {
  @Input() loading = false;
  _playing = false;

  @ViewChild('vinyl', { static: false }) vinyl: ElementRef;
  private animation: any;

  constructor() {}

  @Input('playing')
  set playing(_playing: boolean) {
    this._playing = _playing;

    if (this.animation) {
      if (this._playing) {
        this.animation.play();
        gsap.to(this.animation, { timeScale: 0.8, duration: 1.0 });
      } else {
        gsap.to(this.animation, {
          timeScale: 0,
          duration: 0.5,
          onComplete: function () {
            this.pause();
          },
        });
      }
    }
  }

  get playing() {
    return this._playing;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.animation = gsap
      .to(this.vinyl.nativeElement, { ease: 'none', repeat: -1, paused: true, rotation: 360, transformOrigin: '50% 50%', duration: 1.0 })
      .timeScale(0);
  }
}
