import {HIDDevice} from './HIDDevice';

export class HIDDeviceInfo {
    private hidDeviceInfoJavaObject: any;

    constructor(hidDeviceInfoJavaObject: any) {
        this.hidDeviceInfoJavaObject = hidDeviceInfoJavaObject;
    }

    toString(): string {
        const str: string = this.hidDeviceInfoJavaObject.toString();
        return str;
    }

    getPath(): string {
        const str: string = this.hidDeviceInfoJavaObject.getPath();
        return str;
    }

    getSerial_number(): string {
        const str: string = this.hidDeviceInfoJavaObject.getSerial_number();
        return str;
    }

    getVendor_id(): number {
        const str: number = this.hidDeviceInfoJavaObject.getVendor_id();
        return str;
    }

    getProduct_id(): number {
        const str: number = this.hidDeviceInfoJavaObject.getProduct_id();
        return str;
    }

    open(): HIDDevice {
        const HIDDeviceJavaObj = this.hidDeviceInfoJavaObject.open();
        return new HIDDevice(HIDDeviceJavaObj);
    }
}
