import {Component, Input, NgZone, OnInit} from '@angular/core';

@Component({
    selector: 'turntable',
    templateUrl: './turntable.component.html',
    styleUrls: ['./turntable.component.scss']
})
export class TurntableComponent implements OnInit {

    @Input("playing") playing: boolean = false;

    constructor(private zone: NgZone) {
    }

    ngOnInit() {

        window["ultramixer"] = {
            turntable: {
                setPlaying: this.setPlaying.bind(this)
            }
        };
    }

    setPlaying(playing: boolean) {
        this.zone.run(() => {
            this.playing = playing;
        });
    }

}
