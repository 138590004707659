import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mixer-embed',
  templateUrl: './mixer-embed.component.html',
  styleUrls: ['./mixer-embed.component.scss']
})
export class MixerEmbedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
