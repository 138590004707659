import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { min } from 'rxjs/operators';

@Component({
  selector: 'ox-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Output() valueChanged = new EventEmitter<number>();
  private _value = 100;
  @Input() min = 0;
  @Input() max = 100;
  @Input() steps = 1;

  constructor() { }

  ngOnInit() {
  }


  handleDecrease() {
    this.value = Math.max(this.value - this.steps,this.min);
  }

  handleIncrease() {
    this.value = Math.min(this.value + this.steps);
  }

  

  @Input('value')
  set value(value: number) {
    this._value = value;
    this.valueChanged.emit(this.value);
  }

  get value() {
    return this._value;
  }
}
