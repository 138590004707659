<ion-content [scrollY]="false">
  <div class="root">
    <!--<waveform></waveform>//-->
    <dual-waveform-v1 *ngIf="true" [data1]="waveformCache1$|async" [data2]="waveformCache2$|async"
      [debugMode]="false"></dual-waveform-v1>
    <div class="dualsoundplayer">
      <sound-player #soundPlayer1 [playerID]="0"></sound-player>
      <widgets></widgets>
      <sound-player #soundPlayer2 [playerID]="1"></sound-player>
    </div>
    <mixer *ngIf="mixerVisible$ | async"></mixer>
    <beat-maker *ngIf="beatMakerVisible$ | async"></beat-maker>
    <music-archive *ngIf="!mobile && (musicArchiveVisible$ | async)"></music-archive>
    <music-archive-mobile *ngIf="(musicArchiveVisible$ | async) && mobile"></music-archive-mobile>
  </div>
</ion-content>
<ion-footer>
  <footer></footer>
</ion-footer>