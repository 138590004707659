import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loginAction } from '../core/redux/actions/general.actions';
import { isLoggingIn } from '../core/redux/reducers/general.reducer';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  loggingIn$: Observable<boolean> = this.store.select(isLoggingIn);

  constructor(private store: Store) {}

  ngOnInit() {}

  handleLogin() {
    this.store.dispatch(loginAction({ username: '', password: '' }));
  }
}
