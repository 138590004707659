import { Component, OnInit, ElementRef, ViewChild, HostListener, Input, AfterViewInit } from '@angular/core';
import { WaveformSegment } from '../waveform-segment';
import { BSWaveformComponent } from '../bs-waveform/bs-waveform.component';
import { HttpClient } from '@angular/common/http';
import { WaveformCache } from '../WaveformCache';
import { WaveformCacheI } from '../WaveformCacheI';
import { of, timer, Observable, Subject, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { WaveformSegmentModel } from '../WaveformSegmentModel';
import { WaveformSegmentComponent } from '../waveform-segment/waveform-segment.component';

@Component({
  selector: 'dual-waveform-v2',
  templateUrl: './dual-waveform.component.html',
  styleUrls: ['./dual-waveform.component.scss'],
})

/**
 * ROUTE: "waveform/dual2" || "waveform/dual"
 *
 * Second dual-waveform implementation
 * v1 was flickiering due to live reactClear and repaint every timer-tick
 * This implementation uses one canvas per segment instead on one canvas per waveform.
 * Each segment is an instance of {@link WaveformSegmentComponent} which is initialized via a template (ng-for)
 * from a list of {@link WaveformSegmentModel} instances.
 *
 * The segment components are moved across the background via "translateX(...)" css binding
 * updated via segmentPositionCanvasPxTranlate-property of segment-component
 * @see drawWaveform()
 * @see drawOrPositionSegment()
 */
export class DualWaveformComponent implements OnInit, AfterViewInit {
  constructor(http: HttpClient) {
    this.http = http;
  }
  @Input('data2') set cacheInfo2(data: WaveformCache) {
    this._cacheInfo2 = data;
    // this.initSegments();
  }

  get cacheInfo2() {
    return this._cacheInfo2;
  }

  // background canvas
  @ViewChild('bgCanvas', { static: false })
  bgCanvas: ElementRef;

  // forground canvas
  @ViewChild('fgCanvas', { static: false })
  fgCanvas: ElementRef;

  @ViewChild('p1Canvas', { static: false })
  p1Canvas: ElementRef;
  @ViewChild('p2Canvas', { static: false })
  p2Canvas: ElementRef;

  @ViewChild('segmentContainer', { static: false })
  segmentContainer: ElementRef;

  @ViewChild(DualWaveformComponent, { static: false })
  msPxRatioProvider: DualWaveformComponent = this;

  @Input() debugMode = true;

  protected _cacheInfo1$ = new BehaviorSubject(null);

  // unused for now, SHOULD WE USED BEHAVIOUR SUBJECT OR THIS??? does behavioursubject work well with @Input?
  _cacheInfo2: WaveformCache = null;

  // for now canvas 1024
  canvasWidth = 1024; //OLD: replace this with obeservables
  canvasHeight = 200; //OLD: replace this with obeservables
  public waveformWidth$ = new BehaviorSubject(800); //CURRENTLY UNUSED
  public waveformHeight$ = new BehaviorSubject(140); //CURRENTLY UNUSED

  segmentCount = 20; // segment count per playerlist
  segmentWidth = 64;//128; // 512;

  // maximum number prerendered segments on the left/right side outside of the main canvas waveform
  rightMaxPrerendered = 1;
  leftMaxPrerendered = 0;

  // zoom == millisecond to pixel ratio, this is later controlled by user, for now hardset to value for cache
  // hardcoded value specific to testWaveformCache/data1.json waveformCache 
  // of the song C:\\Program Files\\UltraMixer6\\The_Admirals_featuring_Seraphina_Bass!Man2010_UltraMixerEdit.mp4
  // TODO: later this is used as a zoom factor and as input for PlayerFmod4.calcWaveform() to get waveform-data specific to this value
  msPxRatio = 14.6069; 
  // current play position in ms, should later be aquired from player
  playerPositionMs = 0;

  // position of play-line on main canvas, length of canvas is irrelevant, only playpos relative to canvas-start (0) matters
  private playPosPx = 0; // will be changed in ngOnInit() to middle of canvas

  // cached segments - this will be of constant size once segments are initialized since no segments get added or deleted; just reused
  // filled in this.initSegments()
  // contains all segments including those
  segmentsPool: WaveformSegmentModel[] = new Array();

  // used with template in
  // displaySegmentList: WaveformSegmentModel[] = new Array();
  displaySegmentListObservable$: BehaviorSubject<WaveformSegmentModel[]> = new BehaviorSubject<WaveformSegmentModel[]>(new Array());

  http: HttpClient;

  // cache for dragscrolling waveform, since only one songs waveform can be scrolled at a time this might be used both for p1 and p2
  dragStart: number; //DnD related

  private paused = true;
  private playSub;
  @Input('data1') setCacheInfo1(data: WaveformCache) {
    this._cacheInfo1$.next(data);
  }

  getCacheInfo1(): WaveformCache {
    return this._cacheInfo1$.getValue();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit called');

    this.playPosPx = this.canvasWidth / 2;
    this.redrawBgCanvas();
    this.redrawFgCanvas();

    console.log('chacheInfo1:' + this.getCacheInfo1());
    this._cacheInfo1$.subscribe((wfCache: WaveformCache) => {
      if (wfCache && wfCache != null) {
        console.log('about to init segments:' + wfCache);
        this.initSegments(wfCache);
      } else {
        console.log('cache empty:' + this.getCacheInfo1());
        this.clearSegments();
      }
    });

    if (this.debugMode) {
      console.log('debugMode:' + this.debugMode);
      this.loadDummyWaveformCache();
    }
  }

  handlePlay() {
    this.paused = false;
    if (!this.playSub) {
      this.playSub = timer(0, 8)
        .pipe(
          tap(() => {
            if (!this.paused) {
              this.playerPositionMs += 8;
              this.updateSegmentsPosition();
            }
          })
        )
        .subscribe();
    }
  }

  handlePause() {
    this.paused = true;
  }

  @HostListener('mousedown', ['$event'])
  mouseDown(event: MouseEvent) {
    if ((event.target as HTMLElement).id === 'p1Canvas') {
      // console.log('mousedown on p1Canvas -> remember position %s', event.screenX);
      this.dragStart = event.screenX;
      // todo: stop player before dragging, resume on drop if was playing before
    }
  }

  @HostListener('mouseup', ['$event'])
  mouseUp(event: MouseEvent) {
    // console.log("mouseupEventP1", event);
    this.dragStart = null;
  }

  @HostListener('mousemove', ['$event'])
  mouseMove(event: MouseEvent) {
    if (this.dragStart) {
      const deltaX = event.screenX - this.dragStart;
      // console.log('mouse up and was dragging -> move delta px %s',deltaX);
      const deltaPlayPosMs = deltaX * this.msPxRatio * -1;
      this.playerPositionMs += deltaPlayPosMs;
      this.updateSegmentsPosition(); // todo: trigger this by playerPos value change
      this.dragStart = event.screenX;
    }
  }

  dummyFillP1P2Canvas() {
    const p1Canvas: HTMLCanvasElement = this.p1Canvas.nativeElement;
    const p1Ctx: CanvasRenderingContext2D = p1Canvas.getContext('2d');

    p1Ctx.lineWidth = 2;
    p1Ctx.strokeStyle = 'red';
    p1Ctx.beginPath();
    p1Ctx.moveTo(0, 0);
    p1Ctx.lineTo(p1Canvas.width, p1Canvas.height);
    p1Ctx.stroke();

    const p2Canvas: HTMLCanvasElement = this.p2Canvas.nativeElement;
    const p2Ctx: CanvasRenderingContext2D = p2Canvas.getContext('2d');

    p2Ctx.lineWidth = 2;
    p2Ctx.strokeStyle = 'red';
    p2Ctx.beginPath();
    p2Ctx.moveTo(0, 0);
    p2Ctx.lineTo(p2Canvas.width, p2Canvas.height);
    p2Ctx.stroke();
  }
  redrawFgCanvas() {
    const fgCanvas: HTMLCanvasElement = this.fgCanvas.nativeElement;
    const fgCtx: CanvasRenderingContext2D = fgCanvas.getContext('2d');
    fgCtx.strokeStyle = 'gold';
    fgCtx.strokeRect(0, fgCanvas.height / 2 + 1, fgCanvas.width, 1);
    fgCtx.stroke();

    fgCtx.strokeStyle = 'white';
    fgCtx.strokeRect(0, 0, fgCanvas.width / 2 + 1, fgCanvas.height);
    fgCtx.stroke();
  }

  redrawBgCanvas() {
    const bgCanvas: HTMLCanvasElement = this.bgCanvas.nativeElement;
    const bgCtx: CanvasRenderingContext2D = bgCanvas.getContext('2d');
    bgCtx.fillStyle = 'black';
    bgCtx.fillRect(0, 0, bgCanvas.width, bgCanvas.height);
    bgCtx.fill();
  }

  /**
   * load dummy cache and initialize segments thereafter
   */
  loadDummyWaveformCache() {
    console.log('start loading dummy waveformCache from assets');
    this.http.get('./assets/testWaveformCache/data1.json').subscribe((data: WaveformCacheI) => {
      console.log('dummy Waveformcache loaded:' + data);
      this.setCacheInfo1(new WaveformCache(data));
    });
  }

  movePos100ms() {
    this.playerPositionMs += 200;
    this.updateSegmentsPosition();
  }

  moveNeg100ms() {
    this.playerPositionMs -= 200;
    this.updateSegmentsPosition();
  }

  // initialise segments, set initial positions and draw on internalCanvases of segments, finally copy to p1Canvas
  initSegments(data: WaveformCache): void {
    this.clearSegments();

    /* #region START OF SEGMENTMODEL CREATION FOR LOOP */
    for (let i = 0; i < this.segmentCount; i++) {
      // create new segment
      const segmentModel = new WaveformSegmentModel(this.segmentWidth, this.canvasHeight / 2, this);
      // paint segments with data
      // console.log("initsegment -> waveformCache: ", JSON.stringify(data));
      segmentModel._waveformCache$.next(data);
      segmentModel.idNumber = i;

      // set initial position by lineing segments up in main cache
      const mainCanvasSegmentPosPx = i * this.segmentWidth;
      // segments position in song in ms
      const segmentPosMs = (mainCanvasSegmentPosPx - this.playPosPx) * this.msPxRatio + this.playerPositionMs;
      segmentModel.setXPositionMs(segmentPosMs);
      console.log('set segment %s to position: %s', i, segmentModel.getXPositionMs());

      // add segment to pool. note that not all elements in the pool are included in the dom at all times
      this.segmentsPool.push(segmentModel); // look at drawOrPositionSegment() regarding segment initialization
    }
    /* #region END OF SEGMENTMODEL CREATION FOR LOOP */

    this.updateSegmentsPosition();
  }

  /**
   * clears waveform segments list, should usually only happen if a song is unloaded
   */
  clearSegments() {
    this.segmentsPool = [];
    this.displaySegmentListObservable$.next([]);
  }

  /**
   * this will reposition the segments on the canvas (to xPosPx in Canvas)
   * then the given Model will be included in displaySegmentListObservable$ if it is not already included
   *
   * this will lead to the creation of a waveformSegmentComponent via ng-for
   */
  repositionSegmentModelAndIncludeInList(
    // TODO: RENAME TO SOMETHING BETTER
    segmentModel: WaveformSegmentModel,
    xPosPx: number,
    yPosPx?: number
  ) {
    if (!yPosPx) {
      yPosPx = 0;
    }
    segmentModel.segmentPositionCanvasPx = xPosPx;
    // add model -> dom element will be created
    const list: WaveformSegmentModel[] = this.displaySegmentListObservable$.getValue();
    if (!list.includes(segmentModel)) {
      list.push(segmentModel);
    }
    this.displaySegmentListObservable$.next(list);
  }

  /**
   * repositions segments in dom via change of segmentModels
   * segments are repainted inside waveform-segment-component if their xPositionMs changes (that is to say they get used for a different part in the song)
   *
   * segmentModels and segments should get discarded and new ones created when a different cache is loaded
   */
  updateSegmentsPosition() {
    console.log('called updateSegmentsPosition()');

    // declare some vars for use below
    const invalidSegments: WaveformSegmentModel[] = new Array<WaveformSegmentModel>();
    let lastValidSegmentEndMs = 0;
    let lastValidSegmentPosPx: number, firstValidSegmentPosPx: number;
    let firstValidSegmentStartMs: number = Number.MAX_VALUE;

    // set segments at correct position dependant on current play position
    // change segment position
    for (const segmentModel of this.segmentsPool) {
      const recalcedMainCanvasSegmentPosPx = (segmentModel.getXPositionMs() - this.playerPositionMs) / this.msPxRatio + this.playPosPx;

      // segments exit on left side -> invalidate
      if (!this.isValidSegmentPos(recalcedMainCanvasSegmentPosPx)) {
        invalidSegments.push(segmentModel);
      } else {
        //if the segmetns did not go out of frame on the right or the left (plus buffer)
        //  -> reposition it to the newly calculated px position
        this.repositionSegmentModelAndIncludeInList(segmentModel, recalcedMainCanvasSegmentPosPx, 0);

        //OK soo far we have positioned still valid segments according to where we are in the song
        // we have also added all invalid segments (outside the current to-display area as invalid) to a list

        // now depending on the change in play position we might have to fill some gaps on the left or right side where
        //new segments should show up

        // cache most left and most right valid segment.maxX in ms
        //this way we know where to append new segments
        const endMs = segmentModel.getXPositionMs() + this.segmentWidth * this.msPxRatio;
        if (lastValidSegmentEndMs < endMs) {
          // right side
          lastValidSegmentEndMs = endMs;
          lastValidSegmentPosPx = recalcedMainCanvasSegmentPosPx;
        }
        if (firstValidSegmentStartMs > segmentModel.getXPositionMs()) {
          // left side
          firstValidSegmentStartMs = segmentModel.getXPositionMs();
          firstValidSegmentPosPx = recalcedMainCanvasSegmentPosPx;
        }
      }
    }


    // instead of creating entirely new segment instances we are going to reuse the invalidated segments
    // fillReuseSegments(invalidSegments); // todo: move some code to such an function

    
    // IN THIS NEXT SECTION WE WILL REASSIGN EXISTING INVALID SEGMENTS TO REPRESENT NEW POSITIONS IN THE SONG 
    // THIS ONLY HAPPENDS IF THERE IS SOME FREE ROOM TO FILL LEFT ON THE LEFT OR RIGHT SIDE OF THE ALREADY EXISTING SEGMENTS

    // with this while loop we keep appending segments until there is no free space left or we run out of segments to reuse
    while (this.isRightSideFreeSpace(lastValidSegmentPosPx) && invalidSegments.length > 0) {
      const segmentModel = invalidSegments.pop();
      // segments position in song in ms
      const newPosMs = lastValidSegmentEndMs;
      
      // recalculate where the new segment should be placed in px
      const recalcedMainCanvasSegmentPosPx = (newPosMs - this.playerPositionMs) / this.msPxRatio + this.playPosPx;
      console.log('newPos for reused segment: %s ms   -> pos in canvasPx: %s', newPosMs, recalcedMainCanvasSegmentPosPx);

      // tell the segmentModel that it now represents a different part in the song !!!THIS WILL REPAINT THE SEGMENT!!!
      segmentModel.setXPositionMs(newPosMs);

      // this will add a component equlvaent to the model to the dom at the correct pixel position
      this.repositionSegmentModelAndIncludeInList(segmentModel, recalcedMainCanvasSegmentPosPx, 0);
      lastValidSegmentEndMs = lastValidSegmentEndMs + (this.segmentWidth * this.msPxRatio);
    }


    // now do the same thing we did above for the left side
    while (this.isLeftSideFreeSpace(firstValidSegmentPosPx) && invalidSegments.length > 0) {
      const segment = invalidSegments.pop();
      // segments position in song in ms
      const newPosMs = firstValidSegmentStartMs - /*i**/ this.segmentWidth * this.msPxRatio;
      // only for debugging
      const recalcedMainCanvasSegmentPosPx = (newPosMs - this.playerPositionMs) / this.msPxRatio + this.playPosPx;
      console.log('newPos for reused segment: %s ms   -> pos in canvasPx: %s', newPosMs, recalcedMainCanvasSegmentPosPx);
      segment.setXPositionMs(newPosMs);

      // draw segment on main canvas
      this.repositionSegmentModelAndIncludeInList(segment, recalcedMainCanvasSegmentPosPx, 0);
      firstValidSegmentStartMs = firstValidSegmentStartMs - (this.segmentWidth * this.msPxRatio);
    }
  }

  /** returns if a potential segment positioned at the provided position is valid or not
   * @param canvasPxPos some position in canvas pixel coordinate system (x)
   */
  private isValidSegmentPos(canvasPxPos: number): boolean {
    // left side invalid
    const leftSideValid: boolean = canvasPxPos + this.segmentWidth + this.leftMaxPrerendered * this.segmentWidth > 0;
    const rightSideValid: boolean = canvasPxPos < this.canvasWidth + this.rightMaxPrerendered * this.segmentWidth;

    return leftSideValid && rightSideValid;
  }

  private isRightSideFreeSpace(lastValidSegmentPosPx: number): boolean {
    return lastValidSegmentPosPx < this.canvasWidth + (this.rightMaxPrerendered - 1) * this.segmentWidth;
  }

  private isLeftSideFreeSpace(firstValidSegmentPosPx: number): boolean {
    return firstValidSegmentPosPx + this.leftMaxPrerendered * this.segmentWidth > 0;
  }
}
