<div>
  <h1>Dual Waveform</h1>
  <ion-button (click)="movePos100ms()">move +200ms</ion-button>
  <ion-button (click)="moveNeg100ms()">move -200ms</ion-button>
  <ion-button (click)="handlePlay()">play</ion-button>
  <ion-button (click)="handlePause()">pause</ion-button>
  <div>
    <canvas #bgCanvas class="bg-canvas" width="1024"
    height="200"></canvas>

    <div id="waveformSegmentContainer" class="waveform-segment-container"  width="1024"
    height="200">
      <!-- <ion-button *ngFor="let x of this.displaySegmentList" text="x.idNumber"></ion-button> -->
      <waveform-segment 
      class="waveform-segment" 
      *ngFor="let x of this.displaySegmentListObservable$ | async"
      [width]="x.segmentWidth"
      [height]="x.segmentHeight"
      [segmentPositionCanvasPx]="x.segmentPositionCanvasPx"
      [xPositionMs]="x.xPositionMs"
      [waveformCache]="x._waveformCache$.value"
      ></waveform-segment>
      <!-- [ngStyle]="{ transform: x.segmentPositionCanvasPx() }" -->
      <!-- [segmentPositionCanvasPx]="x.segmentPositionCanvasPx" -->

    </div>
    <div style="position:absolute; display:flex; flex-direction: column;">
      <!-- ADD segments for p1 here dynamically, then just move them around-->
      <!-- nothing painted on p1 in new vairant, but viewchild used for width && height for now so ndont comment out -->
      <canvas id="p1Canvas" #p1Canvas width="1024" height="100"></canvas>
      <canvas  id="p2Canvas" #p2Canvas width="1024" height="100"></canvas>
    </div>
    <canvas
      id="fgCanvas"
      #fgCanvas
      width="1024"
      height="200"
      style="box-shadow:1px 1px 1px 1px black inset;position:absolute;"
    ></canvas>
  </div>
</div>
