import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-performance-test',
  templateUrl: './performance-test.component.html',
  styleUrls: ['./performance-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceTestComponent implements OnInit {
  time$ = new BehaviorSubject('00:00');
  constructor(private ngZone: NgZone) {
    (window as any).setTime = (time: string) => {
      this.ngZone.run(() => {
        this.time$.next(time);
      });
    };
  }

  ngOnInit() {}

  handleTest() {
    // Results in a call to the OnQuery method in binding_test.cpp
    (window as any).cefQuery({
      // request: 'MyJavaClass::myMethod::Tobi',
      request: 'MyJavaClass::play::Tobi',
      onSuccess: (response) => {
        // alert('Java Response: ' + response);
      },
      onFailure: (error_code, error_message) => {
        alert(error_code);
      },
    });
  }
}
