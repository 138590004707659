import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { StarRatingModule } from 'angular-star-rating';
import { RatingModule } from 'primeng/rating';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ComponentsModule } from '../../components/components.module';
import { GroupsBrowserMobileModule } from '../groups-browser-mobile/groups-browser-mobile.module';
import { WidgetsModule } from '../../main/widgets/widgets.module';
import { SharedUiModule } from '../../shared-ui/shared-ui.module';
import { ChartsListComponent } from './charts-list.component';
import { createCustomElement, NgElementConfig } from '@angular/elements';
import { ChartsStore } from './component-reducer/charts.reducer';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    TreeModule,
    ContextMenuModule,
    GroupsBrowserMobileModule,
    TableModule,
    IonicModule,
    WidgetsModule,
    StarRatingModule,
    SharedUiModule,
    RatingModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
  ],
  declarations: [
    ChartsListComponent
  ],
  exports: [
    ChartsListComponent
  ]
})
export class ChartsListModule {
  constructor(private injector: Injector) {
    // Stellen Sie sicher, dass die Definition nur einmal erfolgt
    if (!customElements.get('um-charts')) {
      const el = createCustomElement(ChartsListComponent, { injector: this.injector });
      customElements.define('um-charts', el);
    }
  }

  ngDoBootstrap() { }
}
