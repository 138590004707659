import { Component, OnInit } from '@angular/core';

// import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { MidiSoundControlService } from './services/midi-sound-control.service';
import { MidiService } from './services/midi.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private platform: Platform, private midiService: MidiService, private midiAudioControlService: MidiSoundControlService) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
//      StatusBar.setStyle({ style: Style.Dark });
    });
  }

  ngOnInit(): void {}
}
