import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
// import { ResizeObserver } from '@juggle/resize-observer';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { getTime, isPlaying } from '../../../core/redux/reducers/player.reducer';
import { WaveformCache } from '../WaveformCache';
import { WaveformCacheI } from '../WaveformCacheI';
import { WaveformSegment } from '../waveform-segment';

@Component({
  selector: 'dual-waveform-v1',
  templateUrl: './dual-waveform-v1.component.html',
  styleUrls: ['./dual-waveform-v1.component.scss'],
})

/**
 * DEPRECATED -> use DualWaveformComponent (dual-waveform.component) instead
 * This waveform implementation uses a single canvas per song to render the waveform in the dom.
 * {@link WaveformSegment}s are simple TS-classes (NOT components) which hold a canvas instance that is never
 * included in the dom but used for background drawing.
 * When the player position (ms) changes that content of the segment-canvases is simple copied onto the main canvas
 * in the respective position.
 *
 * This implementation does support segment reuse/wrapping.
 * This implementation support drag-scrolling.
 *
 * The data is loaded from a WaveformCache json @see loadWaveformCache() and {@link WaveformCache}
 */
export class DualWaveformComponentv1Component implements OnInit, AfterViewInit {
  // background canvas
  @ViewChild('bgCanvas', { static: false }) bgCanvas: ElementRef;

  // forground canvas
  @ViewChild('fgCanvas', { static: false }) fgCanvas: ElementRef;

  @ViewChild('p1Canvas', { static: false }) p1Canvas: ElementRef;
  @ViewChild('p2Canvas', { static: false }) p2Canvas: ElementRef;

  @ViewChild('waveformContainer', { static: false }) waveformContainer: ElementRef;

  _cacheInfo1: WaveformCache = null;
  _cacheInfo2: WaveformCache = null;

  @Input() debugMode = true;
  mainCtx1: CanvasRenderingContext2D;
  mainCtx2: CanvasRenderingContext2D;

  @Input('data1') set cacheInfo1(data: WaveformCache) {
    this._cacheInfo1 = data;
    this.initSegments(this._cacheInfo1, this.mainCtx1);
  }

  get cacheInfo1() {
    return this._cacheInfo1;
  }

  @Input('data2') set cacheInfo2(data: WaveformCache) {
    this._cacheInfo2 = data;
    this.initSegments(this._cacheInfo2, this.mainCtx2);
  }

  get cacheInfo2() {
    return this._cacheInfo2;
  }

  public waveformWidth$ = new BehaviorSubject(800);
  public waveformHeight$ = new BehaviorSubject(140);

  // for now canvas 1024
  segmentCount = 14; // segment count per playerlist
  segmentWidth = 100; // 512;

  private readonly RESIZE_DEBOUNCE_DELAY = 250; // ms

  isPlaying1$: Observable<boolean> = this.store.select(isPlaying(0));
  isPlaying2$: Observable<boolean> = this.store.select(isPlaying(1));

  time1$: Observable<number> = this.store.select(getTime(0));
  time2$: Observable<number> = this.store.select(getTime(1));

  // maximum number prerendered segments on the left/right side outside of the main canvas waveform
  rightMaxPrerendered = 2;
  leftMaxPrerendered = 0;

  // zoom == millisecond to pixel ratio, this is later controlled by user, for now hardset to value for cache since it doubles FmodMock
  msPxRatio = 14.6069; // TODO: this needs to be dynamically calculated depending on zoom level of waveform  // thriller: ~ 580.0;

  // current play position in ms, should later be aquired from player
  playerPositionMs = 0;
  // position of play-line on main canvas, length of canvas is irrelevant, only playpos relative to canvas-start (0) matters
  private playPosPx = 200; // will be changed in ngOnInit() to middle of canvas

  // cached segments - this will be of constant size once segments are initialized since no segments get added or deleted; just reused
  // filled in this.initSegments()
  segments: WaveformSegment[] = new Array();

  // mainCtx: CanvasRenderingContext2D;

  // cache for dragscrolling waveform, since only one songs waveform can be scrolled at a time this might be used both for p1 and p2
  dragStart: number;

  private paused = true;
  private playSub: Subscription;

  constructor(private store: Store, private http: HttpClient /*,private navParams: NavParams*/) {
    this.isPlaying1$.subscribe((isPlaying) => {
      if (isPlaying) {
        this.handlePlay(); // does nothing right now, play is handled via time only for now
      } else {
        this.handlePause(); // does nothing right now, play is handled via time only for now
      }
    });

    this.time1$.subscribe((time) => {
      this.playerPositionMs = time;
      this.drawWaveform(this.mainCtx1);
    });
    this.time2$.subscribe((time) => {
      this.playerPositionMs = time;
      this.drawWaveform(this.mainCtx2);
    });

    this.isPlaying2$.subscribe((isPlaying) => {
      if (isPlaying) {
        this.handlePlay();
      } else {
        this.handlePause();
      }
    });
  }

  ngOnInit() {}

  loadDummyWaveformCache() {
    console.log('start loading dummy waveformCache from assets');
    this.http.get('./assets/testWaveformCache/data1.json').subscribe((data: WaveformCacheI) => {
      console.log('dummy Waveformcache loaded:' + data);
      this.cacheInfo1 = new WaveformCache(data);
    });
  }

  ngAfterViewInit(): void {
    this.mainCtx1 = (this.p1Canvas.nativeElement as HTMLCanvasElement).getContext('2d');
    this.mainCtx2 = (this.p2Canvas.nativeElement as HTMLCanvasElement).getContext('2d');

    // TODO: add debounce again!
    const func = (entries) => {
      for (const entry of entries) {
        if (entry.target === this.waveformContainer.nativeElement) {
          this.onResize(entry.contentRect.width, entry.contentRect.height);
        }
        if (entry.target === this.p1Canvas.nativeElement) {
          this.onCanvasResize();
        }
      }
    }; // debounce, this.RESIZE_DEBOUNCE_DELAY);

    const ro = new ResizeObserver(func);
    ro.observe(this.waveformContainer.nativeElement);
    ro.observe(this.p1Canvas.nativeElement);

    if (this.debugMode) {
      // this.loadDummyWaveformCache();
    }
  }

  private onCanvasResize() {
    this.redrawBgCanvas();
    this.redrawFgCanvas();
    this.drawWaveform(this.mainCtx1);
    this.drawWaveform(this.mainCtx2);
  }

  private onResize(width: number, height: number) {
    this.playPosPx = width / 2;
    this.waveformWidth$.next(width);
    this.waveformHeight$.next(height);
  }

  @HostListener('mousedown', ['$event'])
  mouseDown(event: MouseEvent) {
    // window['mouseevent'] = event;
    // window['canvasp1'] = this.p1Canvas;
    // console.log('canvasp1' , this.p1Canvas);

    if ((event.target as HTMLElement).id === 'p1Canvas') {
      // console.log('mousedown on p1Canvas -> remember position %s', event.screenX);
      this.dragStart = event.screenX;
      // todo: stop player before dragging, resume on drop if was playing before
    }
  }

  @HostListener('mouseup', ['$event'])
  mouseUp(event: MouseEvent) {
    // console.log("mouseupEventP1", event);
    this.dragStart = null;
  }

  @HostListener('mousemove', ['$event'])
  mouseMove(event: MouseEvent) {
    if (this.dragStart) {
      const deltaX = event.screenX - this.dragStart;
      // console.log('mouse up and was dragging -> move delta px %s',deltaX);
      const deltaPlayPosMs = deltaX * this.msPxRatio * -1;
      this.playerPositionMs += deltaPlayPosMs;
      this.drawWaveform(this.mainCtx1); // todo: trigger this by playerPos value change
      this.drawWaveform(this.mainCtx2); // todo: trigger this by playerPos value change
      this.dragStart = event.screenX;
    }
  }

  /*onMouseDownP1(event)
  {
    console.log("mousedownEventP1",event);
  }*/

  dummyFillP1P2Canvas() {
    const p1Canvas: HTMLCanvasElement = this.p1Canvas.nativeElement;
    const p1Ctx: CanvasRenderingContext2D = p1Canvas.getContext('2d');

    p1Ctx.lineWidth = 2;
    p1Ctx.strokeStyle = 'red';
    p1Ctx.beginPath();
    p1Ctx.moveTo(0, 0);
    p1Ctx.lineTo(this.waveformWidth$.getValue(), p1Canvas.height);
    p1Ctx.stroke();

    const p2Canvas: HTMLCanvasElement = this.p2Canvas.nativeElement;
    const p2Ctx: CanvasRenderingContext2D = p2Canvas.getContext('2d');

    p2Ctx.lineWidth = 2;
    p2Ctx.strokeStyle = 'red';
    p2Ctx.beginPath();
    p2Ctx.moveTo(0, 0);
    p2Ctx.lineTo(p2Canvas.width, p2Canvas.height);
    p2Ctx.stroke();
  }

  redrawFgCanvas() {
    const fgCanvas: HTMLCanvasElement = this.fgCanvas.nativeElement;
    const fgCtx: CanvasRenderingContext2D = fgCanvas.getContext('2d');
    fgCtx.strokeStyle = 'gold';
    // fgCtx.strokeRect(0, fgCanvas.height / 2 + 1, fgCanvas.width, 0);
    fgCtx.moveTo(0, fgCanvas.height / 2 + 1);
    fgCtx.lineTo(fgCanvas.width, fgCanvas.height / 2 + 1);
    fgCtx.stroke();

    fgCtx.strokeStyle = 'white';
    fgCtx.strokeRect(0, 0, fgCanvas.width / 2 + 1, fgCanvas.height);
    fgCtx.stroke();
  }

  redrawBgCanvas() {
    const bgCanvas: HTMLCanvasElement = this.bgCanvas.nativeElement;
    const bgCtx: CanvasRenderingContext2D = bgCanvas.getContext('2d');
    bgCtx.fillStyle = 'black';
    bgCtx.fillRect(0, 0, bgCanvas.width, bgCanvas.height);
    //  bgCtx.fill();
  }

  movePos100ms() {
    this.playerPositionMs += 200;
    this.drawWaveform(this.mainCtx1);
    this.drawWaveform(this.mainCtx2);
  }

  moveNeg100ms() {
    this.playerPositionMs -= 200;
    this.drawWaveform(this.mainCtx1);
    this.drawWaveform(this.mainCtx2);
  }

  handlePlay() {
    /*if(this.debugMode)
    {
      this.store.dispatch(new PlayAction({ playerID: 0}));
    }*/

    if (this.debugMode) {
      this.paused = false;
      if (!this.playSub) {
        this.playSub = timer(0, 8)
          .pipe(
            tap(() => {
              if (!this.paused) {
                this.playerPositionMs += 8;
                this.drawWaveform(this.mainCtx1);
                this.drawWaveform(this.mainCtx2);
              }
            })
          )
          .subscribe();
      }
    }
  }

  handlePause() {
    if (this.debugMode) {
      this.paused = true;
    }
  }

  // initialise segments, set initial positions and draw on internalCanvases of segments, finally copy to p1Canvas
  initSegments(cacheInfo: WaveformCache, mainCtx: CanvasRenderingContext2D): void {
    if (!cacheInfo) {
      return;
    }

    this.segments = new Array();

    for (let i = 0; i < this.segmentCount; i++) {
      // create new segment
      const segment = new WaveformSegment(this.segmentWidth, this.waveformHeight$.getValue() / 2, this);

      // set initial position of segment
      // todo: do this in ms instead of pixels
      // set initial position by linging segments up in main cache
      const mainCanvasSegmentPosPx = i * this.segmentWidth;
      // segments position in song in ms
      const segmentPosMs = (mainCanvasSegmentPosPx - this.playPosPx) * this.msPxRatio + this.playerPositionMs;
      segment.setXPositionMs(segmentPosMs);
      // console.log('set segment %s to position: %s', i, segment.getXPositionMs());

      // paint segments with data
      segment.repaint(cacheInfo);

      // copy segment canvas content to p1Canvas in segment positions
      // let recalcedMainCanvasSegmentPosPx = (segment.getXPositionMs() - this.playerPositionMs)/this.msPxRatio + this.playPosPx;
      // this.mainCtx.drawImage(segment.getCanvas(), recalcedMainCanvasSegmentPosPx, 0);

      this.segments.push(segment);
    }

    this.drawWaveform(mainCtx);
  }

  /**
   * does NOT redraw segments, simply redraws the main canvas from all the already existing segments
   * according to current play position
   */
  drawWaveform(mainCtx: CanvasRenderingContext2D) {
    //  console.log('drawWaveform');
    // todo: clear rect
    if (!mainCtx) {
      return;
    }

    mainCtx.clearRect(0, 0, this.waveformWidth$.getValue(), this.waveformHeight$.getValue() / 1);
    // this.mainCtx.fillRect(0, 0, this.waveformWidth$.getValue(), this.waveformHeight$.getValue() / 1);

    const invalidSegments: Array<WaveformSegment> = new Array<WaveformSegment>();
    let lastValidSegmentEndMs = 0;
    let lastValidSegmentPosPx: number, firstValidSegmentPosPx: number;
    let firstValidSegmentStartMs: number = Number.MAX_VALUE;

    // paint segments at correct position dependant on current play position
    for (const segment of this.segments) {
      const recalcedMainCanvasSegmentPosPx = (segment.getXPositionMs() - this.playerPositionMs) / this.msPxRatio + this.playPosPx;

      // segments exit on left side -> invalidate
      if (!this.isValidSegmentPos(recalcedMainCanvasSegmentPosPx)) {
        invalidSegments.push(segment);
      } else {
        // DRAW SEGMENTS ON MAIN CANVAS !!!
        mainCtx.drawImage(segment.getCanvas(), recalcedMainCanvasSegmentPosPx, 0);

        // cache most left and most right valid segment.maxX in ms
        const endMs = segment.getXPositionMs() + this.segmentWidth * this.msPxRatio;
        if (lastValidSegmentEndMs < endMs) {
          // right side
          lastValidSegmentEndMs = endMs;
          lastValidSegmentPosPx = recalcedMainCanvasSegmentPosPx;
        }
        if (firstValidSegmentStartMs > segment.getXPositionMs()) {
          // left side
          firstValidSegmentStartMs = segment.getXPositionMs();
          firstValidSegmentPosPx = recalcedMainCanvasSegmentPosPx;
        }
      }
    }

    // reuse invalidated/scrapped segments (render on right side)
    // fillReuseSegments(invalidSegments); // todo: move some code to such a function
    // todo: redraw reused segments this update already, not the next
    if (this.isRightSideFreeSpace(lastValidSegmentPosPx) && invalidSegments.length > 0) {
      // todo: under some circumstances multiple segments need to be refilled -> implement
      const segment = invalidSegments.pop();
      // segments position in song in ms
      const newPosMs = lastValidSegmentEndMs; /* + i*this.segmentWidth * this.msPxRatio*/
      // only for debugging
      const recalcedMainCanvasSegmentPosPx = (newPosMs - this.playerPositionMs) / this.msPxRatio + this.playPosPx;
      console.log('newPos for reused segment: %s ms   -> pos in canvasPx: %s', newPosMs, recalcedMainCanvasSegmentPosPx);
      segment.setXPositionMs(newPosMs);

      // repaint segment content to reflect new position
      segment.repaint(this.cacheInfo1);

      // draw segment on main canvas
      mainCtx.drawImage(segment.getCanvas(), recalcedMainCanvasSegmentPosPx, 0);
    }

    if (this.isLeftSideFreeSpace(firstValidSegmentPosPx) && invalidSegments.length > 0) {
      // todo: under some circumstances multiple segments need to be refilled -> implement
      const segment = invalidSegments.pop();
      // segments position in song in ms
      const newPosMs = firstValidSegmentStartMs - /*i**/ this.segmentWidth * this.msPxRatio;
      // only for debugging
      const recalcedMainCanvasSegmentPosPx = (newPosMs - this.playerPositionMs) / this.msPxRatio + this.playPosPx;
      console.log('newPos for reused segment: %s ms   -> pos in canvasPx: %s', newPosMs, recalcedMainCanvasSegmentPosPx);
      segment.setXPositionMs(newPosMs);

      // repaint segment content to reflect new position
      segment.repaint(this.cacheInfo1);

      // draw segment on main canvas
      mainCtx.drawImage(segment.getCanvas(), recalcedMainCanvasSegmentPosPx, 0);
    }
  }

  /** returns if a potential segment positioned at the provided position is valid or not
   * @param canvasPxPos some position in canvas pixel coordinate system (x)
   */
  private isValidSegmentPos(canvasPxPos: number): boolean {
    // left side invalid
    const leftSideValid: boolean = canvasPxPos + this.segmentWidth + this.leftMaxPrerendered * this.segmentWidth > 0;
    const rightSideValid: boolean = canvasPxPos < this.waveformWidth$.getValue() + this.rightMaxPrerendered * this.segmentWidth;

    return leftSideValid && rightSideValid;
  }

  private isRightSideFreeSpace(lastValidSegmentPosPx: number): boolean {
    return lastValidSegmentPosPx < this.waveformWidth$.getValue() + (this.rightMaxPrerendered - 1) * this.segmentWidth;
  }

  private isLeftSideFreeSpace(firstValidSegmentPosPx: number): boolean {
    return firstValidSegmentPosPx + this.leftMaxPrerendered * this.segmentWidth > 0;
  }
}
