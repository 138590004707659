import {Injectable} from '@angular/core';
import {ActionTrigger} from './action-trigger';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActionTriggerGroup} from './action-trigger-group';

@Injectable({
    providedIn: 'root'
})
export class ActionTriggerService {

    constructor(private http: HttpClient) {
    }

    public getActionTriggers(): Observable<ActionTrigger[]> {
        return this.http.get<ActionTrigger[]>('./assets/json/action-triggers.json');
    }

    public getActions(): Observable<ActionTriggerGroup[]> {
        return this.http.get<ActionTriggerGroup[]>('./assets/json/action-trigger-actions.json');

    }

    addNewTrigger() {

    }
}
