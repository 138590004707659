import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AudioService } from '../../../services/audio.service';
import {
  crossfaderAction,
  killEqHighAction,
  killEqLowAction,
  killEqMidAction,
  setEqHighAction,
  setEqLowAction,
  setEqMidAction,
} from '../actions/mixer.actions';
import { setVolumeAction } from './../actions/player.actions';
@Injectable()
export class MixerEffects {
  public static timeRate = 55;

  private timerSubs: Subscription[] = [];

  constructor(private actions$: Actions, private audioService: AudioService) {}

  crossfaderAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(crossfaderAction),
        tap((action) => {
          this.audioService.setCrossfader(action.value);
        })
      ),
    { dispatch: false }
  );

  volume$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setVolumeAction),
        map((action) => {
          this.audioService.setVolume(action.playerID, action.volume);
        })
      ),
    { dispatch: false }
  );

  setEqHigh$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setEqHighAction),
        map((action) => {
          this.audioService.setEQHigh(action.playerID, action.value);
        })
      ),
    { dispatch: false }
  );

  setEqMid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setEqMidAction),
        map((action) => {
          this.audioService.setEQMid(action.playerID, action.value);
        })
      ),
    { dispatch: false }
  );

  setEqLow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setEqLowAction),
        map((action) => {
          this.audioService.setEQLow(action.playerID, action.value);
        })
      ),
    { dispatch: false }
  );

  killEqHigh$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(killEqHighAction),
        map((action) => {
          this.audioService.killEqHigh(action.playerID, action.kill);
        })
      ),
    { dispatch: false }
  );

  killEqMid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(killEqMidAction),
        map((action) => {
          this.audioService.killEqMid(action.playerID, action.kill);
        })
      ),
    { dispatch: false }
  );

  killEqLow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(killEqLowAction),
        map((action) => {
          this.audioService.killEqLow(action.playerID, action.kill);
        })
      ),
    { dispatch: false }
  );
}
