<div class="login-card">
    <div class="logo">
        <img src="./assets/img/ultramixer-company-logo-277x74.png">
    </div>
    <div class="login-form">
        <h1>login to mix.</h1>

        <ion-item>
            <ion-label position="floating">Username</ion-label>
            <ion-input></ion-input>
        </ion-item>
        <ion-item>
            <ion-label position="floating">Password</ion-label>
            <ion-input></ion-input>
        </ion-item>


        <ion-button class="login-button" expand="block" (click)="handleLogin()">
            <ng-container *ngIf="!(loggingIn$| async)">Login</ng-container>
            <ion-spinner *ngIf="(loggingIn$| async)"></ion-spinner>
        </ion-button>
    </div>
</div>