import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { Track } from '../../../music-archive/track';
import { Group } from '../../../services/group';
import { ActionWithPayload } from './action.interface';

const ACTION_TYPE_ARCHIVE = '[Archive]';

export const loadTracksAction = createAction(
  `${ACTION_TYPE_ARCHIVE} Load tracks`,
  props<{ groupID: string }>()
);
export const loadTracksSucceededAction = createAction(
  `${ACTION_TYPE_ARCHIVE} Load tracks succeeded`,
  props<{ tracks: Track[] }>()
);

export const loadGroupsAction = createAction(`${ACTION_TYPE_ARCHIVE} Load groups`);
export const loadGroupsSucceededAction = createAction(
  `${ACTION_TYPE_ARCHIVE} Load groups succeeded`,
  props<{ groups: TreeNode[] }>()
);
export const loadGroupsFailedAction = createAction(
  `${ACTION_TYPE_ARCHIVE} Load groups failed`,
  props<{ error: HttpErrorResponse }>()
);

export class ArchiveActions {
  static LOAD_TRACKS_FAILED = `${ACTION_TYPE_ARCHIVE} Load tracks failed`;

  static ADD_DIRECTORY = `${ACTION_TYPE_ARCHIVE} Add directory`;
  static ADD_DIRECTORY_SUCCESSFUL = `${ACTION_TYPE_ARCHIVE} Add directory successful`;
  static ADD_DIRECTORY_FAILED = `${ACTION_TYPE_ARCHIVE} Add directory failed`;

  static REMOVE_GROUP = `${ACTION_TYPE_ARCHIVE} Remove group`;
  static REMOVE_GROUP_SUCCESSFUL = `${ACTION_TYPE_ARCHIVE} Remove group successful`;
  static REMOVE_GROUP_FAILED = `${ACTION_TYPE_ARCHIVE} Remove group failed`;

  static GROUP_CHANGED = `${ACTION_TYPE_ARCHIVE} Group has changed`;

  static RENAME_GROUP = `${ACTION_TYPE_ARCHIVE} Rename group`;
  static RENAME_GROUP_SUCCESSFUL = `${ACTION_TYPE_ARCHIVE} Rename group successful`;
  static RENAME_GROUP_FAILED = `${ACTION_TYPE_ARCHIVE} Rename group failed`;

  static ADD_GROUP = `${ACTION_TYPE_ARCHIVE} Add group`;
  static ADD_GROUP_SUCCESSFUL = `${ACTION_TYPE_ARCHIVE} Add group successful`;
  static ADD_GROUP_FAILED = `${ACTION_TYPE_ARCHIVE} Add group failed`;
}

export class LoadTracksFailedAction implements ActionWithPayload {
  readonly type = ArchiveActions.LOAD_TRACKS_FAILED;

  constructor(public payload: { groupID: string; error: HttpErrorResponse }) { }
}

export class AddDirectoryAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_DIRECTORY;

  constructor(public payload: { dir: File }) { }
}

export class AddDirectorySuccessfulAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_DIRECTORY_SUCCESSFUL;

  constructor() { }
}

export class AddDirectoryFailedAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_DIRECTORY_FAILED;

  constructor() { }
}

export class RemoveGroupAction implements ActionWithPayload {
  readonly type = ArchiveActions.REMOVE_GROUP;

  constructor(public payload: { group: TreeNode }) { }
}

export class RemoveGroupSuccessfulAction implements ActionWithPayload {
  readonly type = ArchiveActions.REMOVE_GROUP_SUCCESSFUL;

  constructor(public payload: { group: TreeNode }) { }
}

export class GroupChangedAction implements ActionWithPayload {
  readonly type = ArchiveActions.GROUP_CHANGED;

  constructor(public payload: { group: Group }) { }
}

export class RenameGroupAction implements ActionWithPayload {
  readonly type = ArchiveActions.RENAME_GROUP;

  constructor(public payload: { group: TreeNode; newName: string }) { }
}

export class RenameGroupSuccessfulAction implements ActionWithPayload {
  readonly type = ArchiveActions.RENAME_GROUP_SUCCESSFUL;

  constructor(public payload: { group: TreeNode }) { }
}

export class AddGroupAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_GROUP;

  constructor(public payload: { groupName: string }) { }
}

export class AddGroupSuccessfulAction implements ActionWithPayload {
  readonly type = ArchiveActions.ADD_GROUP_SUCCESSFUL;

  constructor(public payload: { group: TreeNode }) { }
}
