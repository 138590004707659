import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { Observable, map } from 'rxjs';
import { groups } from '../../core/redux/reducers/archive.reducer';
import { TrackListMobileComponent } from '../track-list-mobile/track-list-mobile.component';
import { loadGroupsAction } from '../../core/redux/actions/archive.actions';

@Component({
  selector: 'app-groups-browser-mobile',
  templateUrl: './groups-browser-mobile.component.html',
  styleUrls: ['./groups-browser-mobile.component.scss'],
})
export class GroupsBrowserMobileComponent implements OnInit, AfterViewInit {
  groups$: Observable<TreeNode[]> = this.store.select(groups).pipe(map((groups: TreeNode[]) => structuredClone(groups)));

  @Output() selectedGroup = new EventEmitter<TreeNode>();

  constructor(private store: Store) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.store.dispatch(loadGroupsAction());
  }

  handleLoadTracks(group: TreeNode) {
    const nav = document.querySelector('ion-nav');
    nav.push(TrackListMobileComponent, { groupID: group.key });
  }
}
