import {HIDDeviceInfo} from './HIDDeviceInfo';

export class HIDManager {
    private hidManagerJavaObj: any;

    constructor(hidManagerJavaObj: any) {
        this.hidManagerJavaObj = hidManagerJavaObj;
    }

    listDevices(): HIDDeviceInfo[] {
        const outArray: HIDDeviceInfo[] = [];

        // get javaObj
        const javaObjDeviceInfoArray = this.hidManagerJavaObj.listDevices();
        // now iterate over java array and wrap ever device info in javascript HIDDeviceInfo wrapper instace
        const length: number = javaObjDeviceInfoArray.length();
        for (let i = 0; i < length; i++) {
            outArray.push(new HIDDeviceInfo(javaObjDeviceInfoArray[i]));
        }

        // DOES NOT WÖRK!!!
        /*for (const hidDeviceInfo of javaObjDeviceInfoArray) {
            outArray.push(new HIDDeviceInfo(hidDeviceInfo));
        }*/

        return outArray;
    }
}
