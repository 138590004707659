export class HIDDevice {
    private HIDDeviceJavaObj;

    constructor(HIDDeviceJavaObj) {
        this.HIDDeviceJavaObj = HIDDeviceJavaObj;
    }

    close(): void {
        this.HIDDeviceJavaObj.close();
    }
}
