<div class="eq player-1">
  <div class="eq-knobs">
    <knob label="HIGH" (kill)="handleKillEqHigh(0, $event)" (value)="handleEQHigh(0, $event)"></knob>
    <knob label="MID" (kill)="handleKillEqMid(0, $event)" (value)="handleEQMid(0, $event)"></knob>
    <knob label="LOW" (kill)="handleKillEqLow(0, $event)" (value)="handleEQLow(0, $event)"></knob>
  </div>
</div>

<volume
  class="volume player-1"
  [playing]="isPlaying1$ | async"
  [levell1Value]="(levels1$ | async)[0]"
  [levell2Value]="(levels1$ | async)[1]"
  (volume)="handleVolume(0, $event)"
></volume>

<!--<volume [playing]="isPlaying1$ | async"></volume>//-->
<um-crossfader></um-crossfader>

<volume
  class="volume player-2"
  [playing]="isPlaying2$ | async"
  [levell1Value]="(levels2$ | async)[0]"
  [levell2Value]="(levels2$ | async)[1]"
  (volume)="handleVolume(1, $event)"
></volume>

<!--<volume [playing]="isPlaying2$ | async"></volume>//-->

<div class="eq player-2">
  <div class="eq-knobs">
    <knob label="HIGH" (kill)="handleKillEqHigh(1, $event)" (value)="handleEQHigh(1, $event)"></knob>
    <knob label="MID" (kill)="handleKillEqMid(1, $event)" (value)="handleEQMid(1, $event)"></knob>
    <knob label="LOW" (kill)="handleKillEqLow(1, $event)" (value)="handleEQLow(1, $event)"></knob>
  </div>
</div>
