import {LinearFilter, Texture} from "three";

export class VideoTexture {


    url: string;
    video: HTMLVideoElement;
    texture: Texture;

    constructor(url: string) {

        this.url = url;

        // create the video element
        this.video = document.createElement('video');
        this.video.setAttribute("playsinline","true");
        this.video.width = 640;
        this.video.height = 360;
        this.video.autoplay = false;
        this.video.loop = true;
        this.video.src = url;

        // create the texture
        this.texture = new Texture(this.video);
        this.texture.minFilter = LinearFilter;
    };

    /**
     * update the object
     */
    update() {
        if (this.video.readyState !== this.video.HAVE_ENOUGH_DATA) {
            return;
        }
        this.texture.needsUpdate = true;
    };

    /**
     * destroy the object
     */
    destroy() {
        this.video.pause();
    }
}