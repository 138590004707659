import {ActionTriggerAction} from './action-trigger-action';

export class ActionTrigger {
    time = 0;
    active = true;
    actions: ActionTriggerAction[] = [];


    constructor(time: number) {
        this.time = time;
    }
}
