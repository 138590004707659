import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadTracksAction } from '../../core/redux/actions/archive.actions';
import { isLoadingTracks, tracks } from '../../core/redux/reducers/archive.reducer';
import { Track } from '../track';
import { loadIntoPlayerAction } from './../../core/redux/actions/player.actions';

@Component({
  selector: 'app-track-list-mobile',
  templateUrl: './track-list-mobile.component.html',
  styleUrls: ['./track-list-mobile.component.scss'],
})
export class TrackListMobileComponent implements OnInit {
  tracks$: Observable<Track[]> = this.store.select(tracks);
  isLoadingTracks$: Observable<boolean> = this.store.select(isLoadingTracks);

  constructor(private store: Store, private navParams: NavParams) {
    const groupID = this.navParams.get('groupID');
    this.loadTracks(groupID);
  }

  ngOnInit() {}

  loadTrackIntoPlayer(track: Track, playerID: number) {
    console.log('load: ', track);
    this.store.dispatch(loadIntoPlayerAction({ playerID, track }));
  }

  loadTracks(groupID: string) {
    this.store.dispatch(loadTracksAction({ groupID }));
  }
}
