import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AddActionAction } from '../../../core/redux/actions/action-trigger-editor.actions';
import { ActionTriggerAction } from '../action-trigger-action';
import { ActionTriggerGroup } from '../action-trigger-group';
import { ActionTriggerService } from '../action-trigger.service';

@Component({
  selector: 'app-action-chooser',
  templateUrl: './action-chooser.component.html',
  styleUrls: ['./action-chooser.component.scss'],
})
export class ActionChooserComponent implements OnInit {
  actions$: Observable<ActionTriggerGroup[]>;

  @Input() actionTriggerIndex: number;

  constructor(private modalController: ModalController, private actionTriggerService: ActionTriggerService, private store: Store) {
    this.actions$ = this.actionTriggerService.getActions();
  }

  ngOnInit() {}

  handleClose() {
    this.modalController.dismiss();
  }

  handleAddAction(action: ActionTriggerAction) {
    this.store.dispatch(new AddActionAction({ actionTriggerIndex: this.actionTriggerIndex, action }));
    this.handleClose();
  }
}
