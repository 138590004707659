<ion-header>

    <ion-toolbar>
        <ion-title>Action Trigger Editor</ion-title>
    </ion-toolbar>

</ion-header>
<ion-content #content>

    <div *ngFor="let item of (actionTriggers$ | async); let actionTriggerIndex=index;">
        <ion-card class="action-trigger" [ngClass]="{'inactive':!item.active}">

            <ion-card-header>


                <ion-card-subtitle>Trigger {{actionTriggerIndex+1}}</ion-card-subtitle>
                <ion-card-title>
                    <div>{{item?.time | time:'mm:ss:SS'}}</div>
                    <div class="action-buttons">
                        <ion-button [fill]="'outline'" size="small"
                                    (click)="handleRemoveActionTrigger(actionTriggerIndex)">Remove
                        </ion-button>
                        <ion-toggle [checked]="item?.active"
                                    (ionChange)="handleSetTriggerActive(actionTriggerIndex,$event.detail.checked);"></ion-toggle>
                    </div>
                </ion-card-title>
            </ion-card-header>

            <ion-card-content>
                <ion-list inset="false">
                    <ion-list-header>Actions</ion-list-header>
                    <ion-item *ngFor="let action of item?.actions;let actionIndex= index;">
                        <ion-label>{{action?.name}}</ion-label>
                        <ion-button class="delete-button" fill="outline" slot="end"
                                    (click)="handleDeleteAction(actionTriggerIndex,actionIndex);"> Delete
                        </ion-button>
                    </ion-item>
                </ion-list>
                <div style="margin-top: 10px;">
                    <ion-button class="delete-button" fill="clear" size="small"
                                (click)="handleAddAction(actionTriggerIndex);"> Add Action
                    </ion-button>
                </div>
            </ion-card-content>


        </ion-card>
    </div>

    <div>
        <!--<button (click)="handleAddTrigger();">Add Trigger</button>//-->
        <ion-button #addTriggerButton id="addTriggerButton" class="delete-button" fill="clear" size="small"
                    (click)="handleAddTrigger();"> Add Trigger
        </ion-button>
    </div>

</ion-content>  