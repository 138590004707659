import { TreeNode } from 'primeng/api';

export class Group {
  _id: string;
  name: string;

  static treeNodeFromGroup(group: Group): TreeNode {
    return {
      label: group.name,
      data: { ...group },
      key: group._id,
      type: 'group',
    } as TreeNode;
  }
}
