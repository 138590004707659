import {Component, OnInit} from '@angular/core';
import {HIDManager} from './HIDWrappers/HIDManager';
import {HIDDeviceInfo} from './HIDWrappers/HIDDeviceInfo';

export interface HIDMang {
    listDevices(): any[];
}

@Component({
    selector: 'app-device-chooser',
    templateUrl: './device-chooser.component.html',
    styleUrls: ['./device-chooser.component.scss'],
})
export class DeviceChooserComponent implements OnInit {
    // @JSAccessible
    // devices: any[] = ['Device 1', 'Device 2']; // könnte auch spezieller Typ sein, z.b. Device
    private devices = [];
    listDevices: HIDDeviceInfo[] = [];

    constructor() {

    }

    ngOnInit() {
        window['ultramixer.swing.jsArray'] = [];


    }

    fetch() {

       /* const a = window['ultramixer-swing-hid'] as HIDMang;

        this.devices = a.listDevices().map((device:any) => {
            return {
                path: device.getPath()
            };
        })


        this.listDevices.push(a.listDevices()[0]);*/
        // js array to java - fill it - back to js
        /*const a = window['ultramixer.swing.testhid'];
        if (a) {
            this.devices.push(a.helloWorld());
            // console.log('hello this is the console');


            a.fillJsArray();
            window['ultramixer.swing.jsArray'].forEach(e => console.log(e));
            this.devices = this.devices.concat(window['ultramixer.swing.jsArray']);
        }*/
        const hidManager: HIDManager = new HIDManager(window['ultramixer-swing-hid']);
        this.listDevices =  hidManager.listDevices();
        //
        /*const s = window['ultramixer-swing-hid'];
        if (s) {
            // this.listDevices = s.listDevices();
            // console.log(s.listDevices()[0].getPath());
            const length: number = s.listDevices().length();
            console.log(length);
            for (let i = 0; i < length; i++) {
                this.listDevices.push(s.listDevices()[i].getPath());
            }

            // this.listDevices.push(s.listDevices()[1].getPath());

        }*/
        /*this.devices.push(this.listDevices);
        this.devices.push('bla');
        console.log(this.listDevices);
    }

    /*const deviceIterator = window['ultramixer.swing.deviceIterator'];
    this.devices.push(deviceIterator.next());*/


        /*while (deviceInterator.hasNext()) {

        }*/
        /* const s = window['ultramixer.swing.testhid'];
         if (s) {
             this.devices.concat(s.listDevices());
             // console.log(this.listDevices);
         }*/

    }

    handleConnect(device: any) {

    }
}
