import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TrackDataForResponse } from '../../core/interfaces/um-functions.interface';
import { ChartsStore } from './component-reducer/charts.reducer';

@Component({
  selector: 'app-charts-list',
  templateUrl: './charts-list.component.html',
  styleUrls: ['./charts-list.component.scss'],
  providers: [
    ChartsStore
  ]
})
export class ChartsListComponent implements OnInit, AfterViewInit {
  private chartsStore: ChartsStore;

  @Input('show-filter') inputMode: string = 'false';
  @Input('genre') inputGenre: string = '';
  @Input('country') inputCountry: string = '';
  @Input('limit') inputLimit: string = '';

  @Input('color-not-even') colorA: string = '#222';
  @Input('color-even') colorB: string = '#333';
  @Input('color-text') colorText: string = '#fff';
  @Input('color-main-bg') colorMainBg: string = '#000';
  @Input('color-highlight') colorHighlight: string = '#f8c915';

  chartsCountry = '';
  chartsGenre = '';
  chartsLimit = 10;
  applyFilter = 'Filter übernehmen';

  filterLabels = {
    genre: 'Genre',
    country: 'Land',
    limit: 'Limit',
  };

  tableCols = [
    { field: '', header: 'Nr' },
    { field: 'title', header: 'Titel' },
    { field: 'artist', header: 'Artist' },
    { field: 'album', header: 'Album' },
    { field: 'genre', header: 'Genre' },
    { field: 'country', header: 'Land' },
  ];

  isLoading$: Observable<boolean>;
  isFilter$: Observable<boolean>;
  charts$: Observable<TrackDataForResponse[]>;

  constructor() {
    this.chartsStore = new ChartsStore();
    this.isLoading$ = this.chartsStore.isLoadingCharts$;
    this.isFilter$ = this.chartsStore.isFilterCharts$;
    this.charts$ = this.chartsStore.charts$;
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    console.log('### INPUTS', {
      mode: this.inputMode,
      genre: this.inputGenre,
      country: this.inputCountry,
      limit: this.inputLimit,
      colorA: this.colorA,
      colorB: this.colorB,
      colorMainBg: this.colorMainBg,
      colorText: this.colorText,
      colorHighlight: this.colorHighlight,
    });
    this.chartsGenre = this.inputGenre;
    this.chartsCountry = this.inputCountry;
    this.chartsLimit = (this.inputLimit && this.inputLimit !== '') ? parseInt(this.inputLimit) : 10;
    this.chartsLimit = (this.chartsLimit && !Number.isNaN(this.chartsLimit)) ? this.chartsLimit : 10;
    if (this.inputMode === 'true') {
      this.tableCols.push({ field: 'bpm', header: 'BPM' });
      this.tableCols.push({ field: 'rating', header: 'Rating' });
    }

    this.chartsStore.loadChartsList({ genre: this.chartsGenre, country: this.chartsCountry, limit: this.chartsLimit });
  }

  handleOpenFilter(event: Event): void {
    event.stopPropagation();
    this.chartsStore.openFilter();
  }

  handleCloseFilter(event: Event): void {
    event.stopPropagation();
    this.chartsStore.closeFilter();
  }

  stopToggle(event: Event): void {
    event.stopPropagation();
  }

  handleFilterApply(event: Event): void {
    if (this.chartsLimit === null || this.chartsLimit === undefined) {
      this.chartsLimit = 10;
    }
    this.chartsStore.closeFilter();
    this.chartsStore.loadChartsList({ genre: this.chartsGenre, country: this.chartsCountry, limit: this.chartsLimit });
    event.stopPropagation();
  }
}
