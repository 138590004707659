import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setVolumeAction } from '../core/redux/actions/mixer.actions';
import { loadIntoPlayerAction, playAvgTimeAction, setVirtualVelocityAvgTimeAction } from '../core/redux/actions/player.actions';
import { MidiState } from '../core/redux/reducers/midi.reducer';
import { MidiMessage } from '../models/MidiMessage';
import { Track } from '../music-archive/track';
import { AudioService } from './audio.service';

enum LED {
  PLAY_ON,
  PLAY_OFF,
}

@Injectable({
  providedIn: 'root',
})
export class MidiSoundControlService {
  // ------------------ MIDI LOGIC ------------- //

  // Scratch:
  lastScratchTick = [];
  TickRate: number = (60 / 33 / 720) * 1000; // 60s/min * 33r/min / 800 messages/r (* 1000 to get ms)
  maxTickDiffTime = 50;
  tickcount = [];
  scratchID: number = undefined;

  tickDiffLog = 0;
  tickDiffLogCount = 0;
  scratchTime = 0;
  tickscount = 0;

  crossfadertoggle = true;

  songpos = [];

  // Maps Messages to Functions
  private midiMap = new Map<number, Function>([
    // Play Button
    [
      ((0 + 144) << 16) | (11 << 8) | 127,
      (m: MidiMessage) => {
        this.store.dispatch(
          playAvgTimeAction({
            playerID: m.channel,
            received: m.timeStamp,
          })
        );
      },
    ],
    // [(0 + 144) << 16 | 11 << 8 | 127, (m: MidiMessage) => this.audioService.playAvgTime(m.channel,m.timeStamp)],
    // Cue Button

    [((0 + 144) << 16) | (12 << 8) | 127, (m: MidiMessage) => this.audioService.stop(m.channel)],
    // BEAT SYNC Button
    [
      ((0 + 144) << 16) | (88 << 8) | 127,
      (m: MidiMessage) =>
        this.store.dispatch(
          loadIntoPlayerAction({
            playerID: m.channel,
            track: new Track('/Users/stefanbehrendt/Downloads/Area Codes (Tall Boys Remix) (IGORITO Intro).mp3', '', '', 0, 120, 0),
            // tslint:disable-next-line: max-line-length
            // track: new Track("/Users/emilio/Music/y2mate.com - 50 Cent - In Da Club (Int'l Version) [Official Video]_5qm8PH4xAss.mp3", '', '', 0, 120, 0),
          })
        ),
    ],

    [((0 + 176) << 8) | 33, (m) => this.pitchBending(m)],
    [((0 + 176) << 8) | 34, (m) => this.scratchingAvgTime(m)],
    [
      ((0 + 176) << 8) | 19,
      (m: MidiMessage) =>
        this.store.dispatch(
          setVolumeAction({
            playerID: m.channel,
            volume: (m.data2 / 127) * 10000,
          })
        ),
    ],
    [((0 + 144) << 16) | (54 << 8) | 127, (m: MidiMessage) => this.startScratching(m)], // Jogwheel touched
    [((0 + 144) << 16) | (54 << 8) | 0, (m: MidiMessage) => this.stopScratching(m)], // Jogwheel touched released

    [
      ((1 + 144) << 16) | (11 << 8) | 127,
      (m: MidiMessage) =>
        this.store.dispatch(
          playAvgTimeAction({
            playerID: m.channel,
            received: m.timeStamp,
          })
        ),
    ],
    [((1 + 144) << 16) | (12 << 8) | 127, (m: MidiMessage) => this.audioService.stop(m.channel)],
    [
      ((1 + 144) << 16) | (88 << 8) | 127,
      (m: MidiMessage) =>
        this.store.dispatch(
          loadIntoPlayerAction({
            playerID: m.channel,
            track: new Track('/Users/stefanbehrendt/Downloads/Area Codes (Tall Boys Remix) (IGORITO Intro).mp3', '', '', 0, 120, 0),
            // tslint:disable-next-line: max-line-length
            // track: new Track("/Users/emilio/Music/y2mate.com - 50 Cent - In Da Club (Int'l Version) [Official Video]_5qm8PH4xAss.mp3", '', '', 0, 120, 0),
          })
        ),
    ],
    // [(0 + 176) << 8 | 33, (m) => this.scratching(m)],

    [((1 + 176) << 8) | 34, (m) => this.scratching(m)],
    [
      ((1 + 176) << 8) | 19,
      (m: MidiMessage) =>
        this.store.dispatch(
          setVolumeAction({
            playerID: m.channel,
            volume: (m.data2 / 127) * 10000,
          })
        ),
    ],
    [((1 + 144) << 16) | (54 << 8) | 127, (m: MidiMessage) => this.startScratching(m)], // Jogwheel touched
    [((1 + 144) << 16) | (54 << 8) | 0, (m: MidiMessage) => this.stopScratching(m)], // Jogwheel touched released

    // Release FX button (Switches between crosfader cuves)
    [
      ((4 + 144) << 16) | (71 << 8) | 127,
      () => {
        this.crossfadertoggle = this.crossfadertoggle ? false : true;
      },
    ],
    [((6 + 176) << 8) | 31, (m) => this.crossFader(m)],
  ]);

  public midiOutMap = new Map<number, number>([
    [LED.PLAY_ON, ((0 + 144) << 16) | (11 << 8) | 127],
    [LED.PLAY_OFF, ((0 + 144) << 16) | (11 << 8) | 0],
  ]);

  constructor(private store: Store<MidiState>, private audioService: AudioService) {}

  playMessage(value: boolean): MidiMessage {
    return new MidiMessage(0, 144, 11, value ? 127 : 0);
  }

  scratching(message: MidiMessage) {
    if (this.lastScratchTick[message.channel] === undefined || this.lastScratchTick[message.channel] > this.maxTickDiffTime) {
      this.lastScratchTick[message.channel] = window.performance.now();
    }
    const tickdiff = window.performance.now() - this.lastScratchTick[message.channel];
    if (tickdiff < this.maxTickDiffTime) {
      this.tickcount[message.channel] += message.data2 - 64;
      this.audioService.setVirtualVelocity(
        message.channel,
        this.songpos[message.channel] + this.TickRate * this.tickcount[message.channel],
        tickdiff * 1000
      );
      //  console.log("TickDiff: " , tickdiff, " nP: ", (this.audioService.getPosition(0) + (this.TickRate * (message.data2 - 64))));
    } else {
      return;
    }
    this.lastScratchTick[message.channel] = window.performance.now();
    // console.log("Input Time: ",message.timeStamp - window.performance.now());
  }

  scratchingAvgTime(message: MidiMessage) {
    if (this.lastScratchTick[message.channel] === undefined) {
      this.lastScratchTick[message.channel] = window.performance.now();
    }
    const tickdiff = window.performance.now() - this.lastScratchTick[message.channel];
    if (tickdiff < this.maxTickDiffTime) {
      this.tickcount[message.channel] += message.data2 - 64;
      // this.audioService.setVirtualVelocityAvgTime(message.channel,(this.songpos[message.channel] + (this.TickRate * this.tickcount[message.channel])), tickdiff * 1000, message.timeStamp);
      //  console.log("TickDiff: " , tickdiff, " nP: ", (this.audioService.getPosition(0) + (this.TickRate * (message.data2 - 64))));

      // TODO: why do we dispatch business logic via redux? maybe better to directly call the audio service?
      this.store.dispatch(
        setVirtualVelocityAvgTimeAction({
          playerID: message.channel,
          positionMs: this.songpos[message.channel] + this.TickRate * this.tickcount[message.channel],
          timems: tickdiff * 1000,
          received: message.timeStamp,
        })
      );
    }

    this.lastScratchTick[message.channel] = window.performance.now();
    // console.log("Input Time: ",message.timeStamp - window.performance.now());
  }

  pitchBending(message: MidiMessage) {
    if (this.scratchID !== undefined) {
      this.scratchingAvgTime(message);
      clearTimeout(this.scratchID);
      this.scratchID = window.setTimeout(
        () => {
          // console.log("Stop S");
          this.scratchID = undefined;
          this.audioService.setVirtualVTMode(message.channel, 1.0);
          this.audioService.enableVirtualVinyl(message.channel, false);
          this.tickcount[message.channel] = 0;
          this.lastScratchTick[message.channel] = undefined;
          this.songpos[message.channel] = undefined;
          console.log('Scratching end');
        },
        this.TickRate * 3,
        message
      );
    }
  }

  startScratching(message: MidiMessage) {
    this.audioService.setVirtualVTMode(message.channel, 0.0);
    this.audioService.enableVirtualVinyl(message.channel, true);
    this.tickcount[message.channel] = 0;
    this.lastScratchTick[message.channel] = undefined;
    this.songpos[message.channel] = this.audioService.getPosition(message.channel);

    if (this.scratchID !== undefined) {
      // TODO: replace timeout with RXJS-delay
      clearTimeout(this.scratchID);
      this.scratchID = undefined;
    }
  }

  stopScratching(message: MidiMessage) {
    // TODO: replace timeout with RXJS-delay
    this.scratchID = window.setTimeout(
      () => {
        // console.log("Stop S");
        this.scratchID = undefined;
        this.audioService.setVirtualVTMode(message.channel, 1.0);
        this.audioService.enableVirtualVinyl(message.channel, false);
        this.tickcount[message.channel] = 0;
        this.lastScratchTick[message.channel] = undefined;
        this.songpos[message.channel] = undefined;
        console.log('Scratching end');
      },
      this.TickRate * 3,
      message
    );
  }

  volumeFader(message: MidiMessage) {}

  crossFader(message: MidiMessage) {
    // let v = message.data2/127;
    if (this.crossfadertoggle) {
      this.store.dispatch(setVolumeAction({ playerID: 0, volume: (1 - message.data2 / 127) * 10000 }));
      this.store.dispatch(setVolumeAction({ playerID: 1, volume: (message.data2 / 127) * 10000 }));
    } else {
      this.store.dispatch(setVolumeAction({ playerID: 0, volume: message.data2 < 122 ? 10000 : 0 }));
      this.store.dispatch(setVolumeAction({ playerID: 1, volume: message.data2 > 5 ? 10000 : 0 }));
    }
  }

  handleMessage(message: MidiMessage) {
    let call = this.midiMap.get(message.id);
    if (call) {
      call(message);
    } else {
      const id = ((message.channel + message.command) << 8) | message.data1;
      call = this.midiMap.get(id);
      if (call) {
        call(message);
      } else {
        // console.log(message.toString());
      }
    }
  }

  private ledPlay(isPlaying: boolean[]) {
    console.log('isPlaying: ', isPlaying[0]);
  }
}
