import { Injectable, inject } from "@angular/core";
import { TrackDataForResponse } from "../../../core/interfaces/um-functions.interface";
import { ComponentStore } from '@ngrx/component-store';
import { DataService } from "../../../services/data.service";
import { switchMap, tap } from "rxjs";
import { UmFunctionsEnum } from "../../../core/enums/um-functions.enum";

export interface ChartsState {
  isLoadingCharts: boolean;
  isFilterCharts: boolean;
  charts: TrackDataForResponse[];
}

@Injectable()
export class ChartsStore extends ComponentStore<ChartsState> {
  dataService: DataService;
  constructor() {
    // Initial state
    super({
      isLoadingCharts: false,
      isFilterCharts: false,
      charts: Array(10).fill({} as TrackDataForResponse),
    });
    this.dataService = inject(DataService);
  }

  // Selectors
  readonly isLoadingCharts$ = this.select((state) => state.isLoadingCharts);
  readonly isFilterCharts$ = this.select((state) => state.isFilterCharts);
  readonly charts$ = this.select((state) => state.charts);

  // Actions (probably with effects)
  readonly openFilter = this.updater((state) => ({ ...state, isFilterCharts: true }));
  readonly closeFilter = this.updater((state) => ({ ...state, isFilterCharts: false }));
  readonly loadChartsList = this.effect<{ genre: string; country: string; limit: number }>((params$) =>
    params$.pipe(
      switchMap((params) =>
        this.dataService.getChartsList$(
          `${UmFunctionsEnum.GetCharts}?genre=${params.genre}&country=${params.country.toUpperCase()}&limit=${params.limit}`
        )
      ),
      tap((charts: TrackDataForResponse[]) => this.loadChartsListSucceeded({ charts: charts }))
    )
  );
  private readonly loadChartsListSucceeded = this.updater((state, response: { charts: TrackDataForResponse[] }) => ({
    ...state,
    isLoadingCharts: false,
    charts: response.charts,
  }));
}