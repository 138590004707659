import { createAction, props } from '@ngrx/store';

const ACTION_TYPE = '[Mixer]';

export const setLevelsAction = createAction(
  `${ACTION_TYPE} Set levels`,
  props<{ playerID: number; levelLeft: number; levelRight: number }>()
);
export const setEqHighAction = createAction(`${ACTION_TYPE} Set eq high`, props<{ playerID: number; value: number }>());
export const setEqMidAction = createAction(`${ACTION_TYPE} Set eq mid`, props<{ playerID: number; value: number }>());
export const setEqLowAction = createAction(`${ACTION_TYPE} Set eq low`, props<{ playerID: number; value: number }>());

export const killEqHighAction = createAction(`${ACTION_TYPE} Kill eq high`, props<{ playerID: number; kill: boolean }>());
export const killEqMidAction = createAction(`${ACTION_TYPE} Kill eq mid`, props<{ playerID: number; kill: boolean }>());
export const killEqLowAction = createAction(`${ACTION_TYPE} Kill eq low`, props<{ playerID: number; kill: boolean }>());

export const setVolumeAction = createAction(`${ACTION_TYPE} Set Volume`, props<{ playerID: number; volume: number }>());

export const crossfaderAction = createAction(`${ACTION_TYPE} Crossfader`, props<{ value: number }>());
