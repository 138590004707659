import { Component, Input, OnInit } from '@angular/core';
import { loadSampleBankAction, playSampleAction } from '../../../core/redux/actions/audiosampler.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'audio-sampler',
  templateUrl: './audio-sampler.component.html',
  styleUrls: ['./audio-sampler.component.scss'],
})
export class AudioSamplerComponent implements OnInit {
  @Input('noSlots') noSlots: number = 4;

  slots: Array<any>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.slots = new Array(this.noSlots);
    this.store.dispatch(loadSampleBankAction());
  }

  handleClick(slotNumber: number) {
    this.store.dispatch(playSampleAction({ slotIndex: slotNumber }));
  }
}
